import { Select } from 'components'
import React, { useCallback, useMemo } from 'react'
import useStore from '___store'

const QuestionSelect = React.memo(props => {
  const {
    filter: filterTest,
    sort: sortFunction,
    append: appendOptionsObject,
    className,
    chooseRender = 'Select a question',
    anchor = 'bottom-left',
    direction = 'bottom-right',
    value,
    onChange,
  } = props

  const { questions = [] } = useStore('selectQuestions')
  const selectedQuestion = useMemo(() => questions.find(q => q.id === value), [questions, value])
  const selectedQuestionText = selectedQuestion?.text || 'No question text...'

  const filterCallback = useCallback(q => (typeof filterTest === 'function' ? q?.filter(filterTest) : q), [filterTest])
  const sortCallback = useCallback(q => (typeof sortFunction === 'function' ? q?.sort(sortFunction) : q), [sortFunction])
  const questionSelectOptionObject = useMemo(
    () =>
      Object.assign(
        sortCallback(filterCallback(questions))
          ?.filter(q => q.id !== value)
          .reduce((obj, q) => Object.assign(obj, { [q.id]: q.text || 'No question text...' }), {}) || {},
        appendOptionsObject
      ),
    [sortCallback, filterCallback, questions, value, appendOptionsObject]
  )

  return (
    <Select
      className={className}
      chooseMode={true}
      chooseRender={(value && selectedQuestionText) || chooseRender}
      anchor={anchor}
      direction={direction}
      value={value}
      onChange={id => onChange(id)}
      options={questionSelectOptionObject}
    />
  )
})

export default QuestionSelect
