const SEGMENT_TYPES = {
  container: 'container',
  mark: 'mark',
  textarea: 'textarea',
  paragraph: 'paragraph',
  textChunk: 'chunk',
  table: 'table',
  tableHeader: 'header',
  tableBody: 'body',
  tableFooter: 'footer',
  tableRow: 'row',
  tableData: 'cell',
  image: 'image',
}

const SEGMENT_TAGS = {
  [SEGMENT_TYPES.container]: 'div',
  [SEGMENT_TYPES.mark]: 'mark',
  [SEGMENT_TYPES.textarea]: 'textarea',
  [SEGMENT_TYPES.paragraph]: 'pre',
  [SEGMENT_TYPES.textChunk]: 'span',
  [SEGMENT_TYPES.table]: 'table',
  [SEGMENT_TYPES.tableHeader]: 'thead',
  [SEGMENT_TYPES.tableBody]: 'tbody',
  [SEGMENT_TYPES.tableFooter]: 'tfoot',
  [SEGMENT_TYPES.tableRow]: 'tr',
  [SEGMENT_TYPES.tableData]: 'td',
  [SEGMENT_TYPES.image]: 'img',
}
const CASUS_KEYSTRINGS = {
  remove: '_casus_remove',
  keep: '_casus_keep',
  highlight: '_casus_highlight',
  userInput: '_casus_user_input',
}

const CASUS_IDS = {
  rootElement: '_casus_root_element',
}

const CASUS_CLASSES = {
  section: '_casus_section_root',
  pageContentRoot: '_casus_page_content_root',
  counterResetter: '_casus_counter_resetter',
  choiceMarkerDiv: '_casus_choice_marker_div',
  choiceMarkerContent: '_casus_choice_marker_content',
  choiceMarkerHeader: '_casus_choice_marker_header',
  replacementMarker: '_casus_replacement_marker',
  segmentDiv: '_casus_segment_div',
  configureDiv: '_casus_configure_div',
  configureButton: '_casus_configure_button',
  labelInput: '_casus_label_input',
  textarea: '_casus_textarea',
  textareaOverlay: '_casus_textarea_overlay',
  paragraphSegment: '_casus_paragraph_segment',
  tableSegment: '_casus_table_segment',
  tableHeader: '_casus_table_header',
  tableBody: '_casus_table_body',
  tableFooter: '_casus_table_footer',
  tableRow: '_casus_table_row',
  tableCell: '_casus_table_cell',
  textChunk: '_casus_text_chunk_span',
  listDepthLevel: '_casus_list_depth_level',
}

const PAPER_NAMES = {
  LETTER: 'letter',
  LEGAL: 'legal',
  A3: 'a3',
  A4: 'a4',
  A5: 'a5',
  B4: 'b4',
  B5: 'b5',
  EURO_FANFOLD: 'euro-fanfold',
  EXECUTIVE1: 'executive1',
  EXECUTIVE2: 'executive2',
  EXECUTIVE3: 'executive3',
  FOLIO: 'folio',
  LEDGER: 'ledger',
  TABLOID: 'tabloid',
  QUATRO: 'quatro',
  SHORT: 'short',
  STATEMENT: 'statement',
  STATIONERY: 'stationery',
  NARROW: 'narrow',
  WIDE: 'wide',
  '1403W': '1403w',
  '1403WS': '1403ws',
  '3800N': '3800n',
  '3800NS': '3800ns',
  '3800W': '3800w',
  '3800WS': '3800ws',
  CUT_SHEET: 'cut-sheet',
}

const mmpi = 25.4
const pxpi = 96

const PAGE_SIZES_IMPERIAL = {
  [PAPER_NAMES.A5]: { width: 148 / mmpi, height: 210 / mmpi },
  [PAPER_NAMES.B5]: { width: 182 / mmpi, height: 257 / mmpi },
  [PAPER_NAMES.A4]: { width: 210 / mmpi, height: 297 / mmpi },
  [PAPER_NAMES.QUATRO]: { width: 215 / mmpi, height: 275 / mmpi },
  [PAPER_NAMES.EURO_FANFOLD]: { width: 250 / mmpi, height: 340 / mmpi },
  [PAPER_NAMES.B4]: { width: 257 / mmpi, height: 364 / mmpi },
  [PAPER_NAMES.A3]: { width: 297 / mmpi, height: 420 / mmpi },
  [PAPER_NAMES.STATEMENT]: { width: 5.5, height: 8.5 },
  [PAPER_NAMES.EXECUTIVE1]: { width: 7, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE2]: { width: 7.25, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE3]: { width: 7.5, height: 10.5 },
  [PAPER_NAMES['3800NS']]: { width: 7.5, height: 11.5 },
  [PAPER_NAMES['3800WS']]: { width: 7.5, height: 13.5 },
  [PAPER_NAMES.STATIONERY]: { width: 8, height: 10 },
  [PAPER_NAMES['3800N']]: { width: 8.5, height: 10 },
  [PAPER_NAMES.SHORT]: { width: 8.5, height: 10.5 },
  [PAPER_NAMES.LETTER]: { width: 8.5, height: 11 },
  [PAPER_NAMES.FOLIO]: { width: 8.5, height: 13 },
  [PAPER_NAMES['1403WS']]: { width: 8.5, height: 13.5 },
  [PAPER_NAMES.LEGAL]: { width: 8.5, height: 14 },
  [PAPER_NAMES['3800W']]: { width: 10, height: 13.5 },
  [PAPER_NAMES.NARROW]: { width: 10, height: 14 },
  [PAPER_NAMES['1403W']]: { width: 11, height: 13.5 },
  [PAPER_NAMES.WIDE]: { width: 11, height: 14 },
  [PAPER_NAMES.TABLOID]: { width: 11, height: 17 },
  [PAPER_NAMES.CUT_SHEET]: { width: 17, height: 22 },
}

const PAGE_SIZES = Object.entries(PAGE_SIZES_IMPERIAL).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: { width: value.width * pxpi, height: value.height * pxpi },
  }),
  {}
)

const ORIENTATION = {
  horizontal: 'landscape',
  vertical: 'portrait',
}

const DATA_STRUCTURE = {
  segments: [
    {
      id: '10001',
      type: SEGMENT_TYPES.paragraph,
      tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
      customStyle: 'paragraph-class',
      styles: ['paragraph-style-1', 'paragraph-style-2'],
      break: {
        type: 'page',
        id: 'page-1',
      },
      textChunks: [
        {
          type: SEGMENT_TYPES.textChunk,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.textChunk],
          customStyle: 'chunk-class',
          styles: ['chunk-style-1', 'chunk-style-2'],
          text: 'sample text',
        },
      ],
    },
    {
      id: '10002',
      type: SEGMENT_TYPES.table,
      tag: SEGMENT_TAGS[SEGMENT_TYPES.table],
      customStyle: 'table-class',
      styles: ['table-style-1', 'table-style-2'],
      break: {
        type: 'section',
        id: 'section-1',
        layout: {
          page: PAPER_NAMES.A4,
          orientation: ORIENTATION.vertical,
        },
      },
      header: [
        {
          id: '100021',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-header-class',
          styles: ['table-header-style-1', 'table-header-style-2'],
          cells: [
            {
              id: '1000211',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [],
            },
          ],
        },
      ],
      body: [
        {
          id: '100022',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-body-class',
          styles: ['table-body-style-1', 'table-body-style-2'],
          cells: [
            {
              id: '1000221',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [],
            },
          ],
        },
      ],
      footer: [
        {
          id: '100023',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-footer-class',
          styles: ['table-footer-style-1', 'table-footer-style-2'],
          cells: [
            {
              id: '1000231',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [],
            },
          ],
        },
      ],
    },
  ],
}

const NUMBERING_SYSTEM = {
  systemName: [
    {
      prefix: '',
      type: 'decimal',
      suffix: '.',
      combined: false,
      combineString: '',
      styleName: 'style-name-1',
    },
    {
      prefix: '',
      type: 'lower-latin',
      suffix: ')',
      combined: true,
      combineString: '',
      styleName: 'style-name-2',
    },
  ],
}

const KEY_BLACKLIST = ['styles']

const extractNestedKeys = (structure, set = new Set()) => {
  Object.entries(structure).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (!KEY_BLACKLIST.includes(key)) set.add(key)
      value.forEach(obj => extractNestedKeys(obj, set))
    }
  })
  return set
}

const NESTED_STRUCTURE_KEYS = Array.from(extractNestedKeys(DATA_STRUCTURE))

const extractNestedTypes = (structure, acc = {}) =>
  NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (structure[key]?.length) {
      if (!acc[key]) acc[key] = structure[key][0]?.type || null
      structure[key].forEach(segment => extractNestedTypes(segment, acc))
    }
    return acc
  }, acc)

const NESTED_STRUCTURE_TYPES = extractNestedTypes(DATA_STRUCTURE)

const findNestedSegment = (dataStructure = {}, segmentType) => {
  let res = null
  NESTED_STRUCTURE_KEYS.every(
    key =>
      !dataStructure[key]?.find(segment =>
        segment.type === segmentType ? (res = segment) : (res = findNestedSegment(segment, segmentType))
      )
  )
  return res
}

const SEGMENT_KEYS = Object.values(SEGMENT_TYPES).reduce(
  (acc, cur) =>
    Object.assign(acc, {
      [cur]: Object.keys(findNestedSegment(DATA_STRUCTURE, cur) || {}).filter(k => !acc.all.includes(k)),
    }),
  {
    all: ['type', 'tag', 'customStyle', 'styles'],
  }
)

const STOP_DEPTH_NODE_CLASSES = [CASUS_CLASSES.section]

const SKIP_DEPTH_NODE_CLASSES = [
  CASUS_CLASSES.pageContentRoot,
  // CASUS_CLASSES.choiceMarkerDiv,
  CASUS_CLASSES.choiceMarkerContent,
]

const IGNORE_NODE_CLASSES = [CASUS_CLASSES.choiceMarkerHeader]

const CASUS_PREFIX = '_casus_'

const stylesInfo = {
  fsize: { property: 'font-size', prefix: '', suffix: 'pt' },
  indent: { property: 'margin-left', prefix: '', suffix: 'pt' },
  'spacing-before': { property: 'margin-top', prefix: '', suffix: 'pt' },
  'spacing-after': { property: 'margin-bottom', prefix: '', suffix: 'pt' },
}

const STYLES_PROPERTIES = Object.entries(stylesInfo).reduce(
  (acc, [key, { property }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: property }),
  {}
)

const STYLES_AFFIXES = Object.entries(stylesInfo).reduce(
  (acc, [key, { prefix, suffix }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: { prefix, suffix } }),
  {}
)

export {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  CASUS_KEYSTRINGS,
  CASUS_IDS,
  CASUS_CLASSES,
  PAPER_NAMES,
  PAGE_SIZES,
  ORIENTATION,
  DATA_STRUCTURE,
  NUMBERING_SYSTEM,
  NESTED_STRUCTURE_KEYS,
  NESTED_STRUCTURE_TYPES,
  SEGMENT_KEYS,
  STOP_DEPTH_NODE_CLASSES,
  SKIP_DEPTH_NODE_CLASSES,
  IGNORE_NODE_CLASSES,
  STYLES_PROPERTIES,
  STYLES_AFFIXES,
}
