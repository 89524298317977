import { useContext, useMemo, useReducer, useEffect } from 'react'
import { unpackSelectorKey } from './enhancers'
import { StoreContext } from './storeComposer'

export const useStore = (...keys) => {
  const store = useContext(StoreContext)
  if (store === null) throw new Error('Could not find a store provider.')

  // console.log('USE STORE KEYS: ', keys)

  const keystring = keys.join('; ')

  const [selectorKeys, actions, actionCreators, selectedState] = useMemo(() => {
    const selectKeys = []
    const actionKeys = []
    const creatorKeys = []
    keystring.split('; ').forEach(str => {
      if (str.slice(0, 6) === 'select') selectKeys.push(str)
      else if (str.slice(-7) === 'Creator') creatorKeys.push(str)
      else actionKeys.push(str)
    })
    const boundActions = store.getActions(actionKeys)
    const creators = store.getActionCreators(creatorKeys)
    const state = Object.entries(store.select(selectKeys)).reduce(
      (acc, [key, value]) => Object.assign(acc, { [unpackSelectorKey(key)[0]]: value }),
      {}
    )
    // console.log('KEYS: ', keystring.split('; '))
    // console.log('SELECT KEYS: ', selectKeys)
    // console.log('ACTION KEYS: ', actionKeys)
    // console.log('CREATOR KEYS: ', creatorKeys)
    return [selectKeys, boundActions, creators, state]
  }, [store, keystring])

  // console.log(selectedState)

  // console.log('SELECTOR KEYS: ', selectorKeys)
  // console.log('BOUND ACTIONS: ', actions)
  // console.log('ACTION CREATORS: ', actionCreators)
  // console.log('SELECTED STATE: ', selectedState)

  const [state, setState] = useReducer(
    (s, action) => {
      if (typeof action === 'function') return action(s)
      const state = Object.entries(action).reduce(
        (acc, [key, value]) => Object.assign(acc, { [unpackSelectorKey(key)[0]]: value }),
        { ...s }
      )
      return state
    },
    {
      ...selectedState,
      ...actions,
      ...actionCreators,
    }
  )

  useEffect(() => store.subscribeToSelectors(selectorKeys, setState), [store, selectorKeys])

  return state
}
