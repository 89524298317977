import React, { forwardRef, useImperativeHandle } from 'react'
import useStore from '___store'
import { AdvancedChoiceQuestionConfiguration } from './components'

const classes = {
  wrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-wrapper',
}

const AdvancedQuestionConfiguration = React.memo(
  forwardRef((props, ref) => {
    const { id } = props
    const { questions: question = {} } = useStore(`selectQuestions[${id}]`)
    const { type } = question

    useImperativeHandle(ref, () => ({ key: 'question-advanced', priority: 1 }), [])

    // console.log(question)

    return (
      <div className={classes.wrapper}>
        {type === 'choice' ? <AdvancedChoiceQuestionConfiguration ref={ref} id={id} /> : null}
        {/* {type === 'replacement' ? <AdvancedChoiceQuestionConfiguration ref={ref} id={id} /> : null} */}
      </div>
    )
  })
)

AdvancedQuestionConfiguration.displayName = 'Wizard-AdvancedQuestionConfiguration'

export default AdvancedQuestionConfiguration
