// import { v4 as uuid } from 'uuid'

import { evaluateQuestionConditionalOutcome, fullAssign } from './index'

const setAnswering = (state, payload) => {
  return fullAssign({}, state, { answering: payload.id })
}

const getFirstVisibleId = (state, ids) => ids?.find(id => evaluateQuestionConditionalOutcome(state, id))
const getNextVisibleId = (state, group, index = -1) => getFirstVisibleId(state, group?.questionIds?.slice(index + 1))

const getFirstVisibleGroup = (state, groups) => groups?.find(g => g.questionIds?.some(qid => evaluateQuestionConditionalOutcome(state, qid)))
const getNextVisibleGroup = (state, index = -1) => getFirstVisibleGroup(state, state.questionLayout.slice(index + 1))

const goToNextQuestion = state => {
  const answering = state.answering
  let groupIndex
  const group = state.questionLayout.find(({ id, questionIds }, i) => (id === answering || questionIds?.includes(answering)) && (groupIndex = i))
  let next
  if (group?.type === 'loose') next = getNextVisibleId(group, group.questionIds?.indexOf(answering))
  if (!next) {
    const nextGroup = getNextVisibleGroup(state, groupIndex)
    next = nextGroup?.type === 'loose' ? nextGroup.questionIds[0] : nextGroup?.id
  }
  return setAnswering(state, { id: next || 'finish' })
}

const getPrevVisibleId = (state, group, index) => getFirstVisibleId(state, group?.questionIds?.slice(0, index).reverse())
const getPrevVisibleGroup = (state, index = -1) => getFirstVisibleGroup(state, state.questionLayout.slice(0, index).reverse())

const goToPreviousQuestion = state => {
  const answering = state.answering
  let groupIndex
  const group = state.questionLayout.find(({ id, questionIds }, i) => (id === answering || questionIds?.includes(answering)) && (groupIndex = i))
  let prev
  if (group?.type === 'loose') prev = getPrevVisibleId(group, group.questionIds?.indexOf(answering))
  if (!prev) {
    const prevGroup = getPrevVisibleGroup(state, groupIndex)
    prev = prevGroup?.type === 'loose' ? prevGroup.questionIds[0] : prevGroup?.id
  }
  return setAnswering(state, { id: prev || 'start' })
}

export { goToNextQuestion, goToPreviousQuestion }
