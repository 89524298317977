import React, { forwardRef, useImperativeHandle, useMemo } from 'react'

export const PageContext = React.createContext(null)

export const PageProvider = forwardRef(({ children }, ref) => {
  const value = useMemo(() => ({}), [])

  useImperativeHandle(ref, () => value, [value])

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
})
