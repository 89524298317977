import Caret from './Caret'
import Check from './Check'
import CheckBox from './CheckBox'
import Cogwheel from './Cogwheel'
import Cross from './Cross'
import Deselect from './Deselect'
import Extract from './Extract'
import Insert from './Insert'
import Pen from './Pen'
import Plus from './Plus'
import RadioButton from './RadioButton'
import Trash from './Trash'

import './style.scss'

export const className = 'SVG-Icon'

export { Caret, Check, CheckBox, Cogwheel, Cross, Deselect, Extract, Insert, Pen, Plus, RadioButton, Trash }
