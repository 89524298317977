import React, { forwardRef, useImperativeHandle } from 'react'
import useStore from '___store'

import ShowHideConditionalFragment from './ShowHideConditionalFragment'

const classes = {
  wrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-wrapper',
  fragment: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragment',
  fragmentRow: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentRow',
  fragmentControls: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentControls',
  fragmentLabel: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentLabel',
  fragmentSwitch: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentSwitch',
  fragmentButton: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentButton',
}

const AdvancedChoiceQuestionConfiguration = React.memo(
  forwardRef((props, ref) => {
    const { id } = props
    const {
      // questions: question = {},
      //updateQuestion,
      concludeConfiguring,
    } = useStore(
      // `selectQuestions[${id}]`,
      // 'updateQuestion',
      'concludeConfiguring'
    )

    // console.log(question)

    useImperativeHandle(
      ref,
      () => ({
        key: 'question-advanced',
        priority: 2,
        buttonArray: [{ text: 'Confirm', callback: concludeConfiguring }],
      }),
      [concludeConfiguring]
    )

    return (
      <div className={classes.wrapper}>
        <ShowHideConditionalFragment questionId={id} />
      </div>
    )
  })
)

AdvancedChoiceQuestionConfiguration.displayName = 'Wizard-AdvancedQuestionConfiguration-Choice'

export default AdvancedChoiceQuestionConfiguration
