import { v4 as uuid } from 'uuid'

import ACTION_TYPES from '___store/actionTypes'
import {
  evaluateDependencies,
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  addNewQuestion,
  updateQuestion,
  removeQuestion,
  assignQuestion,
  unassignQuestion,
  updateQuestionOption,
  updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
  goToNextQuestion,
  goToPreviousQuestion,
} from './helpers/index' // remove /index
import {
  addLocation,
  answerQuestion,
  applyToConfiguringStack,
  assignMarker,
  evaluateChoiceMarker,
  evaluateReplacementMarker,
  getQuestionOption,
  getStateQuestion,
  initializeWizard,
  removeLocation,
  unassignMarker,
} from './helpers'

const initialState = {
  configuring: [],
  answering: null,
  dataStructure: {},
  sections: [],
  locations: { choice: {}, replacement: {} },
  styles: {},
  numberingSystem: {},
  questions: [],
  dependencies: {},
  questionLayout: [],
  answers: [],
  mode: 'document-generation',
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action
  if (type === ACTION_TYPES.WIZARD_RESET) return { ...initialState }
  if (type === ACTION_TYPES.WIZARD_INITIALIZED) return evaluateDependencies(initializeWizard(state, payload))
  if (type === ACTION_TYPES.LOCATION_MARKED) return addLocation(state, payload)
  if (type === ACTION_TYPES.LOCATION_UNMARKED) return removeLocation(state, payload)
  if (type === ACTION_TYPES.MARKER_ASSIGNED) return assignMarker(state, payload)
  if (type === ACTION_TYPES.MARKER_UNASSIGNED) return unassignMarker(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////// QUESTION LAYOUT ////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.QUESTION_LAYOUT_SEPARATOR_ADDED) return addNewQuestionLayoutGroupSeparator(state)
  if (type === ACTION_TYPES.QUESTION_LAYOUT_GROUP_ADDED) return addNewQuestionLayoutGroup(state)
  if (type === ACTION_TYPES.QUESTION_LAYOUT_GROUP_UPDATED) return updateQuestionLayoutGroup(state, payload)
  if (type === ACTION_TYPES.QUESTION_LAYOUT_GROUP_UNPACKED) return unpackQuestionLayoutGroup(state, payload)
  if (type === ACTION_TYPES.QUESTION_LAYOUT_GROUP_REMOVED) return removeQuestionLayoutGroup(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// QUESTIONS ///////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.NEW_QUESTION_ADDED) return addNewQuestion(state, payload)
  if (type === ACTION_TYPES.QUESTION_UPDATED) return updateQuestion(state, payload)
  if (type === ACTION_TYPES.QUESTION_REMOVED) return removeQuestion(state, payload)
  if (type === ACTION_TYPES.QUESTION_ASSIGNED) return assignQuestion(state, payload)
  if (type === ACTION_TYPES.QUESTION_UNASSIGNED) return unassignQuestion(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ////////////////////////////////////////////// OPTIONS ////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.QUESTION_OPTION_UPDATED) return updateQuestionOption(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////// ADVANCED QUESTION ///////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.ADVANCED_QUESTION_CONFIGURATION_UPDATED) return updateAdvancedQuestionConfiguration(state, payload)
  if (type === ACTION_TYPES.QUESTION_CONDITIONAL_RULE_ADDED) return addNewConditionalRule(state, payload)
  if (type === ACTION_TYPES.QUESTION_CONDITIONAL_RULE_UPDATED) return updateConditionalRule(state, payload)
  if (type === ACTION_TYPES.QUESTION_CONDITIONAL_RULE_REMOVED) return removeConditionalRule(state, payload)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////// ANSWERS /////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  if (type === ACTION_TYPES.NEXT_QUESTION_SET) return goToNextQuestion(state)
  if (type === ACTION_TYPES.PREVIOUS_QUESTION_SET) return goToPreviousQuestion(state)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////// //
  // if (type === ACTION_TYPES.QUESTION_REMOVED) {
  //   const { id } = payload
  //   const newQuestions = state.questions.filter(q => q.id !== id)
  //   if (state.questions.length === newQuestions) return state
  //   return { ...Object.assign(state, { questions: newQuestions }) }
  // }
  if (type === ACTION_TYPES.CHOICE_MARKER_UPDATED)
    return Object.values(state.locations.choice).some((array, i, all) => {
      const index = array.findIndex(m => m.id === payload.id)
      return index !== -1 && array.splice(index, 1, Object.assign({ ...array[index] }, payload))
    })
      ? { ...state }
      : state
  if (type === ACTION_TYPES.CONFIGURING_SET) return { ...state, configuring: applyToConfiguringStack(state.configuring, payload) }
  if (type === ACTION_TYPES.ANSWERING_SET) return { ...state, answering: payload.id }
  if (type === ACTION_TYPES.CONFIGURING_DONE) return { ...state, configuring: state.configuring.slice(0, state.configuring.length - 1) }
  if (type === ACTION_TYPES.QUESTION_OPTION_ADDED) {
    const { questionId } = payload
    const [question, index] = getStateQuestion(state, questionId)
    if (index === -1) return state
    const resultingOptions = question.options?.slice() || []
    const ignore = ['questionId']
    const fallback = { id: uuid() }
    const optionObject = Object.entries(payload).reduce((acc, [k, v]) => (ignore.includes(k) ? acc : Object.assign(acc, { [k]: v })), fallback)
    if (question.type === 'choice') Object.assign(optionObject, { text: `Option ${String(question.options?.length + 1 || 1)}` })
    resultingOptions.push(optionObject)
    const optionTypes = new Set(resultingOptions.map(({ type }) => type))
    const computedQuestionValueType = optionTypes.size === 1 ? Array.from(optionTypes)[0] : 'ambiguous'
    state.questions.splice(index, 1, Object.assign({}, question, { options: resultingOptions, valueType: computedQuestionValueType }))
    return { ...state }
  }
  // if (type === ACTION_TYPES.QUESTION_OPTION_UPDATED) {
  //   const { questionId, id } = payload
  //   const [question, index] = getStateQuestion(state, questionId)
  //   if (index === -1) return state
  //   const [option, optionIndex] = getQuestionOption(question, id)
  //   const ignore = ['questionId', 'id']
  //   const optionObject = Object.entries(payload).reduce(
  //     (acc, [k, v]) => (ignore.includes(k) ? acc : Object.assign(acc, { [k]: v })),
  //     { ...option }
  //   )
  //   question.options.splice(optionIndex, 1, optionObject)
  //   const optionTypes = new Set(question.options.map(({ type }) => type))
  //   const computedQuestionValueType = optionTypes.size === 1 ? Array.from(optionTypes)[0] : 'ambiguous'
  //   if (question.valueType !== computedQuestionValueType)
  //     state.questions.splice(index, 1, Object.assign({}, question, { valueType: computedQuestionValueType }))
  //   return { ...state }
  // }
  if (type === ACTION_TYPES.QUESTION_OPTION_REMOVED) {
    const { questionId, id } = payload
    const [question, index] = getStateQuestion(state, questionId)
    if (index === -1) return state
    const resultingOptions = question.options?.filter(o => o.id !== id) || []
    if (question.options?.length === resultingOptions.length) return state
    state.questions.splice(index, 1, Object.assign({}, question, { options: resultingOptions }))
    return { ...state }
  }
  if (type === ACTION_TYPES.QUESTION_OPTION_REORDERED) {
    const { questionId, id, order } = payload
    if (!Number(order)) return state
    const [question, index] = getStateQuestion(state, questionId)
    if (index === -1) return state
    const [option, optionIndex] = getQuestionOption(question, id)
    if (optionIndex === -1) return state
    const newIndex = Math.min(Math.max(optionIndex + order, 0), question.options.length - 1)
    question.options.splice(optionIndex, 1)
    question.options.splice(newIndex, 0, option)
    state.questions.splice(index, 1, Object.assign({}, question))
    return { ...state }
  }
  if (type === ACTION_TYPES.QUESTION_ANSWERED) return answerQuestion(state, payload)
  if (type === ACTION_TYPES.CHOICE_MARKER_EVALUATED) return evaluateChoiceMarker(state, payload)
  if (type === ACTION_TYPES.REPLACEMENT_MARKER_EVALUATED) return evaluateReplacementMarker(state, payload)

  // return payload.id !== state.configuring.id ? { ...state, configuring: payload } : state

  // if (type === ACTION_TYPES.SEGMENT_INSERTED_AT_INDEX) {
  //   const segment = generateSegment(payload.segmentType)
  //   const configuring = segment.id
  //   const dataStructure = {
  //     ...insertSegmentAtIndex(state.dataStructure, payload.parentId, payload.inside, payload.index, segment),
  //   }
  //   return { ...state, dataStructure, configuring }
  // }
  // if (type === ACTION_TYPES.SEGMENT_REMOVED_AT_INDEX) {
  //   const dataStructure = { ...removeSegmentAtIndex(state.dataStructure, payload.parentId, payload.index) }
  //   const configuring = state.configuring === payload.id ? null : state.configuring
  //   return { ...state, dataStructure, configuring }
  // }

  // if (type === ACTION_TYPES.SEGMENT_INSERTED_ABOVE) {
  //   const segment = generateSegment(payload.segmentType)
  //   return { ...insertSegmentAbove(state, payload.id, segment), configuring: segment.id }
  // }
  // if (type === ACTION_TYPES.PARAGRAPH_CONTENT_REPLACED)
  //   return { ...replaceParagraphContent(state, payload.id, payload.text) }
  // if (type === ACTION_TYPES.SEGMENT_LABEL_UPDATED) return { ...updateSegmentLabel(state, payload.id, payload.label) }
  // if (type === ACTION_TYPES.CUSTOM_STYLE_APPLIED) return { ...applyCustomStyle(state, payload.id, payload.customStyle) }
  // if (type === ACTION_TYPES.SEGMENT_REMOVED)
  //   return {
  //     ...removeSegment(state, payload.id, payload.customStyle),
  //     configuring: state.configuring === payload.id ? null : state.configuring,
  //   }
  return state
}

export { reducer }
export default reducer
