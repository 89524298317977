import React from 'react'
import { useHistory } from 'react-router-dom'
import { UserCardContainer, UserInfoContainer, Text } from './Sidebar.styles'
import { UserCardProps } from './Sidebar.types'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'
import { limitLongString } from 'utils'
import { useTranslation } from 'react-i18next'

export const UserProfileCard: React.FC<UserCardProps> = ({
  imageUrl,
  firstName,
  lastName,
  email,
  version,
}) => {
  const history = useHistory()
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : email.split('@')[0]
  const {t} = useTranslation()

  return (
    <UserCardContainer onClick={() => history.push(`/${t('settings')}`)}>
      <ProfileImage
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        size={40}
        style={{ marginRight: 16 }}
      />
      <UserInfoContainer>
        <Text variant='body2'>{limitLongString(fullName, 29)}</Text>
        <Text variant='body2'>{limitLongString(email, 30)}</Text>
        <Text variant='body2'>{version}</Text>
      </UserInfoContainer>
    </UserCardContainer>
  )
}

export default UserProfileCard
