import React, { useState, Fragment, useEffect } from 'react'
import { Icon, StandardModal } from 'components/common'
import { Container, PersonsContainer, InputActionWrapper } from './SigningDocumentModal.styles'
import { SigningFooter, Input } from './'
import { useHistory } from 'react-router-dom'
import { useSignDocument } from 'queries/documents/useSignDocument'
import './style.scss'
import theme from 'config/themes/theme'
import { useTranslation } from 'react-i18next'
import Validator from 'validator'
import { MESSAGE_INVALID_EMAIL } from 'constants/messages'
import { useToast } from 'hooks/useToast'
import {Loader} from "components/common"
import loader from "../../../common/Loader/Loader";

const classes = {
  input: {
    wrapper: 'Modal-Main-Input-wrapper',
  },
  button: {
    wrapper: 'Modal-Main-Button-wrapper',
  },
  email: {
    actions: {
      wrapper: 'Modal-Main-Email-Actions-wrapper',
    },
  },
  divider: 'Modal-Main-Divider-wrapper',
  empty: {
    container: { wrapper: 'Modal-Main-Empty-Container-Wrapper' },
    icon: { wrapper: 'Modal-Main-Empty-Icon-Wrapper' },
    span: { wrapper: 'Modal-Main-Empty-Span-Wrapper' },
  },
  normal: {
    span: { wrapper: 'Modal-Main-Span-Wrapper' },
    icon: { wrapper: 'Modal-Main-Icon-Wrapper' },
  },
}

const steps = [
  {message: 'loader_sign_01', img: loader},   // step 0
  {message: 'loader_sign_02', img: loader},   // step 1
  {message: 'loader_sign_03', img: loader},   // step 2
  {message: 'loader_sign_done', img: loader}, // step 3
]

const EmptySigningWith = React.memo(({ text }) => {
  return (
    <div className={classes.empty.container.wrapper}>
      <Icon iconName="sign" fill="#d0d5dd" className={classes.empty.icon.wrapper} />
      <span className={classes.empty.span.wrapper}>{text}</span>
    </div>
  )
})

const ErrorOccurred = React.memo(({ close, text }) => {
  return (
    <div>
      <div className={classes.empty.container.wrapper}>
        <Icon iconName="error" fill={theme.colorz.brand} className={classes.normal.icon.wrapper} />
        <span className={classes.normal.span.wrapper}>{text}</span>
      </div>
      <SigningFooter isEnabled={true} handleClose={close} />
    </div>
  )
})

const SigningRequestSuccess = React.memo(({ close, text }) => {
  return (
    <div>
      <div className={classes.empty.container.wrapper}>
        <Icon iconName="check" fill={theme.colorz.brand} className={classes.normal.icon.wrapper} />
        <span className={classes.normal.span.wrapper}>{text}</span>
      </div>
      <SigningFooter isEnabled={true} handleClose={close} />
    </div>
  )
})

export const SigningDocumentModal = React.memo(({ signDocumentId }) => {
  const [tryCount, setTryCount] = useState(0)
  const [step, setStep] = useState(10)
  const [email, setEmail] = useState('')
  const { location, replace } = useHistory()
  const [signingWith, setSigningWith] = useState([])
  const sign = useSignDocument()
  const { t } = useTranslation()
  const toast = useToast()

  useEffect(() => {
    const interval = setInterval(() => {
      if (step < 2) {
        setStep(step => step + 1)
      }
    }, 3300);
    return () => clearInterval(interval);
  }, [sign, signDocumentId, signingWith, step]);

  // Breaking point template vs document
  const addSigner = email => {
    if (!signDocumentId || !document) return
    setSigningWith(arr => [...arr, email])
  }

  const isEnabled = () => {
    return signingWith.length > 0
  }

  const validateEmail = email => {
    const isEmail = Validator.isEmail(email)
    if (!isEmail) toast('error', MESSAGE_INVALID_EMAIL)
    return isEmail
  }

  const onEmailDelete = email => {
    setSigningWith(signingWith.filter(e => e !== email))
  }

  const onEditEmail = email => {
    setSigningWith(signingWith.filter(e => e !== email))
    setEmail(email)
  }

  const close = () => {
    replace(location.pathname)
  }

  const onSign = async () => {
    setStep(0)
    sign.mutate({ documentId: signDocumentId, signatureEmails: signingWith })
  }

  const handleButtonClick = e => {
    const isValid = validateEmail(email)
    if (!isValid) return
    addSigner(email)
    setEmail('')
  }

  let content = null

  if (sign.isLoading) {
      content = <div style={{margin: 100}}>
        <Loader />
        <h2 style={{textAlign: 'center'}}>{t(`${steps[step].message}`)}</h2>
      </div>
  } else if (sign.isError) {
    setTryCount(tryCount => tryCount + 1)
    if(tryCount < 3) {
      sign.mutate({documentId: signDocumentId, signatureEmails: signingWith})
      setStep(0)
    } else {
      content = <ErrorOccurred close={close} text={t('err_msg_default')}/>
    }
  } else if (sign.isSuccess) {
    content = <SigningRequestSuccess close={close} text={t('signature_success')} />
  } else {
    content = (
      <Fragment>
        <Fragment>
          <InputActionWrapper>
            <Input value={email} onChange={e => setEmail(e.target.value)} placeholder={t('email_address_signee')} />
            <div
              className={classes.button.wrapper}
              onClick={handleButtonClick}
              /* disabled={
                  email.length < 5 || !Validator.isEmail(email) || signingWith.includes(email)
                } */
            >
              <Icon iconName="add" fill="#FFFFFF" className={classes.button.wrapper} />
            </div>
          </InputActionWrapper>
          <hr className={classes.divider}></hr>
          <PersonsContainer>
            {signingWith.length > 0 ? (
              signingWith.map(person => (
                <InputActionWrapper key={person}>
                  <Input value={person} readOnly={true} />
                  <div className={classes.email.actions.wrapper}>
                    <div
                      className={classes.button.wrapper}
                      onClick={() => onEditEmail(person)}
                      disabled={email.length < 5 || !Validator.isEmail(email) || signingWith.includes(email)}
                    >
                      <Icon iconName="edit" fill="#FFFFFF" className={classes.button.wrapper} />
                    </div>
                    <div
                      className={classes.button.wrapper}
                      onClick={() => onEmailDelete(person)}
                      disabled={email.length < 5 || !Validator.isEmail(email) || signingWith.includes(email)}
                    >
                      <Icon iconName="close" fill="#FFFFFF" className={classes.button.wrapper} />
                    </div>
                  </div>
                </InputActionWrapper>
              ))
            ) : (
              <EmptySigningWith text={t('signature_empty')} />
            )}
          </PersonsContainer>
          <SigningFooter
            isEnabled={true}
            enablePrimaryButton={isEnabled()}
            handleClose={() => close()}
            handleFinishClick={() => onSign()}
          />
        </Fragment>
      </Fragment>
    )
  }

  return (
    <StandardModal
      open={!!signDocumentId}
      handleClose={close}
      title="e-Signature"
      primaryButtonDisabled={false}
      disableCloseButton={true}
      contentContainerStyles={{ style: { width: 480 } }}
    >
      <Container>{content}</Container>
    </StandardModal>
  )
})

