import React, { useMemo } from 'react'
import useStore from '___store'
import { CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'
import Insert from 'assets/svgIconComponents/Insert'
import Cogwheel from 'assets/svgIconComponents/Cogwheel'
import Deselect from 'assets/svgIconComponents/Deselect'
import Trash from 'assets/svgIconComponents/Trash'

const classes = {
  wrapper: 'Wizard-Editor-Page-ReplacementMarker-wrapper',
  controlsDiv: 'Wizard-Editor-Page-ReplacementMarker-controlsDiv',
}

const chunkStylesToIgnore = ['Normal']

const mapChunkToSpan = (id, text, styles, i = 0) => {
  if (styles.length) {
    styles.unshift(CASUS_CLASSES.textChunk)
    return (
      <span key={`replacement-marker-${id}-text-chunk-${i}`} className={styles.join(' ')}>
        {text}
      </span>
    )
  }
  return text
}

export const ReplacementMarker = React.memo(props => {
  const { id, textChunks } = props
  const {
    marker,
    currentQuestion: isRelevantQuestion,
    configuring,
    assignMarker,
    stripMarker,
    mode,
  } = useStore(
    `selectMarker[${id}]`,
    `selectCurrentQuestion[replacement,${id}]`,
    `selectConfiguring[${id}]`,
    'assignMarker',
    'stripMarker',
    'selectMode'
  )

  const { questionId = '', value, color } = marker

  const markerMode = mode === 'template-creation' ? 'edit' : 'preview'

  const children = useMemo(() => {
    const resultingChunks = value
      ? [
          {
            spanStyles: Array.from(new Set(textChunks.reduce((acc, { styles }) => [...acc, ...styles], []))).filter(
              s => s && !chunkStylesToIgnore.includes(s)
            ),
            text: value,
          },
        ]
      : textChunks.map((chunk, i) => ({
          spanStyles: [chunk.customStyle || '', ...(chunk.styles || [])].filter(
            s => s && !chunkStylesToIgnore.includes(s)
          ),
          text: chunk.text || '',
        }))
    return resultingChunks.map(({ spanStyles, text }, i) => mapChunkToSpan(id, text, spanStyles, i))
  }, [textChunks, id, value])

  // const children = props.text

  // console.groupCollapsed(`REPLACEMENT MARKER %c${id}`, 'color: lightgreen;', 'RENDER!')
  // textChunks.forEach(({ text }) => console.log(text))
  // console.groupEnd()

  const markerClassName = [classes.wrapper, CASUS_CLASSES.replacementMarker].join(' ')

  return (
    <mark
      id={`replace-${id}`}
      className={markerClassName}
      data-mode={markerMode}
      data-configuring={configuring}
      // data-configuring-question={!!isRelevantQuestion}
      data-non-assignable={!isRelevantQuestion && !!questionId}
      data-configuring-relevant-question={isRelevantQuestion}
      style={{ '--color': color }}
    >
      {!questionId && markerMode === 'edit' ? (
        <div className={classes.controlsDiv} data-leading={true}>
          <button type="button" tabIndex={-1} onClick={event => assignMarker({ markerId: id, type: 'replacement' })}>
            <Insert />
          </button>
        </div>
      ) : null}
      {children}
      {markerMode === 'edit' ? (
        <div className={classes.controlsDiv} data-trailing={true}>
          <button type="button" tabIndex={-1} onClick={() => console.log('CONFIGURE!')}>
            <Cogwheel />
          </button>
          <button type="button" tabIndex={-1} onClick={() => stripMarker({ id })}>
            <Deselect />
          </button>
          <button type="button" tabIndex={-1} onClick={() => console.log('DELETE!')}>
            <Trash />
          </button>
        </div>
      ) : null}
    </mark>
  )
})

ReplacementMarker.displayName = 'Wizard-Editor-ReplacementMarker'

export default ReplacementMarker
