import {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  CASUS_CLASSES,
  STOP_DEPTH_NODE_CLASSES,
  SKIP_DEPTH_NODE_CLASSES,
  IGNORE_NODE_CLASSES,
} from 'TemplateCreation-DocumentGeneration/constants'

// import { classes } from './components/Page'

const falseChoiceParentTypes = [
  SEGMENT_TYPES.table,
  SEGMENT_TYPES.tableHeader,
  SEGMENT_TYPES.tableBody,
  SEGMENT_TYPES.tableFooter,
  SEGMENT_TYPES.tableRow,
]

const validateForMarking = (start = {}, end = {}) => {
  const { offset: startOffset, edgeSegments: startEdgeSegments, parentSegments: startParentSegments } = start
  const { offset: endOffset, edgeSegments: endEdgeSegments, parentSegments: endParentSegments } = end
  let notDone = true

  let choiceParent = null
  let choiceRange = null
  let replacementParent = null
  let replacementRange = null

  while (notDone) {
    const startNode = startParentSegments.pop() || startEdgeSegments.pop()
    const endNode = endParentSegments.pop() || endEdgeSegments.pop()
    if (startNode?.id !== endNode?.id) {
      notDone = false
      break
    }
    const choiceValid =
      !(startParentSegments.length || endParentSegments.length) &&
      startEdgeSegments.length &&
      endEdgeSegments.length &&
      !choiceParent &&
      !falseChoiceParentTypes.includes(startNode.type)
    if (choiceValid) {
      choiceParent = startNode
      // choiceRange = [startEdgeSegments[startEdgeSegments.length - 1].id, endEdgeSegments[endEdgeSegments.length - 1].id]

      // ======================================================================================================== //
      // ============================ MAYBE ALSO NEEDED FOR REPLACEMENT, CHECK LATER ============================ //
      // ======================================================================================================== //
      const filteredStartEdgeSegments = startEdgeSegments.filter(({ type }) => type !== SEGMENT_TYPES.container)
      const startEdgeLength = filteredStartEdgeSegments.length
      const lastStartEdgeNodeId = filteredStartEdgeSegments[startEdgeLength - 1].id
      const filteredEndEdgeSegments = endEdgeSegments.filter(({ type }) => type !== SEGMENT_TYPES.container)
      const endEdgeLength = filteredEndEdgeSegments.length
      const lastEndEdgeNodeId = filteredEndEdgeSegments[endEdgeLength - 1].id
      choiceRange = [lastStartEdgeNodeId, lastEndEdgeNodeId]
      // ======================================================================================================== //
      // ======================================================================================================== //
      // ======================================================================================================== //
    }
    const empty = !(
      startParentSegments.length ||
      endParentSegments.length ||
      startEdgeSegments.length ||
      endEdgeSegments.length
    )
    if (empty) {
      notDone = false
      if (startNode.type === SEGMENT_TYPES.paragraph) {
        replacementParent = startNode
        replacementRange = [startOffset, endOffset]
      }
    }
  }

  const choice = { parent: choiceParent, range: choiceRange }
  const replacement = { parent: replacementParent, range: replacementRange }

  // console.log('CHOICE: ', choice)
  // console.log('REPLACEMENT: ', replacement)

  return { choice, replacement }
}

const getChildNodes = (node = {}) =>
  Array.from(node.childNodes || []).filter(
    n => !Array.from(n.classList || []).some(className => IGNORE_NODE_CLASSES.includes(className))
  )

const getNodeLength = (node = {}) => (node.nodeName === '#text' ? node.length : getChildNodes(node).length)

// const ignoreClasses = [classes.page, classes.content]

const getUpstreamSegmentIdsArray = (node = {}, direction = 'start', allTheWay = false, propagatedArray = [[], []]) => {
  if (Array.from(node.classList || []).some(className => STOP_DEPTH_NODE_CLASSES.includes(className)))
    return propagatedArray
  const parent = node.parentNode
  if (!parent || parent.nodeName === '#document') return propagatedArray
  const regex = /((^[^-]+)-)?(.+$)/g
  const [match = []] = Array.from(parent.id?.matchAll(regex) || []) || []
  const parentId = match[2] === 'section' ? 'root' : match[3] || ''
  const [parentType] = Object.entries(SEGMENT_TAGS).find(([_, v]) => v === parent.localName) || []
  const isTableHeader = Array.from(parent.classList).includes(CASUS_CLASSES.tableHeader)
  const isTableBody = Array.from(parent.classList).includes(CASUS_CLASSES.tableBody)
  const isTableFooter = Array.from(parent.classList).includes(CASUS_CLASSES.tableHeader)
  const isParentTableSection = isTableHeader || isTableBody || isTableFooter
  const offsetWithinParent = getChildNodes(parent).indexOf(node) + Number(direction === 'end')
  const length = getNodeLength(parent)
  const isParentMarker =
    parentType === SEGMENT_TYPES.container && Array.from(parent.classList).includes(CASUS_CLASSES.choiceMarkerDiv)
  const markerRange = (match || [])[2]?.split('/') || [0, 0, 0]
  const isOnMarkerEdge =
    isParentMarker &&
    Number(markerRange[Number(direction === 'end')]) === Number(markerRange[2]) * Number(direction === 'end')
  const onEdge = offsetWithinParent === 0 + Number(direction === 'end') * length && (!isParentMarker || isOnMarkerEdge)
  const index = Number(allTheWay || !onEdge)
  if (Array.from(parent.classList).some(c => SKIP_DEPTH_NODE_CLASSES.includes(c)))
    return getUpstreamSegmentIdsArray(parent, direction, allTheWay || Boolean(index), propagatedArray)
  const lastArray = propagatedArray[index] || []
  if (parentId) {
    lastArray.push({
      type: parentType,
      id: parentId,
    })
  } else if (isParentTableSection) {
    if (isTableHeader) lastArray.push({ type: SEGMENT_TYPES.tableHeader })
    if (isTableBody) lastArray.push({ type: SEGMENT_TYPES.tableBody })
    if (isTableFooter) lastArray.push({ type: SEGMENT_TYPES.tableFooter })
  }
  return getUpstreamSegmentIdsArray(parent, direction, allTheWay || Boolean(index), propagatedArray)
}

const getTextOffsetWithinParent = (parent = {}, node = {}, accumulatedOffset = 0) => {
  let accumulated = accumulatedOffset
  const calculateInnerTextOffset = n =>
    getChildNodes(n).some(
      c => c === node || (c.nodeName === '#text' ? Boolean((accumulated += c.length) * 0) : calculateInnerTextOffset(c))
    )
  return calculateInnerTextOffset(parent) ? accumulated : null
}

const stopClass = CASUS_CLASSES.paragraphSegment

const getTextNodeParent = (node = {}) => {
  if (Array.from(node.classList || []).some(className => className === stopClass)) return node
  const parent = node.parentNode
  return !parent || parent.nodeName === '#document' ? undefined : getTextNodeParent(parent)
}

const getUpstreamNode = (node = {}, direction = 'start') => {
  const parent = node.parentNode
  if (!parent) return null
  const offsetWithinParent = Array.from(parent.childNodes || []).indexOf(node) + Number(direction === 'start')
  const length = getNodeLength(parent)
  if (offsetWithinParent === 0 + Number(direction === 'start') * length) return getUpstreamNode(parent, direction)
  return [parent, offsetWithinParent]
}

const getDownstreamNode = (node = {}, offset = 0, direction = 'start') => {
  if (!node.childNodes?.length) return node
  const nextNode = Array.from(node.childNodes)[Math.max(offset - Number(direction === 'end'), 0)]
  return getDownstreamNode(nextNode, direction === 'start' ? 0 : getNodeLength(nextNode), direction)
}

// const findNodeWithContentDeep = (node = {}, offset = 0, direction = 'start') => {
//   debugger
//   //   console.log('DEEP NODE: ', node)
//   const isTextNode = node.nodeName === '#text'
//   const length = isTextNode ? node.length : node.childNodes.length
//   const isOnEdge = offset === 0 + Number(direction === 'start') * length - Number(direction === 'end')
//   //    - Number(direction === 'end' && !isTextNode)
//   if (length && !isOnEdge) {
//     if (isTextNode) return [node, offset + Number(direction === 'end')]
//     else {
//       const childNodes = Array.from(node.childNodes || [])
//       const firstNodeToCheck = childNodes[offset]
//       if (firstNodeToCheck) {
//         const isChildTextNode = firstNodeToCheck.nodeName === '#text'
//         const innerNodeLength = isChildTextNode
//           ? firstNodeToCheck.length
//           : Array.from(firstNodeToCheck.childNodes || []).length
//         if (innerNodeLength) {
//           const offsetWithin = Number(direction === 'end') * (innerNodeLength - Number(!isChildTextNode))
//           const res = findNodeWithContentDeep(firstNodeToCheck, offsetWithin, direction)
//           return res
//         }
//       }
//       const nextOffset = offset + (-1) ** Number(direction === 'end')
//       if (nextOffset >= 0) return findNodeWithContentDeep(node, nextOffset, direction)
//     }
//   }
//   return null
// }

// const findNodeWithContentShallow = (node = {}, offset = 0, direction = 'start') => {
//   debugger
//   //   console.log('SHALLOW NODE: ', node)
//   const res = findNodeWithContentDeep(node, offset - Number(direction === 'end'), direction)
//   if (res) return res
//   let nodeToCheck = node
//   let parentNode = null
//   let offsetWithinParent = null
//   let isOnEdge = true
//   do {
//     const tempNode = nodeToCheck
//     parentNode = tempNode.parentNode
//     const siblings = Array.from(parentNode.childNodes)
//     offsetWithinParent = siblings.findIndex(n => n === tempNode) + Number(direction === 'start')
//     const length = siblings.length
//     isOnEdge = offsetWithinParent === Number(direction === 'start') * length
//     nodeToCheck = parentNode
//   } while (isOnEdge && parentNode.id !== CASUS_CLASSES.section)
//   if (isOnEdge) return null
//   return findNodeWithContentShallow(nodeToCheck, offsetWithinParent, direction)
// }

const getNode = {
  downstream: getDownstreamNode,
  upstream: (node, _, direction) => getDownstreamNode(...getUpstreamNode(node, direction), direction),
}

const isOffsetOnEdge = (node = {}, offset = 0, direction = 'start') => {
  const length = getNodeLength(node)
  return [
    offset === 0 || offset === length,
    length && offset === 0 + Number(direction === 'start') * length ? 'upstream' : 'downstream',
  ]
}

const validateRange = (range = {}) => {
  const directions = ['start', 'end']
  const { start: startObject, end: endObject } = directions.reduce((acc, direction) => {
    const node = range[`${direction}Container`]
    const offset = range[`${direction}Offset`]

    const [edgy, dir] = isOffsetOnEdge(node, offset, direction)
    const startingDirection = edgy ? dir : 'downstream'

    const relevantNode =
      node.nodeName === '#text' && startingDirection === 'downstream'
        ? node
        : getNode[startingDirection](node, offset, direction)

    const relevantNodeOffset = Number(direction === 'end') * getNodeLength(relevantNode)

    const nodeOffset = relevantNode === node ? offset : relevantNodeOffset
    const textNodeOffsetWithinIdedParent = getTextOffsetWithinParent(
      getTextNodeParent(relevantNode),
      relevantNode,
      nodeOffset
    )
    const relevantOffset = relevantNode.nodeName === '#text' ? textNodeOffsetWithinIdedParent : nodeOffset

    const onEdge = isOffsetOnEdge(relevantNode, nodeOffset, direction)[0]
    const [edgeSegments, parentSegments] = getUpstreamSegmentIdsArray(relevantNode, direction, !onEdge)
    if (relevantNode.id)
      edgeSegments.unshift({
        type: Object.entries(SEGMENT_TAGS).find(([_, v]) => v === relevantNode.localName)[0],
        id: relevantNode.id,
      })

    Object.assign(acc, {
      [direction]: {
        offset: relevantOffset,
        edgeSegments,
        parentSegments,
      },
    })
    return acc
  }, {})

  console.log('START: ', JSON.parse(JSON.stringify(startObject)))
  console.log('END: ', JSON.parse(JSON.stringify(endObject)))

  return validateForMarking(startObject, endObject)
}

const getRange = (selection = {}) => {
  const { rangeCount } = selection
  if (rangeCount) {
    if (rangeCount === 1) {
      const { startContainer, startOffset, endContainer, endOffset } = selection.getRangeAt(0)
      return { startContainer, startOffset, endContainer, endOffset }
    }
    const startRange = selection.getRangeAt(0)
    const endRange = selection.getRangeAt(rangeCount - 1)
    const { startContainer, startOffset } = startRange
    const { endContainer, endOffset } = endRange
    return { startContainer, startOffset, endContainer, endOffset }
  }
  return {}
}

export const parseSelection = (selection = {}) => {
  const range = selection.isCollapsed ? null : getRange(selection)
  if (!range) return null
  return validateRange(range)
}
