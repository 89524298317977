import React, { useState, useCallback, useMemo, useEffect } from 'react'

import useStore from '___store'
import { debounceFunction } from 'utilities/helpers'
import {
  generateListStartString,
  generateListStylesString,
  generateStyleString,
} from 'TemplateCreation-DocumentGeneration/helpers'

import { parseSelection } from './selection'
import { Section } from './components'
import EditorControls from './components/EditorControls'

export const classes = {
  wrapper: 'Wizard-Editor-wrapper',
  controlsDiv: 'Wizard-Editor-controls',
  standardStyleButton: 'Wizard-Editor-standardStyleButton',
}

const selectionKeyboardKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'End', 'Home', 'PageUp', 'PageDown']

const noValidSelection = { choice: null, replacement: null }

export const Editor = React.memo(() => {
  const [selectionRange, setSelectionRange] = useState(noValidSelection)

  const { sectionCount, numberingSystem, styles } = useStore(
    'selectSectionCount',
    'selectNumberingSystem',
    'selectStyles'
  )

  const render = useMemo(
    () =>
      new Array(sectionCount).fill(null).map((_, i) => {
        return <Section key={`Section-${i}`} index={i}></Section>
      }),
    [sectionCount]
  )

  const removeSelectionHandler = useCallback(() => {
    // document.getSelection().removeAllRanges()
    setSelectionRange(prev => (prev.choice || prev.replacement ? noValidSelection : prev))
  }, [])

  const selectionHandler = useCallback(
    (event, type) => {
      if (type === 'keyboard' && !selectionKeyboardKeys.includes(event.key)) {
        if (event.key === 'Escape') removeSelectionHandler()
        return
      }
      const documentSelection = document.getSelection()
      const parsed = parseSelection(documentSelection)
      if (!parsed) return removeSelectionHandler()
      // if (!parsed) return
      const selection = {
        choice: null,
        replacement: null,
      }
      const { choice, replacement } = parsed
      if (choice.parent) selection.choice = choice
      if (replacement.parent) selection.replacement = replacement
      setSelectionRange(selection)
    },
    [removeSelectionHandler]
  )

  const dataValid = useMemo(() => {
    const { choice, replacement } = selectionRange
    if (choice && replacement) return 'both'
    if (choice) return 'choice'
    if (replacement) return 'replacement'
    return null
  }, [selectionRange])

  const styleSheet = useMemo(() => {
    // const revealEditor = '.Wizard-Editor-wrapper { display: flex !important; }'
    const listStartString = generateListStartString(numberingSystem)
    const listStylesString = generateListStylesString(numberingSystem)
    const styleString = generateStyleString(styles)
    return [
      // revealEditor,
      listStartString,
      listStylesString,
      styleString,
    ]
      .filter(s => s)
      .join('\n')
  }, [styles, numberingSystem])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = styleSheet
    document.head.appendChild(styleElement)
    return () => document.head.removeChild(styleElement)
  }, [styleSheet])

  useEffect(() => {
    const mouseHandler = debounceFunction(event => selectionHandler(event, 'mouse'))
    const keyboardHandler = debounceFunction(event => selectionHandler(event, 'keyboard'))
    document.addEventListener('mouseup', mouseHandler)
    document.addEventListener('keydown', keyboardHandler)
    return () => {
      document.removeEventListener('mouseup', mouseHandler)
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [selectionHandler])

  // console.log('EDITOR RENDER!')

  return (
    <section className={classes.wrapper} data-valid={dataValid}>
      {render}
      <EditorControls selection={selectionRange} />
    </section>
  )
})

Editor.displayName = 'Wizard-Editor'

export default Editor
