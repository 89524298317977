import { StepProps } from './Steps.types'
import React from 'react'

import { StepContainer, CheckIcon } from './Steps.styles'
import { useTranslation } from 'react-i18next'

const Step: React.FC<StepProps> = ({ step, label, currentStep }) => {
  const { t } = useTranslation()
  const labelText = `${step}. ${t(label)}`
  const isCompleted = currentStep > step
  return (
    <StepContainer id={step.toString()} isactive={currentStep === step ? 1 : 0}>
      {!isCompleted ? labelText : <CheckIcon />}
    </StepContainer>
  )
}

export default Step
