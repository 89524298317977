import React, { useEffect, useMemo } from 'react'

import { CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'
import { generatePageLayoutString } from 'TemplateCreation-DocumentGeneration/helpers'

import Page from './Page'
import useStore from '___store'

const classes = {
  section: 'Wizard-Editor-Section',
  separator: 'Wizard-Editor-Section-separator',
}

export const Section = React.memo(props => {
  const { index } = props
  const { sectionInfo = {} } = useStore(`selectSectionInfo[${index}]`)
  const {
    id,
    // title,
    layout,
    pages = [],
  } = sectionInfo

  // console.log('SECTION INFO: ', sectionInfo)

  const pageCount = pages.length

  const render = useMemo(
    () =>
      new Array(pageCount)
        .fill(null)
        .map((_, i) => <Page key={`section: ${index} - page: ${i}`} sectionIndex={index} index={i} />),
    [pageCount, index]
  )

  const styleSheet = useMemo(() => generatePageLayoutString(id, layout), [id, layout])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = styleSheet
    document.head.appendChild(styleElement)
    return () => document.head.removeChild(styleElement)
  }, [styleSheet])

  // console.log(`SECTION %c${id}`, 'color: lightgreen;', 'RENDER!')

  const className = [classes.section, CASUS_CLASSES.section].join(' ')

  return (
    <>
      <div className={classes.separator} />
      <div id={`section-${id}`} className={className}>
        {/* <span>{title}</span> */}
        {render}
      </div>
    </>
  )
})

Section.displayName = 'Wizard-Editor-Section'

export default Section
