import React from 'react'
import { SidebarContainer, Container, PaddingWrapper, Logo } from './Sidebar.styles'
import { CustomButton, Icon } from '../common'
import { ISidebar } from './Sidebar.types'
import UserProfileCard from './UserProfileCard'
import logoimg from 'assets/logo.svg'
import SidebarLink from './SidebarLink'

const Sidebar: React.FC<ISidebar> = ({
  children,
  isLoading,
  actionButtonText,
  actionButtonDisabled,
  user,
  baseUrl,
  urlLinks,
  handleActionClick,
}) => {
  const { email, imageUrl, firstName, lastName } = user
  const isDisabled = isLoading || actionButtonDisabled

  return (
    <SidebarContainer variant='main'>
      <PaddingWrapper>
        <Logo src={logoimg} />
        {<span style={{verticalAlign: "top", color: "white"}}>{process.env.REACT_APP_NODE_ENV}</span>}
        {Boolean(handleActionClick && actionButtonText) && (
          <CustomButton
            size='large'
            btntype='primary'
            onClick={handleActionClick}
            disabled={isDisabled}
            width='100%'
          >
            <Icon iconName='add' fill='#fff' style={{ marginRight: 4 }} />
            {actionButtonText}
          </CustomButton>
        )}
      </PaddingWrapper>
      <Container>
        {urlLinks &&
          baseUrl &&
          urlLinks.map(({ url, urlName }) => (
            <SidebarLink
              key={urlName}
              url={baseUrl + url}
              urlName={urlName}
              variant='main'
            />
          ))}
        {children}
      </Container>
      <UserProfileCard
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        email={email}
        version={user.version}
      />
    </SidebarContainer>
  )
}

export default Sidebar
