import {
  Box,
  DialogActions,
  DialogTitle,
  Typography,
  styled,
  BoxProps,
  Theme,
} from '@material-ui/core'
import { IStatus } from 'types'

export const TitleContainer = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 68,
}))

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexShrink: 1,
  border: 'none',
  borderRadius: 8
}))

export const ActionsContainer = styled(DialogActions)(({ theme }) => ({
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1),
  height: 120,
}))

interface ModalContentEl extends BoxProps {
  theme: Theme
  status?: IStatus
}

export const ModalContent = styled(Box)(({ theme, status }: ModalContentEl) => ({
  borderTop: `4px solid ${status ? theme.colorz.status[status] : 'transparent'}`,
  background: '#fff',
  outline: 'none',
  // minHeight: 400,
  overflowY: 'auto',
  boxShadow: '0 0 88px rgba(0,0,0,0.1)',
  borderRadius: 8,
}))

export { Typography }
