import React, { useMemo } from 'react'
import useStore from '___store'

import { Input } from 'components'
import { Deselect, Plus, Trash } from 'assets/svgIconComponents'
import QuestionListItem from './QuestionListItem'
// import QuestionListItem from './QuestionListItem'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionGeneration-QuestionGroup-wrapper',
  inputWrapper: 'Wizard-Configuration-QuestionGeneration-QuestionGroup-inputWrapper',
  input: 'Wizard-Configuration-QuestionGeneration-QuestionGroup-input',
  controlsDiv: 'Wizard-Configuration-QuestionGeneration-QuestionGroup-controlsDiv',
}

const QuestionGroup = React.memo(({ id }) => {
  const {
    questionLayout: questionLayoutGroup = {},
    updateQuestionLayoutGroup,
    unpackQuestionLayoutGroup,
    removeQuestionLayoutGroup,
    addNewQuestion,
  } = useStore(
    `selectQuestionLayout[${id}]`,
    'updateQuestionLayoutGroup',
    'unpackQuestionLayoutGroup',
    'removeQuestionLayoutGroup',
    'addNewQuestion'
  )
  const { type, label, questionIds = [] } = questionLayoutGroup
  // const groupIsEmpty = !questionIds.length

  // console.groupCollapsed(`GROUP %c${label}`, 'color: lightgreen;', 'RENDER!')
  // console.log('id: ', id)
  // console.groupCollapsed('questionIds')
  // questionIds.forEach(qid => console.log(qid))
  // console.groupEnd()
  // console.groupEnd()

  const children = useMemo(
    () => questionIds.map(qid => <QuestionListItem key={qid} id={qid} isStandalone={type === 'loose'} />),
    [questionIds, type]
  )

  const groupRender = useMemo(
    () => (
      <div className={classes.wrapper}>
        <div
          className={classes.inputWrapper}
          style={{ '--label': `"${label}"` }}
          // data-empty={groupIsEmpty}
        >
          <Input
            className={classes.input}
            placeholder={'Group label...'}
            defaultValue={label || id}
            needsConfirmation
            onChange={value => updateQuestionLayoutGroup({ id, label: value })}
          />
        </div>
        <div className={classes.controlsDiv}>
          {/* {groupIsEmpty ? ( */}
          <button type="button" onClick={() => addNewQuestion({ questionLayoutGroup: id })}>
            <Plus />
          </button>
          {/* ) : null} */}
          <button type="button" onClick={() => unpackQuestionLayoutGroup({ id })}>
            <Deselect />
          </button>
          <button type="button" onClick={() => removeQuestionLayoutGroup({ id })}>
            <Trash />
          </button>
        </div>
        {children}
        {/* <button type="button" onClick={() => addNewQuestion({ questionLayoutGroupId: id })}>
          New question
        </button> */}
      </div>
    ),
    [
      label,
      // groupIsEmpty,
      id,
      updateQuestionLayoutGroup,
      addNewQuestion,
      unpackQuestionLayoutGroup,
      removeQuestionLayoutGroup,
      children,
    ]
  )

  return type === 'loose' ? children : groupRender
})

QuestionGroup.displayName = 'Wizard-Configuration-QuestionGeneration-QuestionGroup'

export default QuestionGroup
