import { v4 as uuid } from 'uuid'
import ACTION_TYPES from '___store/actionTypes'
import { getLogArray } from '___store/helpers'
import { getStateQuestion, getStateQuestionLayoutGroup } from './helpers'

const getCurrentConfiguringQuestion = store => {
  const { configuringStack } = store.select(['selectConfiguringStack'])
  const configuringQuestionId = configuringStack
    .slice()
    .reverse()
    .find(c => c.key === 'question')?.id
  const { questions = [] } = store.select(['selectQuestions'])
  return questions.find(q => q.id === configuringQuestionId)
}

const interceptors = {
  [ACTION_TYPES.BATCH_ACTIONS]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      const { type, actionArray } = action
      console.groupCollapsed(...getLogArray('intercept', type))
      actionArray.forEach(({ type, payload }) => {
        console.log(`${type}: `, payload)
      })
      console.groupEnd()
      actionArray.forEach(action => store.dispatch(action))
    },
  },
  [ACTION_TYPES.CONFIGURING_SET]: {
    check: (store, payload) => {
      const { id, key } = store.select(['selectConfiguring'])?.configuring || {}
      return payload.id === id && payload.key === key
    },
    method: (store, next, action) => {
      const { type, payload } = action
      console.groupCollapsed(...getLogArray('intercept', type))
      Object.entries(payload).forEach(([key, value]) => {
        console.log(`${key}: `, value)
      })
      console.groupEnd()
    },
  },
  [ACTION_TYPES.QUESTION_LAYOUT_GROUP_REMOVED]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      const { type, payload } = action
      const { id } = payload
      const [questionLayoutGroup, index] = getStateQuestionLayoutGroup(store.getState()?.wizard, id)
      if (index === -1) {
        console.groupCollapsed(...getLogArray('intercept', type))
        Object.entries(payload).forEach(([key, value]) => {
          console.log(`${key}: `, value)
        })
        console.groupEnd()
        return
      }
      next(action)
      const { questionIds = [] } = questionLayoutGroup
      const questionCount = questionIds.length
      if (questionCount) {
        if (questionCount === 1) {
          const questionRemovedAction = { type: ACTION_TYPES.QUESTION_REMOVED, payload: { id: questionIds[0] } }
          store.dispatch(questionRemovedAction)
        }
      } else {
        const actionArray = []
        const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
        questionIds.forEach(qid => actionArray.push({ type: ACTION_TYPES.QUESTION_REMOVED, payload: { id: qid } }))
        store.dispatch(batchActions)
      }
    },
  },
  [ACTION_TYPES.NEW_QUESTION_ADDED]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      if (!action.payload) Object.assign(action, { payload: { id: uuid() } })
      if (!action.payload.id) Object.assign(action.payload, { id: uuid() })
      const { payload } = action
      const { id } = payload
      next(action)
      const configuringSetAction = { type: ACTION_TYPES.CONFIGURING_SET, payload: { key: 'question', id } }
      store.dispatch(configuringSetAction)
    },
  },
  [ACTION_TYPES.LOCATION_MARKED]: {
    check: (store, payload) => {
      const { type: markerType } = payload
      const { type: questionType } = getCurrentConfiguringQuestion(store) || {}
      return markerType === questionType
    },
    method: (store, next, action) => {
      next(action)
      const { payload } = action
      const { locationId: markerId, type } = payload
      const { id: questionId } = getCurrentConfiguringQuestion(store)
      const assignMarkerAction = { type: ACTION_TYPES.MARKER_ASSIGNED, payload: { markerId, questionId, type } }
      store.dispatch(assignMarkerAction)
    },
  },
  [ACTION_TYPES.QUESTION_ANSWERED]: {
    check: (store, payload) => true,
    method: (store, next, action) => {
      next(action)
      const { payload } = action
      const { id } = payload
      const [question, index] = getStateQuestion(store.getState()?.wizard, id)
      if (index === -1) return
      const { type = 'choice', markers } = question
      if (markers.length) {
        const actionArray = []
        const batchActions = { type: ACTION_TYPES.BATCH_ACTIONS, actionArray }
        const actionType = type === 'choice' ? ACTION_TYPES.CHOICE_MARKER_EVALUATED : ACTION_TYPES.REPLACEMENT_MARKER_EVALUATED
        markers.forEach(markerId =>
          actionArray.push({
            type: actionType,
            payload: { id: markerId },
          })
        )
        store.dispatch(batchActions)
      }
    },
  },
}

export { interceptors }
export default interceptors
