import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'

import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootFolder } from 'hooks/useRootFolder'
import { useToast } from 'hooks/useToast'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import PagesLayout from 'layout/PagesLayout/PagesLayout'

import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { FileManager, toBase64 } from 'utils'
import { Container, PageTitle } from '../../Authenticated.page.styles'
import DocumentsSidebar from './DocumentsSlidebar/DocumentsSidebar'

import { queryKeys } from 'queries/queryKeys'
import DocsApi, { DocsUploadApi } from 'api/docs.api'
import { POPUP_CONFIRM_DELETE_ALL_FILES } from 'constants/confirmation-popups'
import { FilePicker } from 'components/common'
import { documentsRoutes } from 'constants/routes'
import { useTranslation } from 'react-i18next'

export const DocumentsLayout: React.FC = ({ children }) => {
  const { location, push } = useHistory()
  const { currentFolder, setCurrentFolder } = useCurrentFolder('docs')
  const { rootFolder } = useRootFolder('docs')
  const queryClient = useQueryClient()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { id, type } = currentFolder
  const toast = useToast()
  const [file, setFile] = useState<null | File>(null)
  const { check } = useSubscriptionCheck()
  const {t} = useTranslation()

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        toast('info', 'Ihr Dokument wird hochgeladen. Einen Moment bitte...')
        const base64data = await toBase64(file)
        await DocsUploadApi.uploadPDF({
          category: id === type ? [] : [id],
          filename: file.name ?? '',
          base64data,
        })
        queryClient.invalidateQueries([queryKeys.DOCUMENTS, { type, folderId: id }])
        toast('success', 'Ihr Dokument wurde hochgeladen.')
      } catch (err) {
        toast('error', 'default')
      } finally {
      }
    },
    [id, type, toast, queryClient]
  )

  useEffect(() => {
    if (file) {
      uploadFile(file)
      setFile(null)
    }
  }, [file, uploadFile])

  const filePickRef = useRef<HTMLButtonElement>(null)

  const onEmptyBin = async () => {
    if (!check()) return
    setIsLoading(true)
    try {
      await DocsApi.emptyTrashed()
      queryClient.invalidateQueries([
        queryKeys.DOCUMENTS,
        { type: 'trashed', folderId: 'trashed' },
      ])
      queryClient.invalidateQueries([queryKeys.TEMPLATE_FOLDERS, 'trashed'])
      close()
    } catch (err) {
      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  const getMenuItems = (): IPopupMenuItem[] | undefined => {
    if (location.pathname !== documentsRoutes.main) return
    const menuItems: IPopupMenuItem[] = []
    switch (type) {
      case 'my':
        if (type === 'my') {
          menuItems.push({
            label: t('new_folder'),
            icon: 'add-folder',
            handleClick: () => {
              if (check({ isFree: true })) push(`${location.pathname}?createFolder=true`)
            },
          })
        }

        menuItems.push({
          label: t('upload'),
          icon: 'upload',
          handleClick: () => {
            if (check({ isFree: true })) filePickRef.current?.click()
          },
        })

        break
      case 'trashed':
        menuItems.push({
          label: t('empty_bin'),
          icon: 'trash',
          handleClick: () =>
            popup({
              ...POPUP_CONFIRM_DELETE_ALL_FILES,
              onConfirm: onEmptyBin,
              onCancel: close,
            }),
        })
    }
    return menuItems
  }

  const onPathClick = (folderId: string) => {
    const fileManager = new FileManager(rootFolder, currentFolder)
    const folder = fileManager.findFolderById(folderId)
    if (folder) setCurrentFolder(folder)
  }

  const showBreadCrumbNavigation = location.pathname === documentsRoutes.main
  const isRemindersPage = window.location.pathname === documentsRoutes.reminders

  return (
    <Container>
      <DocumentsSidebar />
      <PagesLayout
        isLoading={false}
        menuItems={getMenuItems()}
        breadcrumbNavProps={
          showBreadCrumbNavigation
            ? {
                folder: currentFolder,
                handlePathClick: onPathClick,
              }
            : undefined
        }
        PageAssetsComponent={
          isRemindersPage ? <PageTitle>Erinnerungen</PageTitle> : undefined
        }
      >
        {children}
        <FilePicker accept='application/pdf' setFile={setFile}>
          <button
            style={{ position: 'absolute', visibility: 'hidden' }}
            ref={filePickRef}
          >
            dasdasd
          </button>
        </FilePicker>
      </PagesLayout>
    </Container>
  )
}
