import React from 'react'
import { Heading } from '../common-styles'
import { Form } from './LoginForm.styles'
import { TextInputGroup, CustomButton, TextWithUrl } from 'components/common'
import { ILoginFormState } from './LoginForm.container'
import { useTranslation } from 'react-i18next'

interface Props {
  isLoading: boolean
  values: ILoginFormState
  allowClientRegister: boolean
  handleChange: React.ChangeEventHandler
  handleSubmit: React.FormEventHandler
}

const LoginForm: React.FC<Props> = ({
  isLoading,
  allowClientRegister,
  values,
  handleChange,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant='h3'>Login ({process.env.REACT_APP_NODE_ENV})</Heading>
      <TextInputGroup
        type='email'
        label={t('your_email_address')}
        name='email'
        value={values.email}
        placeholder='z.B. marina@meyer.ch'
        onChange={handleChange}
        required
      />

      <TextInputGroup
        type='password'
        label={t('u_password')}
        name='password'
        value={values.password}
        onChange={handleChange}
        labelUrl={{ text: t('forgot_password'), url: '/auth/passwort-wiederherstellen' }}
        required
      />

      <CustomButton
        type='submit'
        isLoading={isLoading}
        btntype='primary'
        size='large'
        width='100%'
        disabled={isLoading}
      >
        {!isLoading ? 'Login' : t('one_moment_please')}
      </CustomButton>
      {allowClientRegister && (
        <TextWithUrl
          text={t('dont_have_account_yet')}
          urlText={"Book a Call"}
          handleUrlClick={() => window.location.href = 'https://calendly.com/team-casus/call-mit-casus'}
        />
      )}
    </Form>
  )
}

export default LoginForm
