import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'

const classes = {
  wrapper: 'Switch-wrapper',
  text: 'Switch-text',
  pin: 'Switch-pin',
}

export const Switch = React.memo(
  forwardRef((props, ref) => {
    const { className: cn, defaultValue = 0, value, onChange, labels = [] } = props

    const [state, setState] = useState(value || defaultValue)

    const result = useMemo(() => value || state, [value, state])
    useImperativeHandle(ref, () => result, [result])

    const className = [classes.wrapper, cn].join(' ')
    return (
      <button
        type="button"
        onClick={() => {
          if (typeof onChange === 'function') onChange(Number(!result))
          setState(Number(!result))
        }}
        className={className}
      >
        <span className={classes.text} data-active={!result}>
          {labels[0] || ''}
        </span>
        <span className={classes.text} data-active={!!result}>
          {labels[1] || ''}
        </span>
        <div className={classes.pin} data-switched={!!result} />
      </button>
    )
  })
)

Switch.displayName = 'Switch'

export default Switch
