import { v4 as uuid } from 'uuid'

import { fullAssign, filterObjectFields, deepAssign } from './index'

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// ///////////////////// HELPERS ///////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

// Generates and returns a dependency
const generateDependency = d => Object.assign({ dependants: [], dependentOn: [] }, d)
// Appends an empty dependency with the given id
const addDependency = (state, id, d) =>
  state.dependencies[id] ? state : fullAssign({}, state, { dependencies: Object.assign({}, state.dependencies, { [id]: generateDependency(d) }) })
// Generates a dependency array by adding/removing the provided array to/from dependants/dependentOn based on method and field (respectively)
const generateDependencyArray = (dependency, field, method, array = []) =>
  (dependency && (method === 'add' ? Array.from(new Set(dependency[field].concat(array))) : dependency[field].filter(d => !array.includes(d)))) || []
// Updates an existing dependency with the information given in the payload
const updateDependency = (state, id, payload) => {
  const dependency = state.dependencies[id]
  const resultingDependants = generateDependencyArray(dependency, 'dependants', payload.dependantsMethod, payload.dependants)
  const resultingDependentOn = generateDependencyArray(dependency, 'dependentOn', payload.dependentOnMethod, payload.dependentOn)
  if (!(dependency && (resultingDependants.length !== dependency.dependants || resultingDependentOn.length !== dependency.dependentOn))) return state
  return (
    Object.assign(state.dependencies, { [id]: { dependants: resultingDependants, dependentOn: resultingDependentOn } }) && Object.assign({}, state)
  )
}
// Creates a dependency between two ids (either by updating existing dependencies or adding new ones)
const createDependency = (state, supporterId, dependantId) =>
  (state.dependencies[supporterId]
    ? updateDependency(state, supporterId, { dependantsMethod: 'add', dependants: [dependantId] })
    : addDependency(state, supporterId, { dependants: [dependantId] })) &&
  (state.dependencies[dependantId]
    ? updateDependency(state, dependantId, { dependentOnMethod: 'add', dependentOn: [supporterId] })
    : addDependency(state, dependantId, { dependentOn: [supporterId] }))
// Dissolves a dependency between two ids
const dissolveDependency = (state, supporterId, dependantId, update = false) => {
  update =
    (state.dependencies[supporterId] && updateDependency(state, supporterId, { dependantsMethod: 'remove', dependants: [dependantId] })) || update
  update =
    (state.dependencies[dependantId] && updateDependency(state, dependantId, { dependentOnMethod: 'remove', dependentOn: [supporterId] })) || update
  return update ? Object.assign({}, state) : state
}

const dissolveAllQIDsDependencies = (state, qid) =>
  !(state.dependencies[qid]?.dependants.length || state.dependencies[qid]?.dependentOn.length)
    ? state
    : !state.dependencies[qid]?.dependants.forEach(id => dissolveDependency(state, qid, id)) &&
      !state.dependencies[qid]?.dependentOn.forEach(id => dissolveDependency(state, id, qid)) &&
      Object.assign({}, state)
// Generates and returns a questionLayout group separator
const generateQLGseparator = () => ({ id: uuid(), type: 'separator', label: 'New separator' })
// Generates and returns a questionLayout group
const generateQLG = g => Object.assign({ id: uuid(), type: 'group', label: 'New group', questionIds: [] }, g)
// Generates and returns a loose questionLayout group
const generateLooseQLG = g => Object.assign(generateQLG(g), { type: 'loose', label: 'loose' })
// Appends a questionLayout group to the end of the questionLayout array, and UPDATES THE STATE
const appendToQL = (state, g) => fullAssign({}, state, { questionLayout: state.questionLayout.concat(generateQLG(g)) })
// Inserts a questionLayout group into the questionLayout array at the given index, and UPDATES THE STATE
const insertIntoQL = (state, i, g) =>
  fullAssign({}, state, { questionLayout: state.questionLayout.slice(0, i).concat(generateQLG(g)).concat(state.questionLayout.slice(i)) })
// Searches for a questionLayout group (by passing a provided test function), and returns the group and its index in the form of an array ([g, i])
const getQLG = (state, test, i = -1) => [state.questionLayout.find((g, j) => test(g) && ((i = j) || true)) || null, i]
// Searches for a questionLayout group (by matching its id to the given id), and returns the group and its index in the form of an array ([g, i])
const getQLGbyID = (state, id) => getQLG(state, g => g.id === id)
// Searches for a questionLayout group (that includes a questionId), and returns the group and its index in the form of an array ([g, i])
const getQLGbyQID = (state, qid) => getQLG(state, g => g.questionIds?.includes(qid))
// Removes all questionLayout groups that pass a provided test function (if removal occurs UPDATES THE STATE), and returns the state
const filterQLG = (state, test, update = false) => {
  const resQL = state.questionLayout.filter(g => !test(g)) || []
  return (state.questionLayout.length !== resQL.length && Object.assign(state, { questionLayout: resQL })) || update
    ? Object.assign({}, state)
    : state
}
// Searches for a questionLayout group (by matching its id to the given id) to remove it (if removal occurs UPDATES THE STATE), and returns the state
const filterQLGbyID = (state, id) => filterQLG(state, g => g.id === id)
// Searches for a questionLayout group (that is EMPTY and LOOSE) to remove it (if removal occurs UPDATES THE STATE), and returns the state
const removeEmptyLooseQLG = state => filterQLG(state, g => g.type === 'loose' && !g.questionIds?.length)
// Parses through the questionLayout and merges all CONSECUTIVE LOOSE questionLayout groups (if a merge occurs UPDATES THE STATE), and returns the state
const mergeQLG = (state, update = false) => {
  const resQL = state.questionLayout.reduce((acc, cur) => {
    const lastQLG = acc[acc.length - 1]
    return cur.type !== 'loose' || !(lastQLG?.type === 'loose')
      ? acc.concat(cur)
      : (update = true) &&
          acc.concat(Object.assign({}, acc.pop(), { questionIds: lastQLG.questionIds?.concat(cur.questionIds) || cur.questionIds || [] }))
  }, [])
  return update ? fullAssign({}, state, { questionLayout: resQL }) : state
}
// Cleans questionLayout by removing EMPTY and LOOSE, and merging CONSECUTIVE LOOSE questionLayout groups
const cleanQL = state => mergeQLG(removeEmptyLooseQLG(state))
// Generates and returns a question
const generateQ = q => Object.assign({ id: uuid() }, q)
// Appends a question to the end of the questions array, and UPDATES THE STATE
const appendToQS = (state, q) => fullAssign({}, state, { questions: state.questions.concat(q) })
// Searches for a question (by passing a provided test function), and returns the question and its index in the form of an array ([q, i])
const getQ = (state, test, i = -1) => [state.questions.find((g, j) => test(g) && ((i = j) || true)) || null, i]
// Searches for a question (by matching its id to the given id), and returns the question and its index in the form of an array ([q, i])
const getQbyID = (state, id) => getQ(state, q => q.id === id)
// Searches for a question (that contains an advanced conditional rule with the given id), and returns the question and its index in the form of an array ([q, i])
const getQbyRID = (state, id) => getQ(state, q => q?.advanced?.rules?.find(r => r.id === id))
// Evaluates a resulting type based on an array of types returning the common type if all entries are identical, otherwise returning "ambiguous"
const evaluateType = t => (t = new Set(t)) && ((t.size === 1 && t.values().next().value) || 'ambiguous')
// Removes all questions that pass a provided test function (if removal occurs UPDATES THE STATE), and returns the state
const filterQ = (state, test, update = false) => {
  const resQ = state.questions.filter(q => !(test(q) && dissolveAllQIDsDependencies(state, q.id)))
  return (state.questions.length !== resQ.length && Object.assign(state, { questions: resQ })) || update ? Object.assign({}, state) : state
}
// Searches for a question (by matching its id to the given id) to remove it (if removal occurs UPDATES THE STATE), and returns the state
const filterQbyID = (state, id) => filterQ(state, g => g.id === id)
// Searches for an option within a question (by passing a provided test function), and returns the option and its index in the form of an array ([o, i])
const getQO = (q, test, i = -1) => [q?.options?.find((o, j) => test(o) && ((i = j) || true)) || null, i]
// Searches for an option within a question (by matching its id to the given id), and returns the option and its index in the form of an array ([o, i])
const getQObyID = (q, id) => getQO(q, o => o.id === id)
// Searches for the questionLayout group that includes the given questionId (if found, tries to remove the given questionId from its questionIds array and,
// if successful, UPDATES THE STATE), and returns the state
const filterQfromQLG = (state, qid, update = false) => {
  const [g, i] = getQLGbyQID(state, qid)
  const resQIDS = g?.questionIds?.filter(id => id !== qid)
  return (resQIDS &&
    g.questionIds?.length !== resQIDS?.length &&
    state.questionLayout.splice(i, 1, Object.assign({}, g, { questionIds: resQIDS }))) ||
    update
    ? Object.assign({}, state)
    : state
}
// Searches for a questionLayout group (by matching its id to the given id), and, if found, adds the given questionId to its questionIds array and UPDATES
// THE STATE, and returns the state
const addQtoQLG = (state, q, qlg) => {
  const [g, i] = getQLGbyID(state, qlg)
  return state.questionLayout.splice(i, 1, Object.assign({}, g, { questionIds: g.questionIds?.concat(q) || [q] })) && Object.assign({}, state)
}

const getR = (adv, test, i = -1) => [adv?.rules?.find((r, j) => test(r) && ((i = j) || true)) || null, i]

const getRbyID = (adv, id) => getR(adv, r => r.id === id)

// const getA = (state, test, i = -1) => [state.answers?.find((a, j) => test(a) && ((i = j) || true)) || null, i]

// const getAbyID = (state, id) => getA(state, a => a.id === id)

// Removes all questionLayout groups that pass a provided test function (if removal occurs UPDATES THE STATE), and returns the state
const filterR = (state, qid, test, update = false) => {
  const [q] = getQbyID(state, qid)
  const resRS = q?.advanced?.rules?.filter(r => !test(r)) || []
  return (resRS && q.advanced.rules.length !== resRS.length && Object.assign(q, { advanced: Object.assign({}, q.advanced, { rules: resRS }) })) ||
    update
    ? Object.assign({}, state)
    : state
}

const filterRbyID = (state, qid, id) => filterR(state, qid, r => r.id === id)

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////// DEPENDENCIES ////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

// Evaluates questions dependencies based on their advanced conditional rules
const evaluateQSD = state =>
  state.questions.reduce(
    (questionParseState, q) =>
      q.advanced?.rules?.reduce((ruleParseState, r) => createDependency(ruleParseState, r.questionId, q.id), questionParseState) ||
      questionParseState,
    state
  )

const getDifferencesAndIntersectionArrays = (a, b, adifb = [], bdifa = [], intersection = []) =>
  a.forEach(id => (b.has(id) ? intersection.push(id) : adifb.push(id))) ||
  b.forEach(id => !a.has(id) && bdifa.push(id)) || [adifb, bdifa, intersection]

const updateQD = (state, qid) => {
  const [q, i] = getQbyID(state, qid)
  if (i === -1) return state
  const { advanced: { rules = [] } = {} } = q || {}
  const dependentOn = new Set(rules.map(({ questionId }) => questionId))
  const [add, remove] = getDifferencesAndIntersectionArrays(dependentOn, new Set(state.dependencies[qid]?.dependentOn))
  return !(add.length || remove.length)
    ? state
    : add.forEach(id => createDependency(state, id, qid)) || remove.forEach(id => dissolveDependency(state, id, qid)) || Object.assign({}, state)
}

const evaluateD = state => evaluateQSD(state)

export { evaluateD as evaluateDependencies }

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// ///////////////// QUESTION LAYOUT ///////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

// Appends a SEPARATOR questionLayout group to the end of the questionLayout array, and updates the state
const addNewQLGseparator = state => appendToQL(state, generateQLGseparator())
// Appends a GENERIC questionLayout group to the end of the questionLayout array, and updates the state
const addNewQLG = state => appendToQL(state, generateQLG())
// Updates a questionLayout group with the information given in the payload
const updateQLG = (state, payload = {}) => {
  const [g, i] = getQLGbyID(state, payload?.id)
  return i === -1 ? state : state.questionLayout.splice(i, 1, Object.assign({}, deepAssign(g, payload))) && Object.assign({}, state)
}
// Unpacks a questionLayout group, removing it and leaving its questions loose
const unpackQLG = (state, payload = {}) => {
  const [g, i] = getQLGbyID(state, payload.id)
  return i === -1 ? state : cleanQL(filterQLGbyID(insertIntoQL(state, i, generateLooseQLG({ questionIds: g.questionIds })), g.id))
}
// Finds a questionLayout group by its id, and removes is
const removeQLG = (state, payload = {}) => cleanQL(filterQLGbyID(state, payload.id))

export {
  addNewQLGseparator as addNewQuestionLayoutGroupSeparator,
  addNewQLG as addNewQuestionLayoutGroup,
  updateQLG as updateQuestionLayoutGroup,
  unpackQLG as unpackQuestionLayoutGroup,
  removeQLG as removeQuestionLayoutGroup,
}

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// //////////////////// QUESTIONS //////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

// Creates a new GENERIC question and adds it to the appropriate questionLayout group (if not given: adds it to a LOOSE questionLayout group at the end)
const addNewQ = (state, payload = {}) => {
  const qid = payload.id || uuid()
  const gid = payload.questionLayoutGroup || uuid()
  const resState = appendToQL(appendToQS(state, generateQ({ id: qid })), generateLooseQLG({ id: gid }))
  return cleanQL(updateQLG(resState, { id: gid, questionIds: getQLGbyID(resState, gid)[0].questionIds?.concat(qid) || [qid] }))
}
// Updates a question with the information given in the payload
const updateQ = (state, payload = {}, loop = true) => {
  const { id: qid, valueType: vt } = payload
  const [q, i] = getQbyID(state, qid)
  if (i === -1) return state
  return i === -1
    ? state
    : state.questions.splice(i, 1, Object.assign({}, deepAssign(q, payload))) &&
        !(loop && vt && q.options?.filter(o => o.type !== vt).forEach(o => updateQO(state, { id: o.id, questionId: qid, type: vt }, false))) &&
        Object.assign({}, state)
}
// Finds a question by its id, removes is, and removes that id from its parent questionLayout group
const removeQ = (state, payload = {}) => filterQfromQLG(filterQbyID(state, payload.id), payload.id)
// Filters the given questionId from its parent questionLayout group's questionIds array, and adds it to the given questionLayout group's questionIds array,
// if no questionLayout group is given, creates a new group and appends it to the end of questionLayout instead
const assignQ = (state, payload = {}) => {
  const gid = payload.questionLayoutGroup || uuid()
  return cleanQL(addQtoQLG(filterQfromQLG(!payload.questionLayoutGroup ? appendToQL(state, { id: gid }) : state, payload.id), payload.id, gid))
}
// Removes the given question from its parent questionLayout group, puts it in a new LOOSE questionLayout group after it, and cleans the questionLayout,
// merging any CONSECUTIVE LOOSE groups
const unassignQ = (state, payload = {}) => {
  const i = getQLGbyQID(state, payload.id)[1]
  return i === -1 ? state : cleanQL(filterQfromQLG(insertIntoQL(state, i + 1, generateLooseQLG({ questionIds: [payload.id] })), payload.id))
}

export { addNewQ as addNewQuestion, updateQ as updateQuestion, removeQ as removeQuestion, assignQ as assignQuestion, unassignQ as unassignQuestion }

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// ///////////////////// OPTIONS ///////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

// Updates a question option with the information given in the payload
const updateQO = (state, payload = {}, loop = true) => {
  const { valueType: vt } = payload
  const [q] = getQbyID(state, payload.questionId)
  const [o, i] = getQObyID(q, payload.id)
  return i === -1
    ? state
    : q.options.splice(i, 1, Object.assign({}, deepAssign(o, filterObjectFields(payload, ['questionId', 'id'])))) &&
        !(loop && vt && q.valueType !== vt && !updateQ(state, { id: q.id, valueType: evaluateType(q.options?.map(({ type }) => type)) }, false)) &&
        Object.assign({}, state)
}

export { updateQO as updateQuestionOption }

// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// //////////////// ADVANCED QUESTION //////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

const updateADV = (state, payload = {}) => {
  const [q, i] = getQbyID(state, payload.questionId)
  return i === -1
    ? state
    : Object.assign(q, { advanced: Object.assign({}, q.advanced, filterObjectFields(payload, 'questionId')) }) && Object.assign({}, state)
}

const addNewR = (state, payload = {}) => {
  const [q, i] = getQbyID(state, payload.questionId)
  return i === -1 ? state : updateADV(state, { questionId: payload.questionId, rules: (q?.advanced?.rules || []).concat({ id: uuid() }) })
}

const updateR = (state, payload = {}) => {
  const [q, i] = getQbyRID(state, payload.id)
  const [r, j] = getRbyID(q?.advanced, payload.id)
  return i === -1 || j === -1
    ? state
    : q.advanced.rules.splice(j, 1, Object.assign({}, deepAssign(r, payload))) &&
        updateQD(updateADV(state, { questionId: q.id, rules: q.advanced.rules }), q.id)
}

const removeR = (state, payload = {}) => updateQD(filterRbyID(state, payload.questionId, payload.id), payload.questionId)

export {
  updateADV as updateAdvancedQuestionConfiguration,
  addNewR as addNewConditionalRule,
  updateR as updateConditionalRule,
  removeR as removeConditionalRule,
}
