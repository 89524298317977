import ACTION_TYPES from '../../actionTypes'

const actionCreators = {
  resetWizard: () => ({ type: ACTION_TYPES.WIZARD_RESET }),
  initializeWizard: payload => ({ type: ACTION_TYPES.WIZARD_INITIALIZED, payload }),
  markLocation: payload => ({ type: ACTION_TYPES.LOCATION_MARKED, payload }),
  stripMarker: payload => ({ type: ACTION_TYPES.LOCATION_UNMARKED, payload }),
  assignMarker: payload => ({ type: ACTION_TYPES.MARKER_ASSIGNED, payload }),
  unassignMarker: payload => ({ type: ACTION_TYPES.MARKER_UNASSIGNED, payload }),

  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////// QUESTION LAYOUT //////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewSeparator: () => ({ type: ACTION_TYPES.QUESTION_LAYOUT_SEPARATOR_ADDED }),
  addNewQuestionLayoutGroup: () => ({ type: ACTION_TYPES.QUESTION_LAYOUT_GROUP_ADDED }),
  updateQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.QUESTION_LAYOUT_GROUP_UPDATED, payload }),
  unpackQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.QUESTION_LAYOUT_GROUP_UNPACKED, payload }),
  removeQuestionLayoutGroup: payload => ({ type: ACTION_TYPES.QUESTION_LAYOUT_GROUP_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////// QUESTIONS /////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  addNewQuestion: payload => ({ type: ACTION_TYPES.NEW_QUESTION_ADDED, payload }),
  updateQuestion: payload => ({ type: ACTION_TYPES.QUESTION_UPDATED, payload }),
  removeQuestion: payload => ({ type: ACTION_TYPES.QUESTION_REMOVED, payload }),
  assignQuestion: payload => ({ type: ACTION_TYPES.QUESTION_ASSIGNED, payload }),
  unassignQuestion: payload => ({ type: ACTION_TYPES.QUESTION_UNASSIGNED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// OPTIONS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  updateQuestionOption: payload => ({ type: ACTION_TYPES.QUESTION_OPTION_UPDATED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////// ADVANCED QUESTION /////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  updateAdvancedQuestionConfiguration: payload => ({ type: ACTION_TYPES.ADVANCED_QUESTION_CONFIGURATION_UPDATED, payload }),
  addNewConditionalRule: payload => ({ type: ACTION_TYPES.QUESTION_CONDITIONAL_RULE_ADDED, payload }),
  updateConditionalRule: payload => ({ type: ACTION_TYPES.QUESTION_CONDITIONAL_RULE_UPDATED, payload }),
  removeConditionalRule: payload => ({ type: ACTION_TYPES.QUESTION_CONDITIONAL_RULE_REMOVED, payload }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// ANSWERS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  goToNextQuestion: () => ({ type: ACTION_TYPES.NEXT_QUESTION_SET }),
  goToPreviousQuestion: () => ({ type: ACTION_TYPES.PREVIOUS_QUESTION_SET }),
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //

  answerQuestion: payload => ({ type: ACTION_TYPES.QUESTION_ANSWERED, payload }),
  updateChoiceMarker: payload => ({ type: ACTION_TYPES.CHOICE_MARKER_UPDATED, payload }),
  updateReplacementMarker: payload => ({ type: ACTION_TYPES.REPLACEMENT_MARKER_UPDATED, payload }),
  setConfiguring: payload => ({ type: ACTION_TYPES.CONFIGURING_SET, payload }),
  setAnswering: payload => ({ type: ACTION_TYPES.ANSWERING_SET, payload }),
  concludeConfiguring: () => ({ type: ACTION_TYPES.CONFIGURING_DONE }),
  addQuestionOption: payload => ({ type: ACTION_TYPES.QUESTION_OPTION_ADDED, payload }),
  removeQuestionOption: payload => ({ type: ACTION_TYPES.QUESTION_OPTION_REMOVED, payload }),
  reorderQuestionOption: payload => ({ type: ACTION_TYPES.QUESTION_OPTION_REORDERED, payload }),
  // insertSegmentAtIndex: payload => ({ type: ACTION_TYPES.SEGMENT_INSERTED_AT_INDEX, payload }),
  // removeSegmentAtIndex: payload => ({ type: ACTION_TYPES.SEGMENT_REMOVED_AT_INDEX, payload }),
  // insertSegmentAbove: payload => ({ type: ACTION_TYPES.SEGMENT_INSERTED_ABOVE, payload }),
  // replaceParagraphContent: payload => ({ type: ACTION_TYPES.PARAGRAPH_CONTENT_REPLACED, payload }),
  // applyCustomStyle: payload => ({ type: ACTION_TYPES.CUSTOM_STYLE_APPLIED, payload }),
  // removeSegment: payload => ({ type: ACTION_TYPES.SEGMENT_REMOVED, payload }),
  // insertPageSegment: payload => ({ type: ACTION_TYPES.SEGMENT_INSERTED_INTO_PAGE, payload }),
  // updateSegmentLabel: payload => ({ type: ACTION_TYPES.SEGMENT_LABEL_UPDATED, payload }),
}

export { actionCreators }
export default actionCreators
