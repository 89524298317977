import React, { useMemo } from 'react'
import useStore from '___store'
import { CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'
import Insert from 'assets/svgIconComponents/Insert'
import Cogwheel from 'assets/svgIconComponents/Cogwheel'
import Deselect from 'assets/svgIconComponents/Deselect'
import Trash from 'assets/svgIconComponents/Trash'

import Segment from '../Segment'
import { Input } from 'components'

const classes = {
  content: 'Wizard-Editor-Page-ChoiceMarker-Content',
  header: 'Wizard-Editor-Page-ChoiceMarker-Header',
  headerControls: 'Wizard-Editor-Page-ChoiceMarker-Header-controls',
}

const ChoiceMarkerHeader = React.memo(props => {
  const { id, label } = props
  const { currentQuestion, setConfiguring, updateChoiceMarker, assignMarker, stripMarker } = useStore(
    `selectCurrentQuestion[choice]`,
    `setConfiguring`,
    'updateChoiceMarker',
    'assignMarker',
    'stripMarker'
  )

  const headerClassName = [classes.header, CASUS_CLASSES.choiceMarkerHeader].join(' ')
  return (
    <div className={headerClassName}>
      <Input
        placeholder={'Marker label...'}
        defaultValue={label || id}
        needsConfirmation
        onChange={value => updateChoiceMarker({ id, label: value })}
        blurShowUnmodified
      />
      {/* {editingLabel ? (
        <input
          autoFocus
          value={label || id}
          onBlur={() => setEditingLabel(false)}
          onKeyDown={event => event.key === 'Enter' && setEditingLabel(false)}
          onChange={event => updateChoiceMarker({ id, label: event.target.value })}
        />
      ) : (
        <span tabIndex={0} onFocus={() => setEditingLabel(true)} onClick={() => setEditingLabel(true)}>
          {label || id}
        </span>
      )} */}
      {/* <input
        autoFocus
        value={label || id}
        onBlur={() => setEditingLabel(false)}
        onKeyDown={event => event.key === 'Enter' && setEditingLabel(false)}
        onChange={event => updateChoiceMarker({ id, label: event.target.value })}
      />
      <span tabIndex={0} onFocus={() => setEditingLabel(true)} onClick={() => setEditingLabel(true)}>
        {label || id}
      </span> */}
      <div className={classes.headerControls}>
        {currentQuestion && !currentQuestion.markers?.includes(id) ? (
          <button
            type="button"
            onClick={event => assignMarker({ markerId: id, questionId: currentQuestion.id, type: 'choice' })}
          >
            <Insert />
          </button>
        ) : null}
        <button type="button" onClick={() => setConfiguring({ key: 'marker', id })}>
          <Cogwheel />
        </button>
        <button type="button" onClick={() => stripMarker({ id })}>
          <Deselect />
        </button>
        <button type="button" onClick={event => console.log('DELETE!')}>
          <Trash />
        </button>
      </div>
    </div>
  )
})

export const ChoiceMarker = React.memo(props => {
  const { id, parent, range, length, start, end } = props
  const {
    marker,
    contentIds: ids,
    configuring,
    mode,
  } = useStore(
    `selectMarker[${id}]`,
    `selectContentIds[marker,${id},${parent},${range}]`,
    `selectConfiguring[${id}]`,
    'selectMode'
  )

  const markerMode = mode === 'template-creation' ? 'edit' : 'preview'

  const { label, defaultKeep, keep, color } = marker

  const children = useMemo(
    () =>
      Array.from(new Set(ids.split('; ')))
        .map(l => {
          const regex = /(.+)(\[(\d*),(\d*),(\d*)\])(se|s|e|)/g
          const match = [...l.matchAll(regex)][0]
          if (!match) return { type: 'segment', id: l }
          const id = match[1]
          const start = Number(match[3])
          const end = Number(match[4])
          const length = Number(match[5])
          const affix = [...match[6]]
          const rangeString = [start, end].join('-')
          return {
            type: 'marker',
            id,
            range: rangeString,
            length,
            start: affix.includes('s'),
            end: affix.includes('e'),
          }
        })
        .map(({ id, type, range, length, start, end }) =>
          type === 'segment' ? (
            <Segment key={id} id={id} />
          ) : (
            <ChoiceMarker key={id} id={id} parent={parent} range={range} length={length} start={start} end={end} />
          )
        ),
    [ids, parent]
  )

  // console.groupCollapsed(`CHOICE MARKER %c${id}`, 'color: lightgreen;', 'RENDER!')
  // console.log(Array.from(new Set(ids.split('; '))))
  // console.groupEnd()

  const contentClassName = [classes.content, CASUS_CLASSES.choiceMarkerContent].join(' ')

  return (
    <div
      id={`${range.replace('-', '/')}/${length}-${id}`}
      className={CASUS_CLASSES.choiceMarkerDiv}
      data-mode={markerMode}
      data-configuring={configuring}
      data-start={start}
      data-end={end}
      data-remove={markerMode === 'preview' && !(keep === undefined ? defaultKeep : keep)}
      style={{ '--color': color }}
    >
      <div className={contentClassName} data-start={start} data-end={end}>
        {markerMode === 'edit' && start ? <ChoiceMarkerHeader id={id} label={label} /> : null}
        {children}
      </div>
    </div>
  )
})

ChoiceMarker.displayName = 'Wizard-Editor-ChoiceMarker'

export default ChoiceMarker
