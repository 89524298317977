import React, {
  // forwardRef,
  // useImperativeHandle,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import useStore from '___store'
import { Caret } from 'assets/svgIconComponents'

import QuestionOption from './QuestionOption'
import { CASUS_KEYSTRINGS } from 'TemplateCreation-DocumentGeneration/constants'
import MarkerCycler from 'TemplateCreation-DocumentGeneration/components/MarkerCycler'

const classes = {
  wrapper: 'Wizard-Configuration-Questionnaire-QuestionListItem-wrapper',
  questionText: 'Wizard-Configuration-Questionnaire-QuestionListItem-questionText',
  questionDescription: 'Wizard-Configuration-Questionnaire-QuestionListItem-questionDescription',
  optionsText: 'Wizard-Configuration-Questionnaire-QuestionListItem-optionsText',
  optionsDiv: 'Wizard-Configuration-Questionnaire-QuestionListItem-optionsDiv',
  questionActionsDiv: 'Wizard-Configuration-Questionnaire-QuestionListItem-questionActionsDiv',
}

const parseUserInputValue = val =>
  // eslint-disable-next-line no-useless-escape
  [...val.matchAll(new RegExp(`(^${CASUS_KEYSTRINGS.userInput}:)([a-zA-Z0-9\-]+):(.*)`, 'g'))][0]

const generateValue = (select, previous, active, value) => {
  const parsed = parseUserInputValue(value)
  if (select === 'single') return (parsed ? parsed[3] && parsed[0] : value) || previous
  const next = previous.filter(prevValue => {
    if (!parsed) return prevValue !== value
    const parsedPrev = parseUserInputValue(prevValue)
    return !(parsedPrev && parsedPrev[2] === parsed[2])
  })
  if (parsed && !parsed[3]) return next
  return active ? next.concat(parsed ? parsed[0] : value) : next
}

const getSelected = (select, currentValue, optionId) => {
  if (select === 'single') {
    const parsed = parseUserInputValue(currentValue)
    const selected = parsed ? parsed[2] === optionId : currentValue === optionId
    const selectedValue = selected && parsed ? parsed[3] : ''
    return [selected, selectedValue]
  }
  const foundValue = currentValue.find(cur => {
    const parsed = parseUserInputValue(cur)
    return parsed ? parsed[2] === optionId : cur === optionId
  })
  const selected = Boolean(foundValue)
  const parsed = selected && parseUserInputValue(foundValue)
  const selectedValue = parsed ? parsed[3] : ''
  return [selected, selectedValue]
}

export const QuestionListItem = React.memo(
  // forwardRef((props, ref) => {
  props => {
    const [stateAnswer, setStateAnswer] = useState()
    const { id, isStandalone, scrollToMarker } = props

    // console.log('QUESTION ID: ', id)

    const {
      questions: question = {},
      answers: { value: storeAnswer } = {},
      answering: beingAnswered,
      questionConditionalOutcome: show,
      answerQuestion,
      goToPreviousQuestion,
      goToNextQuestion,
    } = useStore(
      `selectQuestions[${id}]`,
      `selectAnswers[${id}]`,
      `selectAnswering[${id}]`,
      `selectQuestionConditionalOutcome[${id}]`,
      'answerQuestion',
      'goToPreviousQuestion',
      'goToNextQuestion'
    )

    const disabled = !beingAnswered

    // const isFirstQuestion = previousId === 'start'
    // const isLastQuestion = nextId === 'finish'

    const {
      type,
      text = 'No question text...',
      description,
      // practicalHint,
      options = [],
      select = 'single',
      markers = [],
    } = question

    const optionText = select === 'multi' ? 'Select one or more:' : 'Choose one:'

    const calculatedValue = stateAnswer || storeAnswer || (select === 'single' ? '' : [])
    const valueArray = typeof calculatedValue === 'string' ? [calculatedValue] : calculatedValue

    // console.log('CALCULATED VALUE: ', id, calculatedValue)

    const automaticConfirm = isStandalone && select === 'single'
    const hasUserInput = type === 'replacement' && valueArray.some(value => parseUserInputValue(value))
    const needsConfirmation = isStandalone && (select === 'multi' || hasUserInput)

    const resolveOptionHandler = useCallback(
      (id, active, value) => {
        const resultingValue = generateValue(select, calculatedValue, active, value)
        setStateAnswer(resultingValue)
        const match = parseUserInputValue(value)
        if (match && !match[3]) return
        if (resultingValue !== calculatedValue) answerQuestion({ id, value: resultingValue })
        if (!automaticConfirm) return
        goToNextQuestion()
      },
      [select, calculatedValue, automaticConfirm, answerQuestion, goToNextQuestion]
    )

    const optionIds = useMemo(() => options.map(({ id: optionId }) => optionId).join('; '), [options])
    const children = useMemo(
      () =>
        optionIds &&
        optionIds.split('; ').map(optionId => {
          const [selected, storedValue] = getSelected(select, calculatedValue, optionId)
          return (
            <QuestionOption
              key={optionId}
              questionId={id}
              id={optionId}
              select={select}
              selected={selected}
              storedValue={storedValue}
              handler={(active, value) => resolveOptionHandler(id, active, value)}
              disabled={disabled}
            />
          )
        }),
      [optionIds, id, select, calculatedValue, resolveOptionHandler, disabled]
    )

    // useImperativeHandle(ref, () => ({ value: calculatedValue }), [calculatedValue])

    useEffect(() => {
      if (!show) {
        answerQuestion({ id, value: select === 'single' ? '' : [] })
        setStateAnswer(select === 'single' ? '' : [])
      }
    }, [show, answerQuestion, id, select])

    return show ? (
      <div id={id} className={classes.wrapper} data-being-answered={beingAnswered}>
        <span className={classes.questionText}>{text}</span>
        {description && <span className={classes.questionDescription}>{description}</span>}
        {options.length ? (
          <div className={classes.optionsDiv}>
            <span className={classes.optionsText}>{optionText}</span>
            {children}
          </div>
        ) : (
          <span className={classes.optionsText}>No options...</span>
        )}
        {isStandalone || markers.length || needsConfirmation ? (
          <div className={classes.questionActionsDiv}>
            {isStandalone ? (
              <button
                disabled={
                  disabled
                  //  || isFirstQuestion
                }
                type="button"
                onClick={() => {
                  if (disabled) return
                  goToPreviousQuestion()
                }}
              >
                <Caret />
              </button>
            ) : null}
            {markers.length ? <MarkerCycler disabled={disabled} id={id} initialScrollTo={scrollToMarker} /> : null}
            {needsConfirmation ? (
              <button
                disabled={disabled}
                type="button"
                onClick={() => {
                  if (disabled) return
                  answerQuestion({ id, value: calculatedValue })
                  goToNextQuestion()
                }}
              >
                Next
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    ) : null
  }
)
// )

QuestionListItem.displayName = 'Wizard-Questionnaire-QuestionListItem'

export default QuestionListItem
