import { FeaturesLayout } from 'layout'
import React from 'react'
import * as content from '../content'

interface Props {
  step: number
  heading?: string
  isLoading?: boolean
  onClose: () => void
}

export const DocumentGenerationLayout: React.FC<Props> = ({
  step,
  heading = content.headingCreate,
  isLoading,
  onClose,
  children,
}) => {
  return (
    <FeaturesLayout
      currentStep={step}
      onBoardingSteps={content.onBoardingSteps}
      steps={content.steps}
      handleClose={onClose}
      onBoardingHeading={content.onBoardingHeading}
      heading={(heading)}
      isLoading={isLoading}
    >
      {children}
    </FeaturesLayout>
  )
}
