import React, { useEffect, useState } from 'react'
import useStore from '___store'
import { Caret } from 'assets/svgIconComponents'
import { CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'

const classes = {
  wrapper: 'Wizard-MarkerCycler-wrapper',
  previousButton: 'Wizard-MarkerCycler-previousButton',
  nextButton: 'Wizard-MarkerCycler-nextButton',
  markerIdentifier: 'Wizard-MarkerCycler-markerIdentifier',
}

const scrollToMarker = (type, markerId) => {
  const markerClass = type === 'choice' ? CASUS_CLASSES.choiceMarkerDiv : CASUS_CLASSES.replacementMarker
  const markers = Array.from(document.getElementsByClassName(markerClass))
  if (!markers.length) return
  const marker = markers.find(({ id }) => id.includes(markerId))
  if (!marker) return
  // marker.scrollIntoView({ behavior: 'smooth', block: 'start' })
  marker.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const MarkerIdentifier = React.memo(props => {
  const { id, index, range } = props
  const { marker = {} } = useStore(`selectMarker[${id}]`)
  const identifier = marker.label || marker.contentText || 'unidentified'
  return <span className={classes.markerIdentifier}>{`${index + 1}/${range}: ${identifier}`}</span>
})

export const MarkerCycler = React.memo(props => {
  const { id, disabled, initialScrollTo } = props
  const { questions: question = {} } = useStore(`selectQuestions[${id}]`)

  const { type, markers = [] } = question

  const first = markers[0]

  const [current, setCurrent] = useState(first)
  const index = markers.indexOf(current)
  const range = markers.length

  useEffect(() => {
    if (disabled || !initialScrollTo || !first) return
    scrollToMarker(type, first)
    setCurrent(first)
  }, [disabled, initialScrollTo, first, type])

  return (
    <div className={classes.wrapper} data-disabled={disabled}>
      <button
        className={classes.previousButton}
        disabled={disabled}
        type="button"
        onClick={() =>
          !disabled &&
          setCurrent(prev => {
            const markerId = markers[(markers.indexOf(prev) + markers.length - 1) % markers.length]
            scrollToMarker(type, markerId)
            return markerId
          })
        }
      >
        <Caret />
      </button>
      <button
        className={classes.nextButton}
        disabled={disabled}
        type="button"
        onClick={() =>
          !disabled &&
          setCurrent(prev => {
            const markerId = markers[(markers.indexOf(prev) + markers.length + 1) % markers.length]
            scrollToMarker(type, markerId)
            return markerId
          })
        }
      >
        <Caret />
      </button>
      {current ? <MarkerIdentifier key={current} id={current} index={index} range={range} /> : null}
    </div>
  )
})

MarkerCycler.displayName = 'Wizard-Questionnaire-MarkerCycler'

export default MarkerCycler
