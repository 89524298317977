import { useMutation, useQueryClient } from 'react-query'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { queryKeys } from 'queries/queryKeys'
import { useToast } from 'hooks/useToast'

import DocsApi from 'api/docs.api'
import { IDocument } from 'types'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: IDocument[][]
}

export const useSignDocument = () => {
  const {
    currentFolder: { type, id },
  } = useCurrentFolder('docs')
  const toast = useToast()

  const keys = [queryKeys.DOCUMENTS, { type, folderId: id }]
  const queryClient = useQueryClient()
  const mutation = useMutation(queryKeys.DOCUMENT, (payload: any) => DocsApi.sign({...payload}), {
    onSuccess: (updatedDoc, variables) => {
      queryClient.invalidateQueries(keys).then(() =>
      toast('success', 'Sie haben das Dokument erfolgreich bearbeitet')
      )
      queryClient.setQueryData([queryKeys.DOCUMENT, updatedDoc.id], updatedDoc)
    },
    onError: () => {
      toast('error', 'default')
    },
  })

  return mutation
}
