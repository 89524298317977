import React from 'react'

import { className } from './index'

const Trash = React.memo(() => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <line x1="15" y1="30" x2="85" y2="30" stroke="currentColor" strokeWidth="8" strokeLinecap="round" />
    <path
      d="M 32 30 v -5 c 0 -10 0 -10 10 -10 h 16 c 10 0 10 0 10 10 v 5"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="butt"
      fill="none"
    />
    <path
      d="M 25 30 v 35 c 0 20 0 20 20 20 h 10 c 20 0 20 0 20 -20 v -35"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="butt"
      fill="none"
    />
    <line x1="41" y1="45" x2="41" y2="70" stroke="currentColor" strokeWidth="6" strokeLinecap="round" />
    <line x1="59" y1="45" x2="59" y2="70" stroke="currentColor" strokeWidth="6" strokeLinecap="round" />
  </svg>
))

export { Trash }
export default Trash
