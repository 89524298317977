import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { CheckBox as CheckBoxIcon } from 'assets/svgIconComponents/CheckBox'

const classes = {
  wrapper: 'CheckBox-wrapper',
  label: 'CheckBox-label',
}

export const CheckBox = React.memo(
  forwardRef((props, ref) => {
    const buttonRef = useRef()
    const { className: cn, disabled, defaultValue, value, onChange, label } = props
    const [stateValue, setStateValue] = useState(value || defaultValue || false)

    const className = [classes.wrapper, cn].join(' ')

    let calculatedValue = false
    if (stateValue !== undefined) calculatedValue = stateValue
    if (value !== undefined) calculatedValue = value

    useImperativeHandle(ref, () => ({ value: calculatedValue }), [calculatedValue])

    useEffect(() => {
      if (disabled) return buttonRef?.current?.blur()
    }, [disabled, buttonRef])

    return (
      <button
        ref={buttonRef}
        data-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        data-labeled={!!label}
        type="button"
        onClick={() => {
          if (disabled) return
          setStateValue(!calculatedValue)
          if (!onChange || typeof onChange !== 'function') return
          onChange(!calculatedValue)
        }}
        className={className}
      >
        <CheckBoxIcon active={calculatedValue} />
        {label ? <span className={classes.label}>{label}</span> : null}
      </button>
    )
  })
)

CheckBox.displayName = 'CheckBox'

export default CheckBox
