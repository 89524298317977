import React, {
  // useCallback,
  useEffect,
  useMemo,
  //  useState,
  useContext,
  useRef,
} from 'react'

import useStore from '___store'
import { SEGMENT_TYPES, SEGMENT_TAGS, CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'
import { CellContext, CellProvider } from './CellContext'
import { PageContext, PageProvider } from './PageContext'
import { ChoiceMarker, ReplacementMarker } from './Markers'

const ConfigureDiv = React.memo(({ id, label, style }) => {
  const cellId = useContext(CellContext)
  const indices = useContext(PageContext)
  const {
    styles,
    insertSegmentAtIndex,
    removeSegmentAtIndex,
    // insertSegmentAbove, removeSegment, updateSegmentLabel, applyCustomStyle
  } = useStore(
    'selectStyles',
    'insertSegmentAtIndex',
    'removeSegmentAtIndex'
    // 'insertSegmentAbove',
    // 'removeSegment',
    // 'updateSegmentLabel',
    // 'applyCustomStyle'
  )
  const { customStyles } = styles
  return (
    <div className={CASUS_CLASSES.configureDiv}>
      <input
        className={CASUS_CLASSES.labelInput}
        value={label}
        placeholder="Segment label..."
        onChange={() => {}}
        // onChange={event => updateSegmentLabel({ id, label: event.target.value })}
        onClick={event => event.stopPropagation()}
      />
      <select
        value={style}
        onChange={() => {}}
        // onChange={event => applyCustomStyle({ id, customStyle: event.target.value })}
        onClick={event => {
          event.stopPropagation()
        }}
      >
        {Object.keys(customStyles).map(style => {
          const value = style.charAt(0) === '.' ? style.slice(1) : style
          return (
            <option key={value} value={value}>
              {value}
            </option>
          )
        })}
      </select>
      <button
        className={CASUS_CLASSES.configureButton}
        type="button"
        tabIndex={-1}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          insertSegmentAtIndex({
            segmentType: SEGMENT_TYPES.paragraph,
            index: indices[id],
            inside: false,
            parentId: cellId,
          })
        }}
      >
        INSERT
      </button>
      <button
        className={CASUS_CLASSES.configureButton}
        type="button"
        tabIndex={-1}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          removeSegmentAtIndex({ index: indices[id], parentId: cellId })
        }}
      >
        REMOVE
      </button>
    </div>
  )
})

// const RootSegment = React.memo(({ structure }) => {
//   const { id = '', segments = [] } = structure
//   // const { insertSectionSegment } = useStore('insertSectionSegment')
//   const children = useMemo(
//     () => segments.map(({ id: segmentId }) => <Segment key={segmentId} id={segmentId} />),
//     [segments]
//   )

//   return (
//     <div id={id} className={CASUS_CLASSES.pageContentRoot}>
//       {children}
//       <button
//         type="button"
//         onClick={event => {
//           event.stopPropagation()
//           event.preventDefault()
//           // insertSectionSegment({ id, segmentType: SEGMENT_TYPES.paragraph })
//         }}
//       >
//         ADD paragraph!
//       </button>
//     </div>
//   )
// })

const TableCell = React.memo(({ cell }) => {
  const { id: cellId, customStyle = '', styles = [], content = [] } = cell
  const { contentIds: ids } = useStore(`selectContentIds[cell,${cellId}]`)
  const ref = useRef()
  const cellClass = [CASUS_CLASSES.tableCell, customStyle, ...styles].filter(s => s).join(' ')

  const children = useMemo(
    () =>
      Array.from(new Set(ids.split('; ')))
        .map(l => {
          const regex = /(.+)(\[(\d*),(\d*),(\d*)\])(se|s|e|)/g
          const match = [...l.matchAll(regex)][0]
          if (!match) return { type: 'segment', id: l }
          const id = match[1]
          const start = Number(match[3])
          const end = Number(match[4])
          const length = Number(match[5])
          const affix = [...match[6]]
          const rangeString = [start, end].join('-')
          return {
            type: 'marker',
            id,
            range: rangeString,
            length,
            start: affix.includes('s'),
            end: affix.includes('e'),
          }
        })
        .map(({ id, type, range, length, start, end }) =>
          type === 'segment' ? (
            <Segment key={id} id={id} />
          ) : (
            <ChoiceMarker key={id} id={id} parent={cellId} range={range} length={length} start={start} end={end} />
          )
        ),
    [ids, cellId]
  )
  // console.groupCollapsed(`CELL %c${cellId}`, 'color: lightgreen;', 'RENDER!')
  // console.log(Array.from(new Set(ids.split('; '))))
  // console.groupEnd()

  useEffect(() => {
    if (ref?.current) content.forEach(({ id }, index) => Object.assign(ref.current, { [id]: index }))
  }, [ref, content])

  return (
    <td id={`cell-${cellId}`} className={cellClass}>
      <PageProvider ref={ref}>
        <CellProvider id={cellId}>{children}</CellProvider>
      </PageProvider>
    </td>
  )
})

const TableRow = React.memo(({ row }) => {
  const { id, customStyle = '', styles = [], cells = [] } = row
  const rowClass = [CASUS_CLASSES.tableRow, customStyle, ...styles].filter(s => s).join(' ')
  const children = useMemo(
    () =>
      cells.map((cell, i) => {
        const key = `${id}:cell-${i}`
        return <TableCell key={key} cell={cell} />
      }),
    [cells, id]
  )
  return (
    <tr id={id} className={rowClass}>
      {children}
    </tr>
  )
})

const TableSection = React.memo(({ id, tag, rows }) => {
  const children = useMemo(
    () =>
      rows.map((row, i) => {
        const key = `table(${id}):${tag}:row-${i}`
        return <TableRow key={key} id={key} row={row} />
      }),
    [rows, id, tag]
  )
  const render = useMemo(() => {
    switch (tag) {
      case SEGMENT_TAGS[SEGMENT_TYPES.tableHeader]:
        return <thead className={CASUS_CLASSES.tableHeader}>{children}</thead>
      case SEGMENT_TAGS[SEGMENT_TYPES.tableBody]:
        return <tbody className={CASUS_CLASSES.tableBody}>{children}</tbody>
      case SEGMENT_TAGS[SEGMENT_TYPES.tableFooter]:
        return <tfoot className={CASUS_CLASSES.tableFooter}>{children}</tfoot>
      default:
        return null
    }
  }, [tag, children])

  return render
})

const TableSegment = React.memo(({ structure }) => {
  const { id = '', customStyle = '', styles = [], header = [], body = [], footer = [] } = structure
  const { configuring } = useStore(`selectConfiguring[${id}]`)
  const divClass = [CASUS_CLASSES.segmentDiv, customStyle, ...styles].filter(s => s).join(' ')
  const tableClass = [CASUS_CLASSES.tableSegment, customStyle, ...styles].filter(s => s).join(' ')
  const thead = useMemo(
    () => (header.length ? <TableSection id={id} tag={SEGMENT_TAGS[SEGMENT_TYPES.tableHeader]} rows={header} /> : null),
    [id, header]
  )
  const tbody = useMemo(
    () => (body.length ? <TableSection id={id} tag={SEGMENT_TAGS[SEGMENT_TYPES.tableBody]} rows={body} /> : null),
    [id, body]
  )
  const tfoot = useMemo(
    () => (footer.length ? <TableSection id={id} tag={SEGMENT_TAGS[SEGMENT_TYPES.tableFooter]} rows={footer} /> : null),
    [id, footer]
  )
  return (
    <div className={divClass} data-configuring={configuring}>
      <table id={id} className={tableClass}>
        {configuring ? <ConfigureDiv id={id} /> : null}
        {thead}
        {tbody}
        {tfoot}
      </table>
    </div>
  )
})

const chunkStylesToIgnore = ['Normal']

const ParagraphSegment = React.memo(({ structure }) => {
  // const [textareaNode, setTextareaNode] = useState(null)
  // const [overlayNode, setOverlayNode] = useState(null)
  const { id = '', customStyle = '', styles = [], textChunks = [], label = '' } = structure
  const {
    configuring,
    replacementMarkers: markers = [],
    // setConfiguring,
    // replaceParagraphContent
  } = useStore(
    `selectConfiguring[${id}]`,
    `selectReplacementMarkers[${id}]`
    // 'setConfiguring'
    // 'replaceParagraphContent'
  )

  // const textareaRef = useCallback(node => {
  //   if (node) setTextareaNode(node)
  // }, [])
  // const overlayRef = useCallback(node => {
  //   if (node) setOverlayNode(node)
  // }, [])

  // const adjustHeight = useCallback(() => {
  //   if (textareaNode) {
  //     textareaNode.setAttribute('style', `height: 0 !important;`)
  //     const textareaStyle = `height: calc(${textareaNode.scrollHeight}px + ${1}em);`
  //     const overlayStyle = `height: calc(${textareaNode.scrollHeight}px + ${1}em + ${20}px);`
  //     if (overlayNode) overlayNode.setAttribute('style', overlayStyle)
  //     textareaNode.setAttribute('style', textareaStyle)
  //   }
  // }, [textareaNode, overlayNode])

  const divClass = [CASUS_CLASSES.segmentDiv, customStyle, ...styles].filter(s => s).join(' ')
  const preClass = [CASUS_CLASSES.paragraphSegment, customStyle, ...styles].filter(s => s).join(' ')
  // const textareaClass = [CASUS_CLASSES.textarea, customStyle, ...styles].filter(s => s).join(' ')
  // const overlayClass = [
  //   CASUS_CLASSES.textareaOverlay,
  //   // , customStyle, ...styles
  // ]
  //   .filter(s => s)
  //   .join(' ')
  // const styled = !textChunks.every(chunk => !(chunk?.customStyle || chunk?.styles?.length))
  // const marked = false

  // const textArea = useMemo(() => {
  //   const text = textChunks.map(t => t.text).join('')
  //   // if (!(styled || marked))
  //   return (
  //     <>
  //       <textarea
  //         ref={textareaRef}
  //         className={textareaClass}
  //         autoComplete="off"
  //         autoCorrect="off"
  //         autoCapitalize="off"
  //         placeholder="Paragraph text..."
  //         wrap="soft"
  //         value={text}
  //         onChange={() => {}}
  //         // onChange={event => replaceParagraphContent({ id, text: event.target.value })}
  //         onInput={adjustHeight}
  //         onClick={event => event.stopPropagation()}
  //       />
  //       {styled || marked ? (
  //         <div ref={overlayRef} className={overlayClass}>
  //           This paragraph has styled chunks of text, or is marked, and therefore cannot be edited.
  //           <button
  //             type="button"
  //             onClick={event => {
  //               event.stopPropagation()
  //               event.preventDefault()
  //               // replaceParagraphContent({ id, text })
  //             }}
  //           >
  //             Remove styles and markers and edit
  //           </button>
  //         </div>
  //       ) : null}
  //     </>
  //   )
  // }, [
  //   textChunks,
  //   textareaRef,
  //   textareaClass,
  //   // replaceParagraphContent,
  //   // id,
  //   adjustHeight,
  //   marked,
  //   styled,
  //   overlayRef,
  //   overlayClass,
  // ])

  const content = useMemo(() => {
    const chunks = markers.reduce(
      (result, marker) =>
        result.reduce(
          (iteratedChunks, chunk) => {
            const { id, range } = marker
            const [mStart, mEnd] = range
            const { chunks, length } = iteratedChunks
            const { tag, text = '', textChunks: markerTextChunks = [] } = chunk
            const textLength =
              tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]
                ? markerTextChunks.reduce((sum, { text: markerChunkText }) => sum + markerChunkText.length, 0)
                : text.length
            const cStart = length
            const cEnd = cStart + textLength
            if (mStart >= cEnd || mEnd <= cStart) {
              chunks.push(chunk)
              iteratedChunks.length += textLength
              return iteratedChunks
            }
            const relativeStart = mStart - cStart
            const relativeEnd = mEnd - cStart
            const pre = text.slice(0, Math.max(relativeStart, 0))
            const inside = text.slice(Math.max(relativeStart, 0), Math.min(relativeEnd, textLength))
            const post = text.slice(Math.min(relativeEnd, textLength), textLength)
            const resultingChunks = []
            const lastChunk = chunks[chunks.length - 1]
            if (lastChunk && lastChunk.tag === SEGMENT_TAGS[SEGMENT_TYPES.mark] && lastChunk.id === id)
              lastChunk.textChunks.push(Object.assign({}, chunk, { text: inside }))
            else
              resultingChunks.push({
                tag: SEGMENT_TAGS[SEGMENT_TYPES.mark],
                id,
                textChunks: [Object.assign({}, chunk, { text: inside })],
              })
            if (pre.length) resultingChunks.unshift(Object.assign({}, chunk, { text: pre }))
            if (post.length) resultingChunks.push(Object.assign({}, chunk, { text: post }))
            chunks.push(...resultingChunks)
            iteratedChunks.length += textLength
            return iteratedChunks
          },
          { chunks: [], length: 0 }
        ).chunks,
      textChunks
    )
    return chunks.map((chunk, i) => {
      const { id: segmentId, tag, text = '', customStyle = '', styles = [], textChunks } = chunk
      if (tag === SEGMENT_TAGS[SEGMENT_TYPES.mark])
        return <ReplacementMarker key={segmentId} id={segmentId} textChunks={textChunks} />
      const spanClass = [customStyle, ...styles].filter(s => s && !chunkStylesToIgnore.includes(s))
      if (spanClass.length) {
        spanClass.unshift(CASUS_CLASSES.textChunk)
        return (
          <span key={`paragraph-segment-${segmentId}-text-chunk-${i}`} className={spanClass.join(' ')}>
            {text}
          </span>
        )
      }
      return text
    })
  }, [markers, textChunks])

  // const preview = useMemo(
  //   () =>
  //     textChunks.map((chunk, i) => {
  //       const { id: segmentId, tag, text = '', customStyle = '', styles = [] } = chunk
  //       const spanClass = [customStyle, ...styles].filter(s => s && !chunkStylesToIgnore.includes(s))
  //       if (tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]) return <Segment key={segmentId} id={segmentId} />
  //       if (spanClass.length) {
  //         spanClass.unshift(CASUS_CLASSES.textChunk)
  //         return (
  //           <span key={`paragraph-segment-${segmentId}-text-chunk-${i}`} className={spanClass.join(' ')}>
  //             {text}
  //           </span>
  //         )
  //       }
  //       return text
  //     }),
  //   [textChunks]
  // )

  // useEffect(adjustHeight, [adjustHeight])

  return (
    <div
      data-configuring={configuring}
      className={divClass}
      // onMouseDown={event => {
      //   if (event.detail > 1) event.preventDefault()
      // }}
      // onDoubleClick={event => {
      //   event.stopPropagation()
      //   event.preventDefault()
      //   setConfiguring(id)
      // }}
    >
      {configuring ? <ConfigureDiv id={id} label={label} style={customStyle} /> : null}
      {/* {configuring ? (
        textArea
      ) : ( */}
      <pre id={id} className={preClass} tabIndex={-1}>
        {/* {preview} */}
        {content}
      </pre>
      {/* )} */}
    </div>
  )
})

const Segment = React.memo(({ id }) => {
  const { segment } = useStore(`selectSegment[${id}]`)

  const render = useMemo(() => {
    if (!segment) return null
    const { tag } = segment
    switch (tag) {
      // case SEGMENT_TAGS[SEGMENT_TYPES.container]:
      //   return <RootSegment structure={segment} />
      case SEGMENT_TAGS[SEGMENT_TYPES.paragraph]:
        return <ParagraphSegment structure={segment} />
      case SEGMENT_TAGS[SEGMENT_TYPES.table]:
        return <TableSegment structure={segment} />
      default:
        return null
    }
  }, [segment])

  // console.log('SEGMENT RENDER!')

  return render
})

Segment.displayName = 'Wizard-Editor-Segment'

export default Segment
