import React from 'react'
import PagesLayout from 'layout/PagesLayout/PagesLayout'
import { styled, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  lineHeight: 1.67,
  letterSpacing: 0.24,
  margin: theme.spacing(2, 5, 2, 0),
  fontWeight: 700,
}))


interface Props {
  isLoading: boolean
}
const InnerLayout: React.FC<Props> = ({ isLoading, children }) => {

  const { t } = useTranslation()

  const PageAssetsComponent = <Heading>{t('select_document_template')}</Heading>

  return (
    <PagesLayout
      isLoading={isLoading}
      PageAssetsComponent={PageAssetsComponent}
      hideHeader
      style={{ marginLeft: 0 }}
    >
      {children}
    </PagesLayout>
  )
}

export default InnerLayout
