import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTemplateUpload } from './hooks/useTemplateUpload'

import Layout from '../../../layout/Layout'
import UploadTemplateComponent from './UploadTemplate.component'
import { useCreateTemplate } from 'queries/templates'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

const UploadTemplate: React.FC = () => {
  const { goBack } = useHistory()
  const { upload, isLoading } = useTemplateUpload()
  const { mutate: create, isLoading: createIsLoading } = useCreateTemplate()
  const { check } = useSubscriptionCheck()

  const onCreate = (file: File | null, originalStylesKept: boolean = false) => {
    if (!check({ isFree: true, isStartup: true })) return
    if (!file) return create({})
    upload({ file, originalStylesKept })
  }

  return (
    <Layout
      step={1}
      heading={isLoading ? 'template_is_created' : 'upload_template'}
      onClose={goBack}
    >
      <UploadTemplateComponent
        isLoading={isLoading || createIsLoading}
        onCreate={onCreate}
        onBack={goBack}
      />
    </Layout>
  )
}

export default UploadTemplate
