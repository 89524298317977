import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { v4 as uuid } from 'uuid'

import { useFetchTemplate } from 'queries/templates'
import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { initQuestionsToAnswers } from 'components/features/documentGeneration/services/initQuestionsToAnswers'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'

import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useTranslation } from 'react-i18next'
import { DocumentGenerationWizard } from 'TemplateCreation-DocumentGeneration'
import documentsApi from 'api/docs.api'
import { analytics } from 'utils'

const Questionnaire: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { push, goBack } = useHistory()
  const { data = {} as any } = useFetchTemplate(templateId, true)
  const { isExpired } = useSubscriptionCheck()
  const { t } = useTranslation()
  const requirePayment = useIsTemplatePurchaseRequired(templateId)

  const { questions, htmlData, isCasus, name, v3 = {}, version } = data

  const {
    title: v3title,
    dataStructure: v3dataStructure,
    locations: v3locations,
    cssData: v3cssData,
    numberingSystem: v3numberingSystem,
    questions: v3questions,
    questionLayout: v3questionLayout,
  } = v3

  console.log(data)

  let renderOld = false
  renderOld = version === "v2"

  const { create } = documentsApi

  const closeHandler = useCallback(() => {}, [])
  const finishHandler = useCallback(
    ({ answers }) => {
      create(
        {
          new: true,
          name,
          //@ts-ignore
          v3Answers: answers.map(answer => ({ questionId: answer.id, value: answer.value })),
          templateId,
          category: [],
        },
        true
      ).then(doc => {
        analytics.logEvent('public_flow_create_document', {
          id: doc.id,
          templateId: doc.templateId,
          templateName: doc.templateName,
        })
        push(`/public/download/${doc.id}`)
      })
    },
    [create, name, templateId, push]
  )

  const V3component = useMemo(() => {
    // console.log(v3dataStructure, v3locations, v3cssData, v3numberingSystem, v3questions)
    return (
      <DocumentGenerationWizard
        // @ts-ignore
        id={uuid()}
        title={v3title}
        dataStructure={v3dataStructure}
        locations={v3locations}
        cssData={v3cssData}
        numberingSystem={v3numberingSystem}
        questions={v3questions}
        questionLayout={v3questionLayout}
        answers={[]}
        closeHandler={closeHandler}
        finishHandler={finishHandler}
      />
    )
  }, [
    v3title,
    v3dataStructure,
    v3locations,
    v3cssData,
    v3numberingSystem,
    v3questions,
    v3questionLayout,
    closeHandler,
    finishHandler,
  ])

  const render = useMemo(() => {
    return renderOld ? (
      <DocumentGenerationProvider
        initialData={{
          questions,
          name,
          templateHtmlData: htmlData,
          isCasus,
          isPublic: true,
          mode: 'create_v2_public',
          answers: initQuestionsToAnswers(questions),
          templateId: data.id,
          templateContentVersionId: data.contentVersionId,
          cssData: data.cssData,
          templateDataStructure: data.dataStructure,
          documentDataStructure: data.dataStructure,
        }}
      >
        <DocumentGeneration isExpired={isExpired} />
      </DocumentGenerationProvider>
    ) : (
      V3component
    )
  }, [renderOld, data, questions, name, htmlData, isCasus, isExpired, V3component])

  if (requirePayment) return <PurchaseTemplateModal templateId={templateId} open={true} handleClose={goBack} />

  if (!data)
    return (
      <DocumentGenerationLayout
        step={2}
        heading="Einen Moment bitte..."
        onClose={() => push(`/${t('documents')}`)}
        isLoading={true}
      />
    )

  // console.log(
  //   'GENERATE DOCUMENT PAGE: ',
  //   questions,
  //   name,
  //   initQuestionsToAnswers(questions)
  // )

  return render
}

export default Questionnaire

