export const debounceFunction = (callback, timeout = 100) => {
  if (typeof callback !== 'function') return
  let debounceTimeout
  return (...params) => {
    window.clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => callback(...params), timeout)
  }
}

export const throttleFunction = (callback, interval = 100) => {
  if (typeof callback !== 'function') return
  let throttle = false
  return (...params) => {
    if (throttle) return
    throttle = true
    setTimeout(() => {
      callback(...params)
      throttle = false
    }, interval)
  }
}

export const isObject = object => typeof object === 'object' && !Array.isArray(object) && object !== null
