import React, { useMemo } from 'react'
import useStore from '___store'
import { Extract, Insert, Pen, Trash } from 'assets/svgIconComponents'

import { Select } from 'components'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-wrapper',
  select: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-select',
}

export const QuestionListItem = React.memo(props => {
  const { id, isStandalone } = props
  const {
    questions: question = {},
    questionLayout = [],
    setConfiguring,
    removeQuestion,
    assignQuestion,
    unassignQuestion,
  } = useStore(`selectQuestions[${id}]`, 'selectQuestionLayout', 'setConfiguring', 'removeQuestion', 'assignQuestion', 'unassignQuestion')
  const {
    // type,
    text,
  } = question

  const options = useMemo(
    () =>
      questionLayout
        .filter(({ type }) => type !== 'loose')
        .reduce((acc, { id, label }) => Object.assign(acc, { [id]: label }), { newGroup: 'Create new group' }),
    [questionLayout]
  )

  // console.log(`QUESTION LIST ITEM %c${id}`, 'color: lightgreen;', 'RENDER!')

  return (
    <div className={classes.wrapper}>
      <span>{text || 'No question text...'}</span>
      <div>
        {isStandalone ? (
          <Select
            className={classes.select}
            options={options}
            onChange={qlg => assignQuestion({ id, questionLayoutGroup: qlg === 'newGroup' ? null : qlg })}
            chooseMode
            chooseRender={<Insert />}
            caretLeft
            anchor="bottom-left"
            direction="bottom-right"
          />
        ) : (
          <button type="button" onClick={() => unassignQuestion({ id })}>
            <Extract />
          </button>
        )}
        <button type="button" onClick={() => setConfiguring({ key: 'question', id })}>
          <Pen />
        </button>
        <button type="button" onClick={() => removeQuestion({ id })}>
          <Trash />
        </button>
      </div>
    </div>
  )
})

QuestionListItem.displayName = 'Wizard-Configuration-QuestionGeneration-QuestionListItem'

export default QuestionListItem
