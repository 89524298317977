import { Select } from 'components'
import React, { useCallback, useMemo } from 'react'
import useStore from '___store'

const QuestionOptionSelect = React.memo(props => {
  const {
    questionId,
    filter: filterTest,
    sort: sortFunction,
    append: appendOptionsObject,
    className,
    chooseRender = 'Select an option',
    anchor = 'bottom-left',
    direction = 'bottom-right',
    value,
    onChange,
  } = props

  const { questionOptions = [] } = useStore(`selectQuestionOptions[${questionId}]`)
  const selectedOption = useMemo(() => questionOptions.find(o => o.id === value), [questionOptions, value])
  const selectedOptionText = selectedOption?.text || selectedOption?.value || 'User input...'

  const filterCallback = useCallback(o => (typeof filterTest === 'function' ? o?.filter(filterTest) : o), [filterTest])
  const sortCallback = useCallback(o => (typeof sortFunction === 'function' ? o?.sort(sortFunction) : o), [sortFunction])
  const questionOptionSelectOptionObject = useMemo(
    () =>
      Object.assign(
        sortCallback(filterCallback(questionOptions))
          ?.filter(o => o.id !== value)
          .reduce((obj, o) => Object.assign(obj, { [o.id]: o.text || o.value || 'User input...' }), {}) || {},
        appendOptionsObject
      ),
    [sortCallback, filterCallback, questionOptions, value, appendOptionsObject]
  )

  return (
    <Select
      className={className}
      chooseMode={true}
      chooseRender={(value && selectedOptionText) || chooseRender}
      anchor={anchor}
      direction={direction}
      value={value}
      onChange={id => onChange(id)}
      options={questionOptionSelectOptionObject}
    />
  )
})

export default QuestionOptionSelect
