const getLogArray = (logType = 'log', actionType, ...payload) => {
  let logColor = 'white'
  switch (logType) {
    case 'log':
      logColor = 'dodgerblue'
      break
    case 'intercept':
      logColor = 'orange'
      break
    case 'error':
      logColor = 'red'
      break
    default:
      logColor = 'white'
  }

  const stringArray = [`%cSTORE ACTION: %c${actionType}     `, ...payload?.map(p => `%c${p}`)].join(' ')
  const logArray = [
    stringArray,
    `color: ${logColor}; font-weight: 700; font-size: 14px;`,
    'color: gray; font-size: 14px; font-family: "Heebo", sans-serif; font-weight: 100;',
    ...new Array(payload.length).fill(`color: lightgray; font-size: 12px; font-weight: 100; font-style: italic;`),
  ]
  return logArray
}

export { getLogArray }
