import React, { forwardRef, useImperativeHandle, useEffect, useMemo } from 'react'

import useStore from '___store'
import { QuestionGroup, Separator } from './components'

const classes = {
  wrapper: 'Wizard-Configuration-Questionnaire-wrapper',
  content: 'Wizard-Configuration-Questionnaire-content',
  scrollBuffer: 'Wizard-Configuration-Questionnaire-scrollBuffer',
  questionFade: 'Wizard-Configuration-Questionnaire-questionFade',
}

const getLayoutGroupId = (group, last) => {
  if (!group) return last ? 'start' : 'finish'
  const { id, type, questionIds } = group
  if (type !== 'loose') return id
  const idArray = questionIds.slice()
  if (last) idArray.reverse()
  return idArray[0]
}

export const Questionnaire = React.memo(
  forwardRef((_, ref) => {
    const { questionLayout = [], answering, setAnswering } = useStore('selectQuestionLayout', 'selectAnswering', 'setAnswering')

    useEffect(() => {
      if (!answering && questionLayout.length) {
        const firstGroup = questionLayout[0]
        return setAnswering({ id: firstGroup.type === 'loose' ? firstGroup.questionIds[0] : firstGroup.id })
      }
      // const beingAnsweredElement = document.querySelector('[data-being-answered="true"]')
      // console.log(beingAnsweredElement?.id, answering)
      const questionElement = document.getElementById(answering)
      if (!questionElement) return
      questionElement.scrollIntoView(true)
      // questionElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      const focusableElements = questionElement.querySelectorAll(`button, input`)
      if (!focusableElements && focusableElements[0]) return
      focusableElements[0].focus()
    }, [answering, questionLayout, setAnswering])

    const children = useMemo(
      () =>
        questionLayout.map(({ id, type, label, questionIds }, i) => {
          const previousId = getLayoutGroupId(questionLayout[i - 1], true)
          const nextId = getLayoutGroupId(questionLayout[i + 1])
          return type === 'separator' ? (
            <Separator key={`separator-${i}-${label}`} label={label} />
          ) : (
            <QuestionGroup key={id} id={id} type={type} label={label} questionIds={questionIds} previousGroupId={previousId} nextGroupId={nextId} />
          )
        }),
      [questionLayout]
    )

    // console.log('QUESTION LAYOUT: ', questionLayout)

    useImperativeHandle(ref, () => ({ key: 'document-generation', priority: 1 }), [])

    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {children}
          <div className={classes.scrollBuffer} />
        </div>
        <div className={classes.questionFade} />
      </div>
    )
  })
)

Questionnaire.displayName = 'Wizard-Questionnaire'

export default Questionnaire
