import { getLogArray } from './helpers'

export const interceptorMiddleware = store => next => action => {
  if (typeof action === 'function') store.dispatch(action())
  if (typeof action === 'object') {
    // if (Array.isArray(action)) action.forEach(action => store.dispatch(action))
    // else {
    const { type, payload } = action
    const { check, method } = store.interceptors[type] || {}
    if (check && method && typeof check === 'function' && typeof method === 'function' && check(store, payload))
      return method(store, next, action)
    return next(action)
    // }
  }
}

export const loggerMiddleware = store => next => action => {
  const { type, payload } = action
  if (payload && typeof payload === 'object' && Object.keys(payload).length && !Array.isArray(payload)) {
    console.groupCollapsed(...getLogArray('log', type))
    Object.entries(payload).forEach(([key, value]) => {
      console.log(`${key}: `, value)
    })
    console.groupEnd()
  } else {
    const params = ['log', type]
    if (payload) params.push(payload)
    const toLog = getLogArray(...params)
    if (payload) toLog.push('font-size: 14px;')
    console.log(...toLog)
  }
  return next(action)
}
