import { styled, fade } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 56,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: fade(theme.colorz.secondary[100], 0.7),
  paddingTop: '2vh',
  zIndex: 10,
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  height: 'max-content',
  width: '65%'
}))

export const UploadOptionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  overflowY: 'auto',
}))

export const Heading = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.colorz.secondary.main,
  padding: theme.spacing(2, 0),
}))

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  width: '60%',
}))

export const SubtitleDescription = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  textAlign: 'center',
  marginBottom: theme.spacing(3),
  width: '100%',
}))

export const Col = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  '&:not(:last-child)': {
    borderRight: '1px solid ' + theme.colorz.secondary[100],
  },
}))

const common = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const Header = styled(Box)(({ theme }) => ({
  ...common,
  height: 150,
}))

export const Footer = styled(Box)(({ theme }) => ({
  ...common,
  height: 80,
}))

export const BackBtn = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  transition: 'all .2s',
  color: theme.colorz.secondary[300],
  opacity: 0.85,
  cursor: 'pointer',
  fontWeight: 700,
  '&:hover': {
    opacity: 1,
    transform: 'translateY(-1.5px)',
  },
}))

export const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(3),
}))
