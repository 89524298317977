import React, { useMemo } from 'react'
import useStore from '___store'

import { Trash } from 'assets/svgIconComponents'
import { QuestionOptionSelect, QuestionSelect } from 'TemplateCreation-DocumentGeneration/components/ConfigurationComponent/common'
import { Switch } from 'components'

const classes = {
  wrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule-wrapper',
  select: 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule-select',
  switch: 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule-switch',
  button: 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule-button',
}

const ShowHideRule = props => {
  const { id, parentQuestionId } = props

  const {
    conditionalRule: rule = {},
    updateConditionalRule,
    removeConditionalRule,
  } = useStore(`selectConditionalRule[${id}]`, 'updateConditionalRule', 'removeConditionalRule')
  const { questionId, optionId, questionType, condition = {} } = rule
  const {
    needed,
    // comparisonOperator,
    // referenceValue,
  } = condition

  const questionSelect = useMemo(
    () => (
      <QuestionSelect
        filter={q => q.id !== parentQuestionId}
        className={classes.select}
        value={questionId}
        onChange={qid => updateConditionalRule({ id, questionId: qid, optionId: null })}
      />
    ),
    [questionId, parentQuestionId, updateConditionalRule, id]
  )

  const optionSelect = useMemo(
    () =>
      questionId ? (
        <QuestionOptionSelect
          key={`rule:${id}/q:${questionId}/optionSelect`}
          questionId={questionId}
          append={questionType === 'replacement' ? { value: 'Value Conditional' } : null}
          className={classes.select}
          value={optionId}
          onChange={oid =>
            updateConditionalRule(
              Object.assign({ id }, Object.assign({ optionId: oid }, needed === undefined ? { condition: { needed: true } } : null))
            )
          }
        />
      ) : null,
    [questionId, questionType, optionId, updateConditionalRule, id, needed]
  )

  const neededSwitch = useMemo(
    () =>
      optionId ? (
        <Switch
          className={classes.switch}
          value={Number(needed)}
          onChange={value => updateConditionalRule({ id, condition: { needed: Boolean(value) } })}
          labels={['FALSE', 'TRUE']}
        />
      ) : null,
    [optionId, needed, updateConditionalRule, id]
  )

  return (
    <div className={classes.wrapper}>
      {questionSelect}
      {optionSelect}
      {neededSwitch}
      <button className={classes.button} type="button" onClick={() => removeConditionalRule({ id, questionId: parentQuestionId })}>
        <Trash />
      </button>
    </div>
  )
}

ShowHideRule.displayName = 'Wizard-Configuration-AdvancedQuestionConfiguration-Rule'

export default ShowHideRule
