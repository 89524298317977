import React, { useEffect } from 'react'
import useStore from '___store'

import { Switch } from 'components'
import ShowHideRule from './ShowHideRule'

const classes = {
  wrapper: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragment',
  row: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentRow',
  controls: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentControls',
  label: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentLabel',
  switch: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentSwitch',
  button: 'Wizard-Configuration-AdvancedQuestionConfiguration-fragmentButton',
}

const ShowHideConditionalFragment = React.memo(props => {
  const { questionId } = props
  const {
    advancedQuestionConfiguration: advanced = {},
    updateAdvancedQuestionConfiguration,
    addNewConditionalRule,
  } = useStore(`selectAdvancedQuestionConfiguration[${questionId}]`, 'updateAdvancedQuestionConfiguration', 'addNewConditionalRule')

  const { conditionalFunctionality, logic, rules = [] } = advanced

  useEffect(() => {
    const payload = { questionId }
    if (conditionalFunctionality === undefined) Object.assign(payload, { conditionalFunctionality: 'show' })
    if (logic === undefined) Object.assign(payload, { logic: 'and' })
    if (Object.keys(payload).length > 1) updateAdvancedQuestionConfiguration(payload)
  }, [questionId, conditionalFunctionality, logic, updateAdvancedQuestionConfiguration])

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <span className={classes.label}>Show/Hide conditional logic</span>
        <Switch
          className={classes.switch}
          value={Number(conditionalFunctionality === 'show')}
          onChange={value => updateAdvancedQuestionConfiguration({ questionId, conditionalFunctionality: value ? 'show' : 'hide' })}
          labels={['Hide when', 'Show when']}
        />
      </div>
      {rules?.length ? (
        <div className={classes.row}>
          {rules.map(r => (
            <ShowHideRule key={r.id} id={r.id} parentQuestionId={questionId} />
          ))}
        </div>
      ) : null}
      <div className={classes.controls}>
        <Switch
          className={classes.switch}
          value={Number(logic === 'and')}
          onChange={value => updateAdvancedQuestionConfiguration({ questionId, logic: value ? 'and' : 'or' })}
          labels={['OR', 'AND']}
        />
        <button className={classes.button} type="button" onClick={() => addNewConditionalRule({ questionId })}>
          Add rule
        </button>
      </div>
    </div>
  )
})

ShowHideConditionalFragment.displayName = 'Wizard-AdvancedQuestionConfiguration-ShowHideConditionalFragment'

export default ShowHideConditionalFragment
