import React, { useMemo } from 'react'
import useStore from '___store'

import QuestionGroup from './QuestionGroup'
import Separator from './Separator'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionGeneration-QuestionGroupList-wrapper',
}

const QuestionGroupList = React.memo(props => {
  const { questionLayout = [] } = useStore('selectQuestionLayout')

  const children = useMemo(
    () => questionLayout.map(({ id, type }) => (type === 'separator' ? <Separator key={id} id={id} /> : <QuestionGroup key={id} id={id} />)),
    [questionLayout]
  )

  // console.log('QUESTION LAYOUT: ', questionLayout)

  return <div className={classes.wrapper}>{children}</div>
})

QuestionGroupList.displayName = 'Wizard-Configuration-QuestionGeneration-QuestionGroupList'

export default QuestionGroupList
