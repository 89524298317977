const ACTION_TYPES = {
  BATCH_ACTIONS: 'BATCH_ACTIONS', // Multiple actions sent at once
  WIZARD_RESET: 'WIZARD_RESET', // Wizard state was reset to uninitialized
  WIZARD_INITIALIZED: 'WIZARD_INITIALIZED', // Wizard was initialized with the initial state
  LOCATION_MARKED: 'LOCATION_MARKED', // The user marked a location for a choice/replacement marker
  LOCATION_UNMARKED: 'LOCATION_UNMARKED', // The user removed a location marker
  MARKER_ASSIGNED: 'MARKER_ASSIGNED', // The user has assigned a marker to a specified question
  MARKER_UNASSIGNED: 'MARKER_UNASSIGNED', // The user has unassigned a marker from a specified question
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////// QUESTION LAYOUT //////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  QUESTION_LAYOUT_SEPARATOR_ADDED: 'QUESTION_LAYOUT_SEPARATOR_ADDED', // The user has added a new question layout separator
  QUESTION_LAYOUT_GROUP_ADDED: 'QUESTION_LAYOUT_GROUP_ADDED', // The user has added a new question layout group
  QUESTION_LAYOUT_GROUP_UPDATED: 'QUESTION_LAYOUT_GROUP_UPDATED', // The user has updated a question layout group
  QUESTION_LAYOUT_GROUP_UNPACKED: 'QUESTION_LAYOUT_GROUP_UNPACKED', // The user has unpacked a question layout group
  QUESTION_LAYOUT_GROUP_REMOVED: 'QUESTION_LAYOUT_GROUP_REMOVED', // The user has removed a question layout group
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////////// QUESTIONS /////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  NEW_QUESTION_ADDED: 'NEW_QUESTION_ADDED', // The user created a new question
  QUESTION_UPDATED: 'QUESTION_UPDATED', // The user has updated a question configuration
  QUESTION_REMOVED: 'QUESTION_REMOVED', // The user has removed a question
  QUESTION_ASSIGNED: 'QUESTION_ASSIGNED', // The user assigned a question to a question layout group
  QUESTION_UNASSIGNED: 'QUESTION_UNASSIGNED', // The user unassigned a question from a question layout group
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// OPTIONS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  QUESTION_OPTION_UPDATED: 'QUESTION_OPTION_UPDATED', // The user has updated a question option
  // ///////////////////////////////////////////////////////////////////////////////// //
  // /////////////////////////////// ADVANCED QUESTION /////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  ADVANCED_QUESTION_CONFIGURATION_UPDATED: 'ADVANCED_QUESTION_CONFIGURATION_UPDATED', // The user has updated an advanced question configuration
  QUESTION_CONDITIONAL_RULE_ADDED: 'QUESTION_CONDITIONAL_RULE_ADDED', // The user has added a question conditional rule
  QUESTION_CONDITIONAL_RULE_UPDATED: 'QUESTION_CONDITIONAL_RULE_UPDATED', // The user has updated a question conditional rule
  QUESTION_CONDITIONAL_RULE_REMOVED: 'QUESTION_CONDITIONAL_RULE_REMOVED', // The user has removed a question conditional rule
  // ///////////////////////////////////////////////////////////////////////////////// //
  // //////////////////////////////////// ANSWERS //////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  NEXT_QUESTION_SET: 'NEXT_QUESTION_SET', // The user has moved to the next question
  PREVIOUS_QUESTION_SET: 'PREVIOUS_QUESTION_SET', // The user has moved to the next question
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////////////////////////////// //

  CHOICE_MARKER_UPDATED: 'CHOICE_MARKER_UPDATED', // The user has updated a choice marker configuration
  REPLACEMENT_MARKER_UPDATED: 'REPLACEMENT_MARKER_UPDATED', // The user has updated a replacement marker configuration
  CHOICE_MARKER_EVALUATED: 'CHOICE_MARKER_EVALUATED', // The user has answered a question which triggered a choice marker reevaluation
  REPLACEMENT_MARKER_EVALUATED: 'REPLACEMENT_MARKER_EVALUATED', // The user has answered a question which triggered a replacement marker reevaluation
  CONFIGURING_SET: 'CONFIGURING_SET', // Current configuring key and id was changed
  ANSWERING_SET: 'ANSWERING_SET', // Current answering id was changed
  CONFIGURING_DONE: 'CONFIGURING_DONE', // User has concluded configuring the current configuration object
  QUESTION_OPTION_ADDED: 'QUESTION_OPTION_ADDED', // The user has added a question option
  QUESTION_OPTION_REMOVED: 'QUESTION_OPTION_REMOVED', // The user has removed a question option
  QUESTION_OPTION_REORDERED: 'QUESTION_OPTION_REORDERED', // The user has reordered a question option
  QUESTION_ANSWERED: 'QUESTION_ANSWERED', // The user has answered a question
  // SEGMENT_INSERTED_AT_INDEX: 'SEGMENT_INSERTED_AT_INDEX', // A segment was inserted at the specified index, inside a specified parent, placed inside/outside existing markers
  // SEGMENT_REMOVED_AT_INDEX: 'SEGMENT_REMOVED_AT_INDEX', // A segment was removed at the specified index, inside a specified parent
  // PARAGRAPH_CONTENT_REPLACED: 'PARAGRAPH_CONTENT_REPLACED', // Paragraph content (textChunks) was replaced
  // CUSTOM_STYLE_APPLIED: 'CUSTOM_STYLE_APPLIED', // An existing custom style was applied to a segment
  // SEGMENT_REMOVED: 'SEGMENT_REMOVED', // An IDed segment was removed
  // SEGMENT_INSERTED_INTO_PAGE: 'SEGMENT_INSERTED_INTO_PAGE', // A segment was inserted at the end of an IDed page
  // SEGMENT_LABEL_UPDATED: 'SEGMENT_LABEL_UPDATED', // A segment label was updated
}

export default ACTION_TYPES
