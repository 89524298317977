import { v4 as uuid } from 'uuid'

import { ORIENTATION, PAGE_SIZES_IN_PT, PAPER_NAMES, pxpi } from 'utilities/hardcoded/documentSettings'

import {
  SEGMENT_TAGS,
  SEGMENT_TYPES,
  NESTED_STRUCTURE_KEYS,
  extractSubQuestions,
  parseQuestionLocations,
  findSegmentById,
  measureStructureSections,
} from './parsing'
import { CASUS_KEYSTRINGS, CASUS_CLASSES } from './constants'
// import { classes as wizardClasses } from './Wizard'
// import { classes as editorClasses } from './components/Editor/Editor'
// import { classes as sectionClasses } from './components/Editor/components/Section'
// import { classes as pageClasses } from './components/Editor/components/Page'
// import { classes as choiceMarkerClasses } from './components/Editor/components/ChoiceMarker'

const generatePageLayoutString = (id, layout = {}) => {
  const {
    orientation = ORIENTATION.vertical,
    paper = PAPER_NAMES.A4,
    margins: inchMargins = { top: 1.25, left: 1, bottom: 0.75, right: 1 },
  } = layout
  const { width: shortSide, height: longSide } = PAGE_SIZES_IN_PT[paper]
  const [width, minHeight] = orientation === 'portrait' ? [shortSide, longSide] : [longSide, shortSide]
  const margins = Object.entries(inchMargins).reduce((acc, [key, value = 0]) => ({ ...acc, [key]: value * pxpi }), {})
  // const pageIdentifier = `#section-${id} .${pageClasses.page}`
  // const segmentIdentifier = `#section-${id} .${pageClasses.page} .${pageClasses.content} > .${CASUS_CLASSES.segmentDiv}`
  // const markerIdentifier = `#section-${id} .${pageClasses.page} .${pageClasses.content} > .${CASUS_CLASSES.markerDiv}`
  // const choiceMarkerContentIdentifier = `#section-${id} .${pageClasses.page} .${pageClasses.content} > .${CASUS_CLASSES.markerDiv} > .${choiceMarkerClasses.content}`
  const pageIdentifier = `#section-${id} .${CASUS_CLASSES.pageContentRoot}`
  const segmentIdentifier = `#section-${id} .${CASUS_CLASSES.pageContentRoot} > .${CASUS_CLASSES.segmentDiv}`
  const markerIdentifier = `#section-${id} .${CASUS_CLASSES.pageContentRoot} > .${CASUS_CLASSES.choiceMarkerDiv}`
  const choiceMarkerContentIdentifier = `#section-${id} .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.choiceMarkerDiv} .${CASUS_CLASSES.choiceMarkerContent}`
  const pageRules = {
    width: `${width}px`,
    'min-height': `${minHeight}px`,
  }
  const verticalMargins = ['top', 'bottom']
  verticalMargins.forEach(position => (pageRules[`padding-${position}`] = `${margins[position] || 0}px`))
  const segmentMarkerRules = {}
  const horizontalMargins = ['left', 'right']
  horizontalMargins.forEach(position => (segmentMarkerRules[`padding-${position}`] = `${margins[position] || 0}px`))
  const pageStyle = `${pageIdentifier} { ${Object.entries(pageRules)
    .reduce((acc, [attribute, value]) => {
      acc.push(`${attribute}: ${value};`)
      return acc
    }, [])
    .join(' ')} }`
  const segmentStyle = `${segmentIdentifier} { ${Object.entries(segmentMarkerRules)
    .reduce((acc, [attribute, value]) => {
      acc.push(`${attribute}: ${value};`)
      return acc
    }, [])
    .join(' ')} }`
  const markerStyle = [
    `${markerIdentifier} { ${Object.entries(segmentMarkerRules)
      .reduce((acc, [attribute, value]) => {
        acc.push(`${attribute}: ${value};`)
        return acc
      }, [])
      .join(' ')} }`,
    `${choiceMarkerContentIdentifier}[data-start="false"] { margin-top: calc(-${margins.top}px); padding-top: ${margins.top}px }`,
    `${choiceMarkerContentIdentifier}[data-end="false"] { margin-bottom: calc(-${margins.bottom}px); padding-bottom: ${margins.bottom}px }`,
  ]
  return [pageStyle, segmentStyle, ...markerStyle].join('\n')
}

const extractStyleString = (styleObject = {}, prefix = '') =>
  Object.entries(styleObject).reduce((acc, [identifier, rules]) => {
    const [shallow, deep] = Object.entries(rules).reduce(
      (accumulated, [prop, value]) => {
        if (typeof value === 'string') accumulated[0] = Object.assign(accumulated[0], { [prop]: value })
        else accumulated[1] = Object.assign(accumulated[1], { [prop]: value })
        return accumulated
      },
      [{}, {}]
    )
    const shallowValues = Object.entries(shallow)
      .reduce((accumulated, [prop, value]) => [...accumulated, `${prop}: ${value};`], [])
      .join(' ')
    const shallowStyle = `${prefix}${identifier} { ${shallowValues} }`
    const deepStyles = extractStyleString(deep, `${prefix}${identifier}`)
    if (Object.values(shallow).length) acc.push(shallowStyle)
    if (deepStyles.length) acc.push(...deepStyles)
    return acc
  }, [])

const generateStyleString = (styleObject = {}) => {
  const { customStyles = {}, dynamicStyles = {}, customProperties = {} } = styleObject
  const combined = [...extractStyleString(customStyles, '.'), ...extractStyleString(dynamicStyles, '.')]
  return [
    ...combined.map(
      s =>
        // `.${wizardClasses.wrapper} .${wizardClasses.content} .${editorClasses.wrapper} .${sectionClasses.section} .${pageClasses.page} .${pageClasses.content} .${CASUS_CLASSES.segmentDiv} ${s}`
        // `.${wizardClasses.wrapper} .${wizardClasses.content} .${editorClasses.wrapper} .${CASUS_CLASSES.section} .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.segmentDiv} ${s}`
        `.${CASUS_CLASSES.section} .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.segmentDiv} ${s}`
    ),
    ...extractStyleString(customProperties),
  ].join('\n')
}

const generateListStartString = numberingSystem =>
  `.${CASUS_CLASSES.section} { counter-reset: ${Object.entries(numberingSystem)
    .map(([systemKey, levels]) =>
      levels.map((_, i) => `${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey} 0`).join(' ')
    )
    .join(' ')} }`

const generateListStylesString = (numberingSystem = {}) =>
  Object.entries(numberingSystem)
    .reduce((acc, [systemKey, systemObj]) => {
      const listCounterStyles = systemObj.reduce((accumulated, styleConfig, i, entries) => {
        const { styleName } = styleConfig
        const resetString = `.${CASUS_CLASSES.segmentDiv}.${styleName} { counter-reset: ${
          CASUS_CLASSES.listDepthLevel
        }_${i + 2}_${systemKey} 0; }`
        const content = styleConfig.combined
          ? `${entries.reduce((contentString, entry, j) => {
              return j < i
                ? `${contentString}'${entry.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${j + 1}_${systemKey}, ${
                    entry.type
                  })'${entry.suffix}${entry.combineString}'`
                : contentString
            }, '')}'${styleConfig.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}, ${
              styleConfig.type
            })'${styleConfig.suffix}${styleConfig.combineString} '`
          : `'${styleConfig.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}, ${
              styleConfig.type
            })'${styleConfig.suffix} '`
        const tag = `${SEGMENT_TAGS[SEGMENT_TYPES.paragraph]}.${styleName}::before`
        const textareaTag = `${SEGMENT_TAGS[SEGMENT_TYPES.textarea]}.${styleName}::before`
        const style = `counter-increment: ${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}; content: ${content};`
        const listString = `${tag} { ${style} }`
        const textareaListString = `${textareaTag} { ${style} }`
        accumulated.push(resetString)
        accumulated.push(listString)
        accumulated.push(textareaListString)
        return accumulated
      }, [])
      acc.push(...listCounterStyles)
      return acc
    }, [])
    .join('\n')

const generatePlaceholder = (segments = []) => {
  const firstSegment = segments[0]
  const { styleName = '', styles = [], generate = 'textChunks', value = '' } = firstSegment
  const placeholder = { start: +Infinity, styleName, styles }

  switch (generate) {
    case 'textChunks':
      placeholder.text = ''
      if (value) placeholder.value = value
      break
    case 'tableHeader':
    case 'tableBody':
    case 'tableFooter':
      placeholder.type = SEGMENT_TYPES.tableRow
      placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.tableRow]
      break
    case 'cells':
      placeholder.type = SEGMENT_TYPES.tableData
      placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.tableData]
      break
    case 'segments':
    case 'content':
    default:
      placeholder.id = uuid()
      placeholder.type = SEGMENT_TYPES.paragraph
      placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.paragraph]
      placeholder.textChunks = [{ value }]
  }
  return placeholder
}

const unwrapSegments = (segments = [], highlight = false) =>
  segments.map(segment =>
    !Array.isArray(segment)
      ? segment
      : segment.reduce(
          (acc, cur) => ({ ...acc, start: Math.min(acc.start, cur.start), length: (acc.length || 0) + cur.length }),
          highlight
            ? { start: +Infinity, content: segment, tag: SEGMENT_TAGS[SEGMENT_TYPES.mark], id: highlight }
            : generatePlaceholder(segment)
        )
  )

const changeSegments = (segments = [], change = {}, highlight = false, key = 'textChunks') =>
  segments.reduce(
    (acc, cur) => {
      const [segmentsArray] = acc
      if (acc[1]) segmentsArray.push(cur)
      else {
        const { start = 0, length = 0 } = cur
        const end = start + length
        const { start: changeStart = 0, length: changeLength = 0 } = change
        const changeEnd = changeStart + changeLength
        if (end <= changeStart) segmentsArray.push(cur)
        else if (start >= changeEnd) acc[1] = segmentsArray.push(cur)
        else {
          const segmentWithin = start >= changeStart && end <= changeEnd
          const isNesting = !!segmentsArray.length && Array.isArray(segmentsArray[segmentsArray.length - 1])
          if (segmentWithin) {
            const result = {
              ...cur,
              generate: key,
            }
            if (change.value !== CASUS_KEYSTRINGS.highlight)
              result.value = isNesting ? CASUS_KEYSTRINGS.remove : change.value
            if (isNesting) segmentsArray[segmentsArray.length - 1].push(result)
            else segmentsArray.push([result])
          } else {
            const result = applyChange(
              cur,
              change,
              highlight,
              isNesting && change.value !== CASUS_KEYSTRINGS.highlight && CASUS_KEYSTRINGS.remove
            )
            if (isNesting) {
              segmentsArray[segmentsArray.length - 1].push(result.shift())
              if (result.length) acc[1] = segmentsArray.push(...result)
            } else {
              segmentsArray.push(result.shift())
              if (result.length) segmentsArray.push(result)
              if (result.length > 1) acc[1] = segmentsArray.push(result.pop())
            }
          }
        }
      }
      return acc
    },
    [[], false]
  )

const changeTextChunks = (structure, change, propagatedValue) => {
  const res = []
  const { start = 0, length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength
  const calculatedStart = Math.max(changeStart - start, 0)
  const calculatedEnd = Math.max(Math.min(changeEnd - start, structure.text.length), 0)
  const calculatedLength = calculatedEnd - calculatedStart
  const leading = {
    ...structure,
    length: calculatedStart,
    text: structure.text.slice(0, calculatedStart),
  }
  const base = {
    ...structure,
    start: Math.max(changeStart, start),
    length: calculatedLength,
    text: structure.text.slice(calculatedStart, calculatedEnd),
  }
  if (change.value !== CASUS_KEYSTRINGS.highlight) base.value = propagatedValue || change.value
  const trailing = {
    ...structure,
    start: Math.min(changeEnd, end),
    length: structure.text.length - calculatedEnd,
    text: structure.text.slice(calculatedEnd),
  }
  if (leading.length && leading.length > 0) res.push(leading)
  if (base.length && base.length > 0) res.push(base)
  if (trailing.length && trailing.length > 0) res.push(trailing)
  return res
}

const applyChange = (structure = {}, change = {}, highlight = false, propagatedValue) => {
  const start = structure.id ? 0 : structure.start
  const { length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength

  if (end <= changeStart || start >= changeEnd) return [structure]
  if (structure.text && structure.text.length) return changeTextChunks(structure, change, propagatedValue)

  const result = { ...structure }
  NESTED_STRUCTURE_KEYS.every(key => {
    if (!(result[key] && result[key].length)) return true
    const [replacedSegments, done] = changeSegments(result[key], change, highlight, key)
    const res = unwrapSegments(replacedSegments, highlight && change.locationId)
    result[key] = res
    return !done
  })
  return [result]
}

const generateSegmentStructure = (section = {}, markers = [], highlight = false) => {
  const relevantMarkers = markers[section.id] || {}
  const relevantReplaceMarkers = relevantMarkers.replace || []
  const relevantRemoveMarkers = relevantMarkers.remove || []
  const relevantChanges = relevantReplaceMarkers.concat(relevantRemoveMarkers)
  const relevantHighlightMarkers = relevantMarkers.highlight || []

  const changed = relevantChanges.reduce((acc, cur) => applyChange(acc, cur)[0], section)
  const highlighted = !highlight
    ? changed
    : relevantHighlightMarkers.reduce((acc, cur) => applyChange(acc, cur, true)[0], changed)

  return NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (highlighted[key] && highlighted[key].length)
      // acc[key] = (highlighted[key] || []).map(object => generateChildren(object, replace, highlight))
      acc[key] = (highlighted[key] || []).map(object => generateSegmentStructure(object, markers, highlight))
    return acc
  }, section)
}

const generateDataStructure = (structure = {}, markers = [], highlight = false) => ({
  ...structure,
  sections: structure.sections?.map(section => generateSegmentStructure(section, markers[section.id], highlight)),
})

const splitMarkers = (locations = []) =>
  locations.reduce(
    (acc, location) => {
      if (location.length)
        acc[[CASUS_KEYSTRINGS.remove, CASUS_KEYSTRINGS.highlight].indexOf(location.value) + 1].push(location)
      return acc
    },
    [[], [], []]
  )

const isMarkerContained = (locations = [], marker = {}) =>
  locations.some(location => location.start <= marker.start && location.end >= marker.end)

const filterMarkers = (markerObject = {}, sections = []) =>
  Object.entries(markerObject).reduce((a, [sectionId, segmentsObject]) => {
    const section = sections.find(s => s.id === sectionId)
    if (section) {
      const segments = section.segments || []
      a[sectionId] = Object.entries(segmentsObject).reduce((acc, [id, locations]) => {
        const segment = findSegmentById(segments, id)
        if (segment) {
          const [replace, remove, highlight] = splitMarkers(locations)
          const fullSegmentRemovalMarker = remove.find(marker => marker.start === 0 && marker.length === segment.length)
          if (fullSegmentRemovalMarker) acc[id] = { remove: [fullSegmentRemovalMarker] }
          else {
            const removeFiltered = remove
              .sort((a, b) => b.length - a.length)
              .reduce((accumulated, current) => {
                if (!isMarkerContained(accumulated, current)) accumulated.push(current)
                return accumulated
              }, [])
            const replaceFiltered = replace
              .sort((a, b) => b.length - a.length)
              .reduce((accumulated, current) => {
                if (
                  current.value !== CASUS_KEYSTRINGS.keep &&
                  !isMarkerContained([...removeFiltered, ...accumulated], current)
                )
                  accumulated.push(current)
                return accumulated
              }, [])
            const highlightFiltered = highlight.filter(marker => !isMarkerContained(removeFiltered, marker))
            const resultingMarkers = { replace: replaceFiltered, remove: removeFiltered, highlight: highlightFiltered }
            if (resultingMarkers.replace.length + resultingMarkers.remove.length + resultingMarkers.highlight.length)
              acc[id] = resultingMarkers
          }
        }
        return acc
      }, {})
    }
    return a
  }, {})

const generateMarkers = (questions = [], answers = []) =>
  questions.reduce((acc, question) => {
    const relativeAnswer = answers.find(({ questionId }) => questionId === question.id)
    const locations = parseQuestionLocations(question)
    const result = locations.reduce((accumulated, { segmentId, questionId, locationId, optionId, start, length }) => {
      const questionType = question.type
      let value = CASUS_KEYSTRINGS.highlight
      if (relativeAnswer) {
        if (optionId) value = relativeAnswer.value.includes(optionId) ? CASUS_KEYSTRINGS.keep : CASUS_KEYSTRINGS.remove
        else value = relativeAnswer.value
      }
      const res = { segmentId, questionId, locationId, start, length, value, questionType }
      const sectionId = 'sct1' // get from location when implemented
      if (!accumulated[sectionId]) accumulated[sectionId] = { [segmentId]: [res] }
      else if (!accumulated[sectionId][segmentId]) accumulated[sectionId][segmentId] = [res]
      else accumulated[sectionId][segmentId].push(res)
      return accumulated
    }, acc)
    return result
  }, {})

const parseStructure = (tempDataStructure = {}, nestedQuestions = [], answers = [], highlight = false) => {
  const structure = JSON.parse(JSON.stringify(tempDataStructure))
  const sections = measureStructureSections(structure)
  structure.sections = sections
  const questions = extractSubQuestions(nestedQuestions)
  const markers = generateMarkers(questions, answers)
  const filteredMarkers = filterMarkers(markers, sections)
  const result = generateDataStructure(structure, filteredMarkers, highlight)
  return result
}

export {
  generatePageLayoutString,
  generateStyleString,
  generateListStartString,
  generateListStylesString,
  parseStructure,
}
