import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useDocument } from 'queries/documents'
import { useToast } from 'hooks/useToast'

import TemplatesApi from 'api/templates.api'

import { IDocument, ITemplate } from 'types'
// import { useUser } from 'queries/user/useUser'

export const useLoadEditDocumentData = (documentId: string) => {
  const { location } = useHistory()
  const params = new URLSearchParams(location.search)
  const { data: documentFile } = useDocument(documentId)
  const toast = useToast()
  // const user = useUser()
  const docXMicro = true
  const useLatestTemplateVersion = params.get('useLatestTemplateVersion')

  const [data, setData] = useState<{ documentFile: IDocument; template: ITemplate } | undefined>(undefined)

  const fetch = useCallback(async () => {
    if (documentFile && !data) {
      try {
        const contentVersionId = documentFile.templateContentVersionId
        const useLatest = useLatestTemplateVersion || !contentVersionId

        const template = useLatest
          ? await TemplatesApi.getOne(documentFile.templateId, undefined, docXMicro)
          : await TemplatesApi.getTemplateVersion(documentFile.templateId, documentFile.templateContentVersionId, docXMicro)
        setData({ documentFile, template })
      } catch (err) {
        toast('error', 'default')
      }
    }
  }, [documentFile, useLatestTemplateVersion, data, toast, docXMicro])

  useEffect(() => {
    fetch()
  }, [fetch])

  return data
}
