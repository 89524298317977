import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import useStore from '___store'

const classes = {
  wrapper: 'Wizard-Editor-Controls-wrapper',
  selectionDiv: 'Wizard-Editor-Controls-selection',
  standardStyleButton: 'Wizard-Editor-Controls-standardStyleButton',
}

const EditorControls = React.memo(props => {
  const { markLocation } = useStore('markLocation')

  const { selection } = props
  const { choice, replacement } = selection
  const { parent: choiceParent = {}, range: choiceRange = [] } = choice || {}
  const { id: choiceParentId = '', type: choiceParentType } = choiceParent
  const [choiceRangeStart, choiceRangeEnd] = choiceRange
  const { parent: replacementParent = {}, range: replacementRange = [] } = replacement || {}
  const { id: replacementParentId = '', type: replacementParentType } = replacementParent
  const [replacementRangeStart, replacementRangeEnd] = replacementRange

  const choiceMarkerObject = useMemo(
    () => ({
      id: choiceParentId,
      parentType: choiceParentType,
      locationId: uuid(),
      range: [choiceRangeStart, choiceRangeEnd],
    }),
    [choiceParentId, choiceParentType, choiceRangeStart, choiceRangeEnd]
  )
  const replacementMarkerObject = useMemo(
    () => ({
      id: replacementParentId,
      parentType: replacementParentType,
      locationId: uuid(),
      range: [replacementRangeStart, replacementRangeEnd],
    }),
    [replacementParentId, replacementParentType, replacementRangeStart, replacementRangeEnd]
  )

  return (
    <div className={classes.wrapper} data-empty={!(choice || replacement)}>
      {/* <div className={classes.selectionDiv}>
        {choiceRange ? (
          <span>
            CHOICE({choiceRange.parent.id}): {choiceRange.range[0]}-{choiceRange.range[1]}
          </span>
        ) : null}
        {replacementRange ? (
          <span>
            REPLACEMENT({replacementRange.parent.id}): {replacementRange.range[0]}-{replacementRange.range[1]}
          </span>
        ) : null}
      </div> */}
      {choice ? (
        <button
          className={classes.standardStyleButton}
          type="button"
          tabIndex={-1}
          onClick={() => {
            markLocation({ type: 'choice', ...choiceMarkerObject })
            document.getSelection().removeAllRanges()
          }}
        >
          C
        </button>
      ) : null}
      {replacement ? (
        <button
          className={classes.standardStyleButton}
          type="button"
          tabIndex={-1}
          onClick={() => {
            markLocation({ type: 'replacement', ...replacementMarkerObject })
            document.getSelection().removeAllRanges()
          }}
        >
          R
        </button>
      ) : null}
      {/* <button
        className={classes.standardStyleButton}
        type="button"
        tabIndex={-1}
        onClick={event => console.log('BOLD!')}
      >
        B
      </button>
      <button
        className={classes.standardStyleButton}
        type="button"
        tabIndex={-1}
        onClick={event => console.log('ITALIC!')}
      >
        I
      </button>
      <button
        className={classes.standardStyleButton}
        type="button"
        tabIndex={-1}
        onClick={event => console.log('UNDERLINE!')}
      >
        U
      </button> */}
    </div>
  )
})

EditorControls.displayName = 'Wizard-Editor-Controls'

export default EditorControls
