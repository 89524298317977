import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import useStore from '___store'

import Cross from 'assets/svgIconComponents/Cross'
import Trash from 'assets/svgIconComponents/Trash'

import Input from 'components/Input'
import Select from 'components/Select'
import Switch from 'components/Switch'
import { Caret } from 'assets/svgIconComponents'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionConfiguration-wrapper',
  typeDiv: 'Wizard-Configuration-QuestionConfiguration-typeDiv',
  typeWrapper: 'Wizard-Configuration-QuestionConfiguration-typeWrapper',
  configurationFragment: 'Wizard-Configuration-QuestionConfiguration-configurationFragment',
  controlSet: 'Wizard-Configuration-QuestionConfiguration-controlSet',
  markerTag: 'Wizard-Configuration-QuestionConfiguration-markerTag',
  questionOption: 'Wizard-Configuration-QuestionConfiguration-questionOption',
  questionOptionControls: 'Wizard-Configuration-QuestionConfiguration-questionOptionControls',
  optionOrderDiv: 'Wizard-Configuration-QuestionConfiguration-optionOrderDiv',
}

const MarkerTag = React.memo(props => {
  const { questionId, optionId, id } = props
  const { marker, unassignMarker } = useStore(`selectMarker[${id}]`, 'unassignMarker')
  const { label = '', contentText = '' } = marker

  return (
    <div className={classes.markerTag}>
      <span>{label || contentText || id}</span>
      <button type="button" onClick={() => unassignMarker({ questionId, markerId: id, optionId })}>
        <Cross />
      </button>
    </div>
  )
})

const valueTypes = ['string', 'date', 'date-time', 'time', 'number', 'currency'].reduce(
  (o, s) =>
    Object.assign(o, {
      [s]: s.charAt(0).toUpperCase() + s.slice(1),
    }),
  {}
)

const ReplacementOption = React.memo(props => {
  const { questionId, id, value = '', valueChangeCallback } = props
  const {
    questionOptions: option = {},
    updateQuestionOption,
    removeQuestionOption,
    reorderQuestionOption,
  } = useStore(`selectQuestionOptions[${questionId},${id}]`, 'updateQuestionOption', 'removeQuestionOption', 'reorderQuestionOption')

  const { type } = option

  return (
    <div className={classes.questionOption}>
      <Input className="replacement-option-value-input" value={value} onChange={valueChangeCallback} placeholder="User input" />
      <div className={classes.questionOptionControls} data-type="replacement">
        <Select
          className="replacement-option-type-select"
          value={type}
          anchor="bottom-left"
          direction="bottom-right"
          onChange={optionType => updateQuestionOption({ questionId, id, type: optionType })}
          options={valueTypes}
        />
        <div className={classes.optionOrderDiv}>
          <button type="button" onClick={() => reorderQuestionOption({ questionId, id, order: -1 })}>
            <Caret />
          </button>
          <button type="button" onClick={() => reorderQuestionOption({ questionId, id, order: 1 })}>
            <Caret />
          </button>
        </div>
        <button type="button" onClick={() => removeQuestionOption({ questionId, id })}>
          <Trash />
        </button>
      </div>
    </div>
  )
})

const ReplacementQuestionConfiguration = React.memo(
  forwardRef((props, ref) => {
    const [state, setState] = useState({})
    const [optionValues, setOptionValues] = useState({})
    const updateState = useCallback(payload => setState(prev => Object.assign({}, prev, payload)), [])
    const { id } = props
    const {
      questions: question = {},
      batchActions,
      updateQuestion,
      updateQuestionCreator,
      concludeConfiguring,
      concludeConfiguringCreator,
      addQuestionOption,
    } = useStore(
      `selectQuestions[${id}]`,
      'batchActions',
      'updateQuestion',
      'updateQuestionCreator',
      'concludeConfiguring',
      'concludeConfiguringCreator',
      'addQuestionOption'
    )

    const { markers = [], options = [], valueType = 'ambiguous' } = question

    const { text, description, hint } = useMemo(() => {
      const { text: storeText = '', description: storeDescription = '', hint: storeHint = '' } = question
      const { text: stateText, description: stateDescription, hint: stateHint } = state
      return {
        text: stateText !== undefined ? stateText : storeText,
        description: stateDescription !== undefined ? stateDescription : storeDescription,
        hint: stateHint !== undefined ? stateHint : storeHint,
      }
    }, [question, state])

    const optionValuesChanged = useMemo(() => !!Object.keys(optionValues).length, [optionValues])
    const stateChanged = useMemo(() => !!Object.keys(state).length, [state])

    const mappedOptions = useMemo(
      () =>
        optionValuesChanged
          ? options.slice().map(o => (optionValues[o.id] !== undefined ? Object.assign({}, o, { value: optionValues[o.id] }) : o))
          : options,
      [optionValuesChanged, options, optionValues]
    )

    const finishCallback = useCallback(() => {
      const resultingQuestion = { id }
      if (optionValuesChanged) Object.assign(resultingQuestion, { options: mappedOptions })
      if (stateChanged) Object.assign(resultingQuestion, state)
      if (Object.keys(resultingQuestion).length <= 1) return concludeConfiguring()
      const actions = [concludeConfiguringCreator()]
      actions.unshift(updateQuestionCreator(resultingQuestion))
      batchActions(actions)
    }, [
      id,
      optionValuesChanged,
      mappedOptions,
      stateChanged,
      state,
      concludeConfiguring,
      concludeConfiguringCreator,
      updateQuestionCreator,
      batchActions,
    ])

    useImperativeHandle(
      ref,
      () => ({
        key: 'question',
        priority: 2,
        buttonArray: [
          { text: 'Advanced', callback: () => console.log('REPLACEMENT ADVANCED!') },
          { text: 'Finish', callback: finishCallback },
        ],
      }),
      [finishCallback]
    )

    return (
      <div className={classes.typeWrapper}>
        <div className={classes.configurationFragment}>
          <span>Question text</span>
          <Input
            className="question-text-input"
            value={text}
            onChange={event => updateState({ text: event.target.value })}
            placeholder='Question text goes here... (e.g. "What is the contract type?")'
            autofocus
          />
        </div>
        <div className={classes.configurationFragment}>
          <span data-full="true">Document Markers</span>
          {!markers.length ? (
            <span data-tag="info">
              Select text in the document to highlight it, then select the appropriate marker option to create a marker and assign it to this
              question.
            </span>
          ) : null}
          {markers.map(markerId => (
            <MarkerTag key={`cmt-${markerId}`} questionId={id} id={markerId} />
          ))}
        </div>
        <div className={classes.configurationFragment}>
          <span>Answer options</span>
          <Select
            className="replacement-value-type-select"
            chooseMode={valueType === 'ambiguous'}
            chooseRender="Ambiguous"
            anchor="bottom-right"
            direction="bottom-left"
            value={valueType}
            onChange={valueType => updateQuestion({ id, valueType })}
            options={valueTypes}
          />
          {!options.length ? (
            <span data-tag="info">Add options that will appear for the user to choose between, when answering this question.</span>
          ) : null}
          {mappedOptions.map(o => (
            <ReplacementOption
              key={o.id}
              questionId={id}
              id={o.id}
              value={o.value}
              valueChangeCallback={event => {
                const value = event.target?.value
                setOptionValues(prev => Object.assign({}, prev, { [o.id]: value }))
              }}
              questionMarkers={markers}
            />
          ))}
          <Switch
            value={question.select === 'multi'}
            labels={['single select', 'multi select']}
            onChange={value => updateQuestion({ id, select: ['single', 'multi'][value] })}
          />
          <div className={classes.controlSet}>
            <Select
              className="replacement-add-option-select"
              chooseMode
              chooseRender="Add option"
              anchor="bottom-right"
              direction="bottom-left"
              onChange={optionType => addQuestionOption({ questionId: id, type: optionType })}
              options={valueTypes}
            />
          </div>
        </div>
        <div className={classes.configurationFragment}>
          <span>Additional Info:</span>
          <Input
            value={description}
            onChange={event => updateState({ description: event.target.value })}
            placeholder="Write a question description to better explain the question for the potential user of this template. This description will appear when answering the question."
            multiline
            maxLines={6}
          />
          <Input
            value={hint}
            onChange={event => updateState({ hint: event.target.value })}
            placeholder="This is a field for practical hints. Use this to give answer examples, or hints about which options correspond to which marked segments in the document, or a any hints in general."
            multiline
            maxLines={4}
          />
        </div>
      </div>
    )
  })
)

const ChoiceOption = React.memo(props => {
  const { questionId, id, text = '', textChangeCallback, questionMarkers = [] } = props
  const {
    choiceMarkers,
    questionOptions: option = {},
    removeQuestionOption,
    reorderQuestionOption,
    assignMarker,
  } = useStore('selectChoiceMarkers', `selectQuestionOptions[${questionId},${id}]`, 'removeQuestionOption', 'reorderQuestionOption', 'assignMarker')

  const optionMarkers = option.markers || []
  const markerOptions = useMemo(
    () =>
      choiceMarkers
        .filter(({ id: markerId }) => questionMarkers.includes(markerId))
        .reduce(
          (acc, { id: markerId, label }) => (optionMarkers.includes(markerId) ? acc : Object.assign(acc, { [markerId]: label || markerId })),
          {}
        ),
    [choiceMarkers, questionMarkers, optionMarkers]
  )

  return (
    <div className={classes.questionOption}>
      <Input className="choice-option-text-input" value={text} onChange={textChangeCallback} placeholder="Option text" />
      <div className={classes.questionOptionControls} data-type="choice">
        <div className={classes.optionOrderDiv}>
          <button type="button" onClick={() => reorderQuestionOption({ questionId, id, order: -1 })}>
            <Caret />
          </button>
          <button type="button" onClick={() => reorderQuestionOption({ questionId, id, order: 1 })}>
            <Caret />
          </button>
        </div>
        <button type="button" onClick={() => removeQuestionOption({ questionId, id })}>
          <Trash />
        </button>
      </div>
      <Select
        className="choice-option-marker-select"
        chooseMode
        chooseRender="Assign marker"
        caretLeft
        anchor="bottom-left"
        direction="bottom-right"
        onChange={markerId => assignMarker({ markerId, questionId, optionId: id, type: 'choice' })}
        options={markerOptions}
      />
      {!optionMarkers.length ? <span data-tag="info">There are no markers connected to this option...</span> : null}
      {optionMarkers.map(markerId => (
        <MarkerTag key={`cmt-${markerId}`} questionId={questionId} id={markerId} optionId={id} />
      ))}
    </div>
  )
})

const ChoiceQuestionConfiguration = React.memo(
  forwardRef((props, ref) => {
    const [state, setState] = useState({})
    const [optionTexts, setOptionTexts] = useState({})
    const updateState = useCallback(payload => setState(prev => Object.assign({}, prev, payload)), [])
    const { id } = props
    const {
      questions: question = {},
      batchActions,
      updateQuestion,
      updateQuestionCreator,
      concludeConfiguring,
      concludeConfiguringCreator,
      addQuestionOption,
      addQuestionOptionCreator,
      setConfiguring,
    } = useStore(
      `selectQuestions[${id}]`,
      'batchActions',
      'updateQuestion',
      'updateQuestionCreator',
      'concludeConfiguring',
      'concludeConfiguringCreator',
      'addQuestionOption',
      'addQuestionOptionCreator',
      'setConfiguring'
    )

    const { markers = [], options = [] } = question

    const unassignedMarkers = useMemo(
      () => markers.filter(markerId => !options.some(({ markers }) => markers?.includes(markerId))),
      [markers, options]
    )

    const { text, description, hint } = useMemo(() => {
      const { text: storeText = '', description: storeDescription = '', hint: storeHint = '' } = question
      const { text: stateText, description: stateDescription, hint: stateHint } = state
      return {
        text: stateText !== undefined ? stateText : storeText,
        description: stateDescription !== undefined ? stateDescription : storeDescription,
        hint: stateHint !== undefined ? stateHint : storeHint,
      }
    }, [question, state])

    const optionTextsChanged = useMemo(() => !!Object.keys(optionTexts).length, [optionTexts])
    const stateChanged = useMemo(() => !!Object.keys(state).length, [state])

    const mappedOptions = useMemo(
      () =>
        optionTextsChanged
          ? options.slice().map(o => (optionTexts[o.id] !== undefined ? Object.assign({}, o, { text: optionTexts[o.id] }) : o))
          : options,
      [optionTextsChanged, options, optionTexts]
    )

    const advancedCallback = useCallback(() => setConfiguring({ key: 'question-advanced', id }), [setConfiguring, id])

    const finishCallback = useCallback(() => {
      const resultingQuestion = { id }
      if (optionTextsChanged) Object.assign(resultingQuestion, { options: mappedOptions })
      if (stateChanged) Object.assign(resultingQuestion, state)
      if (Object.keys(resultingQuestion).length <= 1) return concludeConfiguring()
      const actions = [concludeConfiguringCreator()]
      actions.unshift(updateQuestionCreator(resultingQuestion))
      batchActions(actions)
    }, [
      id,
      optionTextsChanged,
      mappedOptions,
      stateChanged,
      state,
      concludeConfiguring,
      concludeConfiguringCreator,
      updateQuestionCreator,
      batchActions,
    ])

    useImperativeHandle(
      ref,
      () => ({
        key: 'question',
        priority: 2,
        buttonArray: [
          { text: 'Advanced', callback: advancedCallback },
          { text: 'Finish', callback: finishCallback },
        ],
      }),
      [advancedCallback, finishCallback]
    )

    return (
      <div className={classes.typeWrapper}>
        <div className={classes.configurationFragment}>
          <span>Question text</span>
          <Input
            className="question-text-input"
            value={text}
            onChange={event => updateState({ text: event.target.value })}
            placeholder='Question text goes here... (e.g. "What is the contract type?")'
            autofocus
          />
        </div>
        <div className={classes.configurationFragment}>
          <span data-full="true">Document Markers</span>
          {!markers.length ? (
            <span data-tag="info">
              Select text in the document to highlight it, then select the appropriate marker option to create a marker and assign it to this
              question.
            </span>
          ) : null}
          {markers.map(markerId => (
            <MarkerTag key={`cmt-${markerId}`} questionId={id} id={markerId} />
          ))}
        </div>
        <div className={classes.configurationFragment}>
          <span>Answer options</span>
          {!options.length ? (
            <span data-tag="info">
              Add options that will appear for the user to choose between, when answering this question. You can add each one manually and assign
              relevant markers, or click the "Assign Automatically" button to create a separate option for each marker and assign them automatically.
            </span>
          ) : null}
          {mappedOptions.map(o => (
            <ChoiceOption
              key={o.id}
              questionId={id}
              id={o.id}
              text={o.text}
              textChangeCallback={event => {
                const value = event.target?.value
                setOptionTexts(prev => Object.assign({}, prev, { [o.id]: value }))
              }}
              questionMarkers={markers}
            />
          ))}
          <Switch
            value={question.select === 'multi'}
            labels={['single select', 'multi select']}
            onChange={value => updateQuestion({ id, select: ['single', 'multi'][value] })}
          />
          <div className={classes.controlSet}>
            <button type="button" onClick={() => addQuestionOption({ questionId: id })}>
              Add option
            </button>
            <button
              type="button"
              disabled={!unassignedMarkers.length}
              onClick={() => {
                const actions = unassignedMarkers.map(markerId => addQuestionOptionCreator({ questionId: id, markers: [markerId] }))
                batchActions(actions)
              }}
            >
              Assign automatically
            </button>
          </div>
        </div>
        <div className={classes.configurationFragment}>
          <span>Additional Info:</span>
          <Input
            value={description}
            onChange={event => updateState({ description: event.target.value })}
            placeholder="Write a question description to better explain the question for the potential user of this template. This description will appear when answering the question."
            multiline
            maxLines={6}
          />
          <Input
            value={hint}
            onChange={event => updateState({ hint: event.target.value })}
            placeholder="This is a field for practical hints. Use this to give answer examples, or hints about which options correspond to which marked segments in the document, or a any hints in general."
            multiline
            maxLines={4}
          />
        </div>
      </div>
    )
  })
)

const QuestionTypeSelection = React.memo(props => {
  const { id } = props
  const { updateQuestion } = useStore('updateQuestion')

  return (
    <div className={classes.typeDiv}>
      <span>Create a new question</span>
      <button type="button" onClick={() => updateQuestion({ id, type: 'choice' })}>
        <span>PICK & CHOOSE</span>
        <p>Define answer options and connect them to different segments, and determine which of those will be kept in the final document.</p>
      </button>
      <button type="button" onClick={() => updateQuestion({ id, type: 'replacement', valueType: 'ambiguous' })}>
        <span>REPLACE WITH ANSWERS</span>
        <p>Mark parts of segments that will be replaced with selected answers.</p>
      </button>
    </div>
  )
})

export const QuestionConfiguration = React.memo(
  forwardRef((props, ref) => {
    const { id } = props
    const { questions: question = {}, concludeConfiguring } = useStore(`selectQuestions[${id}]`, 'concludeConfiguring')

    const { type } = question

    useImperativeHandle(ref, () => ({ key: 'question', priority: 1, buttonArray: [{ text: 'Finish', callback: concludeConfiguring }] }), [
      concludeConfiguring,
    ])

    // console.log('QUESTION CONFIGURATION: ', id, type)

    return (
      <div className={classes.wrapper}>
        {!type && <QuestionTypeSelection id={id} />}
        {type === 'choice' && <ChoiceQuestionConfiguration ref={ref} id={id} />}
        {type === 'replacement' && <ReplacementQuestionConfiguration ref={ref} id={id} />}
      </div>
    )
  })
)

QuestionConfiguration.displayName = 'Wizard-QuestionConfiguration'

export default QuestionConfiguration
