import { findSegmentById } from 'TemplateCreation-DocumentGeneration/parsing'
import { evaluateQuestionConditionalOutcome } from './helpers/index' // remove /index
import { contentIdsSelectorFunction } from './helpers'

const selectors = {
  selectConfiguringStack: (state, id) => state.wizard.configuring || [],
  selectConfiguring: (state, id) => {
    const configuringStack = selectors.selectConfiguringStack(state)
    const configuringStackLength = configuringStack.length
    const configuring = (configuringStackLength && configuringStack[configuringStackLength - 1]) || null
    return id ? configuring?.id === id : configuring
  },
  selectAnswering: (state, id) =>
    id
      ? state.wizard.answering === id ||
        state.wizard.questionLayout.find(({ id: layoutId }) => layoutId === state.wizard.answering)?.questionIds?.includes(id) ||
        false
      : state.wizard.answering,
  selectDataStructure: state => state.wizard.dataStructure,
  selectSections: state => state.wizard.sections || [],
  selectLocations: state => state.wizard.locations,
  selectStyles: state => state.wizard.styles,
  selectNumberingSystem: state => state.wizard.numberingSystem,
  selectQuestions: (state, id) => (id ? state.wizard.questions?.find(q => q.id === id) : state.wizard.questions),
  selectQuestionLayout: (state, id) => (id ? state.wizard.questionLayout?.find(ql => ql.id === id) : state.wizard.questionLayout),
  selectAdvancedQuestionConfiguration: (state, id) => id && selectors.selectQuestions(state, id)?.advanced,
  selectConditionalRule: (state, id, rule = null) =>
    selectors.selectQuestions(state)?.find(q => {
      const { advanced: { rules = [] } = {} } = q
      return (rule = rules.find(rule => rule.id === id))
    }) && rule,
  selectAnswers: (state, id) => (id ? state.wizard.answers?.find(q => q.id === id) : state.wizard.answers),
  selectMode: state => state.wizard.mode,

  selectSectionCount: state => selectors.selectSections(state).length || 0,
  selectSectionInfo: (state, sectionIndex = 0) => (state.wizard.sections || [])[Number(sectionIndex)],
  selectContentIds: (state, type, ...extra) => contentIdsSelectorFunction(state, type, ...extra),
  selectChoiceMarkers: state =>
    Object.values(state.wizard.locations.choice).reduce((acc, cur) => {
      acc.push(...cur)
      return acc
    }, []),
  selectReplacementMarkers: (state, parentId) =>
    parentId
      ? state.wizard.locations.replacement[parentId]
      : Object.values(state.wizard.locations.replacement).reduce((acc, cur) => {
          acc.push(...cur)
          return acc
        }, []),
  selectMarker: (state, id) =>
    [...selectors.selectChoiceMarkers(state), ...selectors.selectReplacementMarkers(state)].find(({ id: markerId }) => id === markerId),
  selectQuestionOptions: (state, questionId, id) => {
    if (!questionId) return
    const relevantQuestion = selectors.selectQuestions(state, questionId)
    if (!relevantQuestion) return
    return id ? relevantQuestion.options?.find(o => o.id === id) : relevantQuestion.options
  },
  selectCurrentQuestion: (state, type, id) => {
    const current = selectors.selectConfiguring(state)
    if (current?.key === 'question') {
      const question = selectors.selectQuestions(state, current.id)
      if (!question) return false
      if (!type) return question
      if (question.type === type) return id ? question.markers?.includes(id) : question
    }
    return false
  },
  selectDependencies: (state, id) => (id ? (Object.entries(state.wizard.dependencies)?.find(([d]) => d === id) || [])[1] : state.wizard.dependencies),
  selectQuestionConditionalOutcome: (state, id) => evaluateQuestionConditionalOutcome(state.wizard, id),
  // selectPageIds: (state, sectionIndex, pageIndex) => pageIdsSelectorFunction(state, sectionIndex, pageIndex),
  // selectMarkerContentIds: (state, id, range) => markerIdsSelectorFunction(state, id, range),
  // selectPageLocations: (state, sectionIndex, pageIndex)F =>
  //   pageLocationsSelectorFunction(state, sectionIndex, pageIndex),
  // selectSectionIdsString: (state, sectionIndex = 0) =>
  //   selectors
  //     .selectSections(state)
  //     [sectionIndex]?.pages?.reduce((acc, { ids }) => [...acc, ...ids], [])
  //     .join('; ') || '',
  // selectSectionIdsString: (state, sectionIndex = 0) => {
  //   const range = selectors.selectSections(state)[Number(sectionIndex)]?.range || [0, 0]
  //   console.log('RANGE: ', range)
  //   return (
  //     state.wizard.dataStructure?.segments
  //       ?.slice(...range)
  //       .reduce((acc, cur) => [acc, cur.id].filter(e => e).join('; '), '') || ''
  //   )
  // },
  // selectDocumentSettings: state => state.wizard.documentSettings,
  selectSegment: (state, segmentId) => {
    if (!segmentId) {
      return state.wizard.dataStructure?.segments
      // return console.warn('Missing segment ID for selectSegment selector! Please provide a valid ID.')
    }
    return findSegmentById(state.wizard.dataStructure?.segments, segmentId)
    // return findSegmentById(state.wizard.sections, segmentId)
  },
}

export { selectors }
export default selectors
