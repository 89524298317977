import React, { useRef, useState } from 'react'
import useStore from '___store'
import { CheckBox, Input, RadioButton } from 'components'

import { CASUS_KEYSTRINGS } from 'TemplateCreation-DocumentGeneration/constants'

const classes = {
  wrapper: 'Wizard-Configuration-Questionnaire-QuestionOption-wrapper',
  //   checkBox: 'Wizard-Configuration-Questionnaire-QuestionOption-checkBox',
  //   radioButton: 'Wizard-Configuration-Questionnaire-QuestionOption-radioButton',
}

export const QuestionOption = React.memo(props => {
  const optionRef = useRef()
  const [userInput, setUserInput] = useState()
  const { questionId, id, select, selected, storedValue, handler, disabled } = props
  const { questionOptions: option = {} } = useStore(`selectQuestionOptions[${questionId},${id}]`)

  const { text, value, placeholder = 'User input...' } = option

  const isUserDefined = text === undefined && !value
  const calculatedValue = userInput || storedValue || ''

  const userValue = `${CASUS_KEYSTRINGS.userInput}:${id}:${calculatedValue}`

  return (
    <div ref={optionRef} className={classes.wrapper} data-selected={selected}>
      {select === 'multi' ? (
        <>
          <CheckBox
            // className={classes.checkBox}
            disabled={disabled}
            label={text || value}
            value={selected}
            onChange={active => {
              handler(active, isUserDefined ? userValue : id)
              if (isUserDefined && !calculatedValue) return optionRef.current?.querySelector('input')?.focus()
            }}
          />
          {isUserDefined ? (
            <Input
              disabled={disabled}
              placeholder={placeholder}
              // value={calculatedValue}
              defaultValue={calculatedValue}
              needsConfirmation
              // onChange={({ target: { value } }) => {
              onChange={value => {
                setUserInput(value)
                handler(!!value, `${CASUS_KEYSTRINGS.userInput}:${id}:${value}`)
              }}
            />
          ) : null}
        </>
      ) : (
        <>
          <RadioButton
            // className={classes.radioButton}
            disabled={disabled}
            label={text || value}
            value={selected}
            onChange={() => {
              handler(true, isUserDefined ? userValue : id)
              if (isUserDefined && !calculatedValue) return optionRef.current?.querySelector('input')?.focus()
            }}
          />
          {isUserDefined ? (
            <Input
              disabled={disabled}
              placeholder={placeholder}
              // value={calculatedValue}
              defaultValue={calculatedValue}
              needsConfirmation
              // onChange={({ target: { value } }) => {
              onChange={value => {
                setUserInput(value)
                handler(!!value, `${CASUS_KEYSTRINGS.userInput}:${id}:${value}`)
              }}
            />
          ) : null}
        </>
      )}
    </div>
  )
})

QuestionOption.displayName = 'Wizard-Questionnaire-QuestionOption'

export default QuestionOption
