import { Cross } from 'assets/svgIconComponents'
import { Input } from 'components'
import React from 'react'
import useStore from '___store'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionGeneration-Separator-wrapper',
  input: 'Wizard-Configuration-QuestionGeneration-Separator-input',
}

const Separator = React.memo(({ id }) => {
  const {
    questionLayout: separator = {},
    updateQuestionLayoutGroup,
    removeQuestionLayoutGroup,
  } = useStore(`selectQuestionLayout[${id}]`, 'updateQuestionLayoutGroup', 'removeQuestionLayoutGroup')
  const { label } = separator

  return (
    <div className={classes.wrapper} style={{ '--label': `"${label || id}"` }}>
      <Input
        className={classes.input}
        placeholder={'Separator label...'}
        defaultValue={label || id}
        needsConfirmation
        onChange={value => updateQuestionLayoutGroup({ id, label: value })}
      />
      <button type="button" onClick={() => removeQuestionLayoutGroup({ id })}>
        <Cross />
      </button>
    </div>
  )
})

Separator.displayName = 'Wizard-Configuration-QuestionGeneration-Separator'

export default Separator
