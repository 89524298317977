import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useStore from '___store'
import { answersType, dataStructureType, locationsType, numberingSystemType, questionsType } from 'utilities/propTypes'

import { Header, Footer, Editor, Configuration } from './components'

export const classes = {
  wrapper: 'Wizard-wrapper',
  content: 'Wizard-content',
  title: 'Wizard-title',
}

export const Wizard = React.memo(props => {
  const { resetWizard, initializeWizard } = useStore('resetWizard', 'initializeWizard')

  const {
    title,
    dataStructure,
    locations,
    cssData: styles,
    numberingSystem,
    questions,
    questionLayout,
    answers,
    mode,
    closeHandler,
    finishHandler,
  } = props

  useEffect(() => {
    const configuring = [{ key: mode, id: mode }]
    const firstLayoutGroup = questionLayout && questionLayout[0]
    const answering =
      (mode === 'document-generation' &&
        (firstLayoutGroup?.type === 'loose' ? firstLayoutGroup?.questionIds[0] : firstLayoutGroup?.id)) ||
      null
    const initialSettings = {
      configuring: configuring || [],
      answering: answering || null,
      dataStructure: dataStructure,
      locations: locations || { choice: {}, replacement: {} },
      styles: styles || {},
      numberingSystem: numberingSystem || {},
      questions: questions || [],
      questionLayout: questionLayout || [],
      answers: answers || [],
      mode: mode,
    }
    initializeWizard(initialSettings)
    return resetWizard
  }, [
    mode,
    dataStructure,
    locations,
    styles,
    numberingSystem,
    questions,
    questionLayout,
    answers,
    initializeWizard,
    resetWizard,
  ])

  return (
    <main className={classes.wrapper}>
      <Header onCloseHandler={closeHandler} />
      <section className={classes.content}>
        <Configuration />
        <Editor />
        <div className={classes.title}>{title}</div>
      </section>
      <Footer onFinishHandler={finishHandler} />
    </main>
  )
})

Wizard.displayName = 'Wizard'

Wizard.propTypes = {
  dataStructure: dataStructureType(),
  locations: locationsType(),
  // cssData: cssDataType(),
  numberingSystem: numberingSystemType(),
  questions: questionsType(),
  answers: answersType(),
  mode: PropTypes.oneOf(['template-creation', 'document-generation']).isRequired,
}

export default Wizard
