import React from 'react'
import { PersonWrapper, StrongText } from './SigningDocumentModal.styles'

export const SigningPersonItem = React.memo(( email, handleDeletePerson, ...props ) => {
  return (
    <PersonWrapper {...props}>
      <StrongText>{email}</StrongText>
      
    </PersonWrapper>
  )
})

