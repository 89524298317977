import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { useLoadEditDocumentData } from './useLoadEditDocumentData'
import { generateDocumentHtmlData } from 'components/features/documentGeneration/services/generateDocumentHtmlData'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'
import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { useUser } from 'queries/user/useUser'
import { DocumentGenerationWizard } from 'TemplateCreation-DocumentGeneration'
import { v3parse } from 'TemplateCreation-DocumentGeneration/parsing'
import { useDocumentsUpdate } from 'queries/documents'
import { useToast } from 'hooks/useToast'

const DocumentEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const data = (useLoadEditDocumentData(id) || {}) as any
  const { isExpired } = useSubscriptionCheck()
  const isPaymentRequired = useIsTemplatePurchaseRequired(data?.documentFile?.templateId)
  const { push, goBack } = useHistory()
  const update = useDocumentsUpdate()
  const toast = useToast()

  const user = useUser()
  const version = user?.version
  const docXmicro = true

  const { template = {}, documentFile = {} } = data as any

  const { questions, htmlData, isCasus, v3 = {}, id: templateId, contentVersionId } = template
  const { answers, name, settings, isDirty, documentHtmlData, v3Answers, templateContentVersionId } = documentFile

  const {
    title: v3title,
    dataStructure: v3dataStructure,
    locations: v3locations,
    cssData: v3cssData,
    numberingSystem: v3numberingSystem,
    questions: v3questions,
    questionLayout: v3questionLayout,
  } = v3 as any

  const { answers: v3answers } = v3parse(undefined, undefined, undefined, undefined, v3Answers)

  let renderOld = false
  renderOld = version !== 'v3'

  const closeHandler = useCallback(() => push('/documents'), [push])
  const finishHandler = useCallback(
    ({ answers }) => {
      update.mutate(
        {
          id,
          version: 'v2',
          data: {
            v3Answers: answers.map((answer: any) => ({ questionId: answer.id, value: answer.value })),
            templateContentVersionId,
          },
        },
        {
          onSuccess: () => push('/documents'),
          onError: () => toast('error', 'default'),
          onSettled: () => push('/documents'),
        }
      )
    },
    [update, id, templateContentVersionId, push, toast]
  )

  const V3component = useMemo(() => {
    // console.log(v3dataStructure, v3locations, v3cssData, v3numberingSystem, v3questions)
    return (
      <DocumentGenerationWizard
        // @ts-ignore
        id={id}
        title={v3title}
        dataStructure={v3dataStructure}
        locations={v3locations}
        cssData={v3cssData}
        numberingSystem={v3numberingSystem}
        questions={v3questions}
        questionLayout={v3questionLayout}
        answers={v3answers}
        closeHandler={closeHandler}
        finishHandler={finishHandler}
      />
    )
  }, [
    id,
    v3title,
    v3dataStructure,
    v3locations,
    v3cssData,
    v3numberingSystem,
    v3questions,
    v3questionLayout,
    v3answers,
    closeHandler,
    finishHandler,
  ])

  const render = useMemo(() => {
    return renderOld ? (
      Object.keys(data).length === 0 ? (
        <DocumentGenerationLayout step={2} heading="Einen Moment bitte..." onClose={goBack} isLoading={true} />
      ) : (
        <DocumentGenerationProvider
          initialData={{
            questions,
            name,
            templateHtmlData: htmlData,
            isCasus,
            answers,
            settings,
            mode: 'edit',
            templateId: templateId,
            templateContentVersionId: contentVersionId, // TODO
            documentId: id,
            htmlData: isDirty ? generateDocumentHtmlData(htmlData, questions, answers, docXmicro) : documentHtmlData,
            isDirty,
          }}
        >
          <DocumentGeneration isExpired={isExpired} />
        </DocumentGenerationProvider>
      )
    ) : (
      V3component
    )
  }, [
    renderOld,
    questions,
    name,
    htmlData,
    isCasus,
    answers,
    settings,
    templateId,
    contentVersionId,
    id,
    isDirty,
    docXmicro,
    documentHtmlData,
    isExpired,
    V3component,
  ])

  if (!data)
    return <DocumentGenerationLayout step={2} heading="Einen Moment bitte..." onClose={goBack} isLoading={true} />

  if (isPaymentRequired && data?.documentFile)
    return <PurchaseTemplateModal templateId={data.documentFile.templateId} open={true} handleClose={goBack} />

  return render
}

export default DocumentEdit
