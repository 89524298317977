import React from 'react'
import { useTranslation } from 'react-i18next'

const classes = {
  footer: {
    wrapper: 'Modal-Footer-wrapper',
  },
  button: {
    close: { wrapper: 'Modal-Footer-Button-Close-wrapper' },
    submit: { wrapper: 'Modal-Footer-Button-Submit-wrapper' },
  },
}

export const SigningFooter = React.memo(({ handleFinishClick, handleClose, enablePrimaryButton, isEnabled }) => {
  const { t } = useTranslation()
  return (
    <div className={classes.footer.wrapper}>
      <div
        width={300}
        className={classes.button.close.wrapper}
        onClick={() => handleClose()}
        disabled={!isEnabled}
      >
        {t("close")}
      </div>
      {enablePrimaryButton ? (
        <div
          width={300}
          className={classes.button.submit.wrapper}
          onClick={() => handleFinishClick()}
          disabled={!isEnabled}
        >
        {t("confirm_and_request")}
        </div>
      ) : null}
    </div>
  )
})

