import { Caret } from 'assets/svgIconComponents'
import React, { useMemo } from 'react'
import useStore from '___store'
import QuestionListItem from './QuestionListItem'

const classes = {
  wrapper: 'Wizard-Configuration-Questionnaire-QuestionGroup-wrapper',
  label: 'Wizard-Configuration-Questionnaire-QuestionGroup-label',
  actionsDiv: 'Wizard-Configuration-Questionnaire-QuestionGroup-actionsDiv',
}

export const QuestionGroup = React.memo(props => {
  const { id, type, label, questionIds } = props

  const {
    answering: beingAnswered,
    goToPreviousQuestion,
    goToNextQuestion,
  } = useStore(`selectAnswering[${id}]`, 'goToPreviousQuestion', 'goToNextQuestion')

  const disabled = !beingAnswered

  const render = useMemo(() => {
    if (type === 'loose')
      return questionIds.map((id, i) => {
        // const previousId = questionIds[i - 1] || previousGroupId
        // const nextId = questionIds[i + 1] || nextGroupId
        return (
          <QuestionListItem
            key={id}
            id={id}
            isStandalone
            // previousId={previousId} nextId={nextId}
            scrollToMarker={true}
          />
        )
      })
    return (
      <div id={id} className={classes.wrapper} data-being-answered={beingAnswered}>
        <span className={classes.label}>{label}</span>
        {questionIds.map((id, i) => (
          <QuestionListItem key={id} id={id} scrollToMarker={!i} />
        ))}
        <div className={classes.actionsDiv}>
          <button
            disabled={
              disabled
              //  || isFirstGroup
            }
            type="button"
            onClick={() => {
              if (disabled) return
              goToPreviousQuestion()
            }}
          >
            <Caret className={classes.backButtonIcon} />
          </button>
          <button
            disabled={disabled}
            type="button"
            onClick={() => {
              if (disabled) return
              // answerQuestion({ id, value: calculatedValue })
              goToNextQuestion()
            }}
          >
            Next
          </button>
        </div>
      </div>
    )
  }, [type, questionIds, id, beingAnswered, label, disabled, goToPreviousQuestion, goToNextQuestion])

  // console.log('QUESTION IDS: ', questionIds)

  return render
})

QuestionGroup.displayName = 'Wizard-Questionnaire-QuestionGroup'

export default QuestionGroup
