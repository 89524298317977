import React from 'react'

import { className } from './index'

const Cross = React.memo(() => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <line x1="25" y1="25" x2="75" y2="75" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
    <line x1="25" y1="75" x2="75" y2="25" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
  </svg>
))

export { Cross }
export default Cross
