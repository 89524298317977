import React, { forwardRef, useImperativeHandle, useState } from 'react'
import useStore from '___store'

import { QuestionGroupList } from './components'

const classes = {
  wrapper: 'Wizard-Configuration-QuestionGeneration-wrapper',
  controlsDiv: 'Wizard-Configuration-QuestionGeneration-controlsDiv',
}

export const QuestionGeneration = React.memo(
  forwardRef((_, ref) => {
    const [
      questionId,
      // setQuestionId
    ] = useState()
    const { addNewSeparator, addNewQuestionLayoutGroup, addNewQuestion } = useStore(
      'addNewQuestion',
      'addNewQuestionLayoutGroup',
      'addNewSeparator'
    )

    useImperativeHandle(ref, () => ({ key: 'template-creation', priority: 1 }), [])

    return (
      <div className={classes.wrapper}>
        {questionId ? null : <QuestionGroupList />}
        <div className={classes.controlsDiv}>
          <button type="button" onClick={() => addNewSeparator()}>
            Add separator
          </button>
          <button type="button" onClick={() => addNewQuestionLayoutGroup()}>
            New question group
          </button>
          <button type="button" onClick={() => addNewQuestion()}>
            New question
          </button>
        </div>
      </div>
    )
  })
)

QuestionGeneration.displayName = 'Wizard-QuestionGeneration'

export default QuestionGeneration
