import { v4 as uuid } from 'uuid'

import {
  CASUS_KEYSTRINGS,
  NESTED_STRUCTURE_KEYS,
  ORIENTATION,
  PAPER_NAMES,
  SEGMENT_TYPES,
} from 'TemplateCreation-DocumentGeneration/constants'
import { SEGMENT_TAGS } from 'utilities/parsing'
import { mergeChunks } from 'TemplateCreation-DocumentGeneration/parsing'

const evaluateChoiceMarker = (state, payload = {}) => {
  const { id } = payload
  const [marker, index, parentId, markerArray] = findLocation(state, id)
  if (!parentId) return state
  const { defaultKeep, keep, questionIds = [], ruleSet = {} } = marker
  if (!questionIds.length) return state
  const testFunction = questionId => {
    const [answer, aIndex] = getStateAnswer(state, questionId)
    if (aIndex === -1) return
    const { value } = answer
    const [question, qIndex] = getStateQuestion(state, questionId)
    if (qIndex === -1) return
    const { options = [] } = question
    const relevantOptions = options.filter(option => option?.markers?.includes(id)).map(({ id }) => id)
    const selectedOptions = typeof value === 'string' ? [value] : value
    const test = optionId => selectedOptions.includes(optionId)
    const result = ruleSet[questionId] === 'all' ? relevantOptions.every(test) : relevantOptions.some(test)
    return result
  }
  const updatedKeep = defaultKeep ? questionIds.some(testFunction) : questionIds.every(testFunction)
  if (keep === updatedKeep) return state
  markerArray.splice(index, 1, Object.assign({}, marker, { keep: updatedKeep }))
  return { ...state }
}

const parseValue = val =>
  // eslint-disable-next-line no-useless-escape
  [...val.matchAll(new RegExp(`(^${CASUS_KEYSTRINGS.userInput}:)([a-zA-Z0-9\-]+):(.*)`, 'g'))][0]

const parseValueArray = (array, question) =>
  array.map(value => {
    const parsed = parseValue(value)
    if (parsed) return parsed[3]
    const [option, oIndex] = getQuestionOption(question, value)
    if (oIndex === -1) return value
    return option.value
  })

const evaluateReplacementMarker = (state, payload = {}) => {
  const { id } = payload
  const [marker, mIndex, parentId, markerArray] = findLocation(state, id)
  if (!parentId) return state
  const { questionId, applyModifiers = baseValue => baseValue } = marker
  if (!questionId) return state
  const [question, qIndex] = getStateQuestion(state, questionId)
  if (qIndex === -1) return state
  const { select, separator = '; ' } = question
  const [answer, aIndex] = getStateAnswer(state, questionId)
  if (aIndex === -1) return state
  const { value } = answer
  const valueArray = typeof value === 'string' ? [value] : value
  const parsedValueArray = parseValueArray(valueArray, question).map(applyModifiers)
  const resultingMarker = Object.assign({}, marker)
  if (select === 'multi') {
    const stringValue = parsedValueArray.join(separator)
    Object.assign(resultingMarker, { value: stringValue })
  } else Object.assign(resultingMarker, { value: parsedValueArray[0] })
  markerArray.splice(mIndex, 1, resultingMarker)
  return { ...state }
}

const getGenericPage = (start = 0) => [start, -Infinity]

const getGenericSection = () => ({
  id: 'generic-section-id',
  // id: uuid(),
  title: 'Generic Section Title',
  layout: {
    orientation: ORIENTATION.vertical,
    // orientation: ORIENTATION.horizontal,
    paper: PAPER_NAMES.A4,
    // paper: PAPER_NAMES.A3,
    margins: { top: 1.25, left: 1, bottom: 0.75, right: 1 },
  },
  pages: [],
})

const extractSections = (dataStructure = {}) => {
  const { segments = [] } = dataStructure
  const { sections, pages } = segments.reduce(
    (acc, cur, i) => {
      const { sections, pages } = acc
      const { break: segmentBreak } = cur
      const lastPage = pages[pages.length - 1]
      lastPage[1] = i + 1
      if (segmentBreak) {
        const { type } = segmentBreak
        if (type === 'section') {
          const { id, title, layout } = segmentBreak
          const lastSection = sections[sections.length - 1]
          Object.entries({ id, title, layout, pages: pages.slice() }).reduce(
            (accumulated, [key, value]) => (value ? Object.assign(accumulated, { [key]: value }) : accumulated),
            lastSection
          )
          sections.push(getGenericSection())
          pages.length = 0
          pages.push(getGenericPage(i + 1))
        } else if (type === 'page') pages.push(getGenericPage(i + 1))
      }
      return acc
    },
    { sections: [getGenericSection()], pages: [getGenericPage()] }
  )
  const lastPage = pages[pages.length - 1]
  const lastSection = sections[sections.length - 1]
  if (lastPage[1] <= lastPage[0]) pages.pop()
  if (!pages.length) sections.pop()
  else Object.assign(lastSection, { pages })
  return sections
}

const initializeWizard = (state, payload = {}) => {
  const initializedState = { ...state, ...payload, sections: extractSections(payload.dataStructure) }
  const { locations = {} } = initializedState
  const { choice = {}, replacement = {} } = locations
  const choiceMarkers = Object.values(choice).reduce(
    (acc, markerArray) => [...acc, ...markerArray.map(({ id }) => id)],
    []
  )
  const replacementMarkers = Object.values(replacement).reduce(
    (acc, markerArray) => [...acc, ...markerArray.map(({ id }) => id)],
    []
  )
  const choiceEvaluatedState = choiceMarkers.reduce(
    (acc, cmId) => evaluateChoiceMarker(acc, { id: cmId }),
    initializedState
  )
  const replacementEvaluatedState = replacementMarkers.reduce(
    (acc, rmId) => evaluateReplacementMarker(acc, { id: rmId }),
    choiceEvaluatedState
  )
  return replacementEvaluatedState
}

const getStructure = (structure, id, res = {}) => {
  if (structure.id === id) Object.assign(res, structure)
  else
    NESTED_STRUCTURE_KEYS.every(
      key =>
        !(structure[key] && structure[key].length) ||
        structure[key].every(s => !Object.keys(getStructure(s, id, res)[0]).length)
    )
  return [res, Object.keys(res).find(k => NESTED_STRUCTURE_KEYS.includes(k))]
}

const getMarkerParentId = (locations = { choice: {}, replacement: {} }, givenId) => {
  const [parentId] =
    Object.entries(locations.choice)?.find(([_, array]) => array?.find(({ id }) => id === givenId)) || []
  return (parentId && getMarkerParentId(locations, parentId)) || givenId
}

const isEncompassing = (acc, range) =>
  acc.find(present => {
    const point = Math.sign(present.range[0] + present.range[1] - (range[0] + range[1]) + 0.5)
    const index = Math.abs(Math.ceil(point * -0.5))
    const encompasses = Math.sign(range[index] - present.range[index] + point) === point
    return encompasses
  })

const addLocation = (state, payload = {}) => {
  const { dataStructure = {}, locations = { choice: {}, replacement: {} } } = state
  const { type, id: parentId, parentType, locationId = uuid(), indexRange, range } = payload
  if (!parentId) return state
  if (!['choice', 'replacement'].includes(type)) return state
  const relevantLocationArray = locations[type][parentId] || []
  const resultingMarker = { id: locationId, range, type }
  let resultingLocations = [...relevantLocationArray, resultingMarker]
  const [structure, key = ''] =
    parentType === SEGMENT_TYPES.container
      ? [dataStructure, 'segments']
      : getStructure(dataStructure, type === 'choice' ? getMarkerParentId(locations, parentId) : parentId)
  const structureArray = structure[key] || dataStructure?.segments || []
  if (type === 'choice') {
    const indexedRange =
      indexRange ||
      range
        ?.map((segmentId, i) => structureArray.findIndex(({ id }) => segmentId === id) + i)
        .filter(index => (index || index === 0) && index !== -1)
    if (indexedRange.length !== 2) return state
    Object.assign(resultingMarker, { range: indexedRange, defaultKeep: true, questionIds: [], ruleSet: {} })
  } else {
    const contentText = structureArray.reduce((acc, { text = '' }) => acc.concat(text), '').slice(...range)
    if (!contentText.length) return state
    Object.assign(resultingMarker, { contentText, questionId: null })
  }
  resultingLocations.sort(({ range: ar }, { range: br }) => br[1] - br[0] - (ar[1] - ar[0]))
  const { res, buffer } = resultingLocations.reduce(
    (acc, cur) => {
      const { res, buffer } = acc
      const { range: nextRange } = cur
      const encompassing = isEncompassing(res, nextRange)
      if (encompassing) {
        const bufferMarker = { ...cur }
        if (type === 'choice') Object.assign(bufferMarker, { indexRange: nextRange })
        Object.assign(buffer, { [encompassing.id]: [...(buffer[encompassing.id] || []), bufferMarker] })
      } else res.push(cur)
      return acc
    },
    { res: [], buffer: {} }
  )
  Object.entries(buffer).forEach(([id, array]) => {
    array.forEach(m =>
      type === 'choice'
        ? addLocation(state, Object.assign(m, { type, id, locationId: m.id }))
        : m.questionId && unassignMarker(state, { questionId: m.questionId, markerId: m.id })
    )
    if (type === 'replacement')
      array.length === 1 &&
        Object.assign(
          res.find(m => m.id === id),
          { label: array[0].label }
        )
  })
  resultingLocations = res
  resultingLocations.sort(({ range: [a] }, { range: [b] }) => a - b)
  Object.assign(locations[type], { [parentId]: resultingLocations })
  return { ...state }
}

// const getStateQuestion = (state = {}, id = '') =>
// state.questions?.reduce((acc, cur, i) => (cur.id === id ? [cur, i] : acc), [null, -1]) || [null, -1]  //

const getStateQuestion = (state = {}, id = '', index = -1) => [
  state.questions?.find((q, i) => q.id === id && ((index = i) || true)) || null,
  index,
]

const getQuestionOption = (question = {}, id = '') =>
  question?.options?.reduce((acc, cur, i) => (cur.id === id ? [cur, i] : acc), [null, -1]) || [null, -1]

const getStateAnswer = (state = {}, id = '') =>
  state.answers?.reduce((acc, cur, i) => (cur.id === id ? [cur, i] : acc), [null, -1]) || [null, -1]

const findLocation = (state, markerId) => {
  let parentId = null
  let markerArray = null
  let index = -1
  let marker = null
  Object.entries(Object.assign({}, state.locations.choice, state.locations.replacement)).some(([id, array]) =>
    array.some((m, i) => (m.id === markerId ? ((marker = m) && (index = i)) || true : false))
      ? (parentId = id) && (markerArray = array)
      : false
  )
  return [marker, index, parentId, markerArray]
}

const assignMarker = (state, payload) => {
  const { markerId, type, optionId } = payload
  const configuring = state.configuring.length && state.configuring[state.configuring.length - 1]
  const configuringQuestionId = configuring?.key === 'question' && configuring.id
  const questionId = payload.questionId || configuringQuestionId
  if (!questionId) return state
  const [question, qIndex] = getStateQuestion(state, questionId)
  if (qIndex === -1) return state
  const { options = [], markers = [], select } = question
  const [marker, mIndex, parentId, markerArray] = findLocation(state, markerId)
  if (!parentId) return state
  if (type === 'replacement') {
    markerArray.splice(mIndex, 1, Object.assign({}, marker, { questionId }))
  } else {
    const { questionIds = [], ruleSet = {} } = marker
    const updatedQuestionIds = Array.from(new Set([...questionIds, questionId])).filter(id =>
      state.questions.some(q => q.id === id)
    )
    const updatedRuleSet = Object.entries(ruleSet).reduce(
      (acc, [id, rule]) => (state.questions.some(q => q.id === id) ? Object.assign(acc, { [id]: rule }) : acc),
      { [questionId]: select === 'multi' ? 'all' : 'any' }
    )
    const updatedMarker = Object.assign({}, marker, { questionIds: updatedQuestionIds, ruleSet: updatedRuleSet })
    markerArray.splice(mIndex, 1, updatedMarker)
    if (optionId) {
      const [option, optionIndex] = options.reduce((acc, cur, i) => (cur.id === optionId ? [cur, i] : acc), []) || []
      if (optionIndex !== -1) {
        const resultingOptionMarkers = Array.from(new Set([...(option.markers || []), markerId]))
        if (resultingOptionMarkers.length === option.markers?.length) return state
        options.splice(optionIndex, 1, Object.assign({}, option, { markers: resultingOptionMarkers }))
      }
    }
  }
  const resultingMarkers = Array.from(new Set([...(markers || []), markerId]))
  if (resultingMarkers.length === markers.length) return state
  state.questions.splice(qIndex, 1, Object.assign({}, question, { markers: resultingMarkers }))
  return { ...state }
}

const unassignMarker = (state, payload) => {
  const { markerId, questionId, optionId } = payload
  const [question, index = -1] = getStateQuestion(state, questionId)
  if (index === -1) return state
  const [marker, mIndex, parentId, markerArray] = findLocation(state, markerId)
  if (!parentId) return state
  const resultingMarkers = question.markers?.filter(id => id !== markerId) || []
  if (resultingMarkers.length === question.markers?.length) return state
  const { type } = marker
  if (optionId) {
    const option = question.options?.find(o => o.id === optionId)
    if (!option) return state
    const resultingMarkers = option.markers?.filter(id => id !== markerId) || []
    if (resultingMarkers.length === option.markers?.length) return state
    const resultingOption = Object.assign({}, option, { markers: resultingMarkers })
    const resultingOptions = question.options?.map(o => (o.id === resultingOption.id ? resultingOption : o)) || []
    state.questions.splice(index, 1, Object.assign({}, question, { options: resultingOptions }))
    return { ...state }
  }
  if (type === 'replacement') markerArray.splice(mIndex, 1, Object.assign({}, marker, { questionId: null }))
  else {
    const { questionIds = [] } = marker
    const updatedMarker = Object.assign({}, marker, { questionIds: questionIds.filter(id => id !== questionId) })
    markerArray.splice(mIndex, 1, updatedMarker)
    const optionsWithMarkerIndices =
      question.options?.reduce((acc, { markers }, i) => {
        if (markers?.includes(markerId)) acc.push(i)
        return acc
      }, []) || []
    optionsWithMarkerIndices.forEach(i => {
      const relevantOption = question.options[i]
      const resultingOptionMarkers = relevantOption.markers?.filter(id => id !== markerId) || []
      question.options.splice(i, 1, Object.assign({}, relevantOption, { markers: resultingOptionMarkers }))
    })
  }
  state.questions.splice(index, 1, Object.assign({}, question, { markers: resultingMarkers }))
  return { ...state }
}

const removeLocation = (state, payload = {}) => {
  const { id } = payload
  const [marker, index, parentId, markerArray] = findLocation(state, id)
  if (index === -1) return state
  const { type } = marker
  const resultingMarkers = markerArray.filter(m => m.id !== id)
  if (resultingMarkers.length === markerArray.length) return state
  if (type === 'choice') {
    const childMarkers = state.locations[type][id]
    if (childMarkers?.length) {
      resultingMarkers.push(...childMarkers)
      const rl = Object.entries(state.locations[type]).reduce(
        (acc, [p, m]) => (p === id || m.length === 0 ? acc : Object.assign(acc, { [p]: m })),
        {}
      )
      Object.assign(state.locations, {
        [type]: rl,
      })
    }
  }
  const returnState = state.questions?.reduce(
    (acc, cur) => {
      if (cur.markers?.includes(payload.id)) return unassignMarker(acc, { questionId: cur.id, markerId: payload.id })
      return acc
    },
    { ...state }
  )
  Object.assign(returnState.locations[type], { [parentId]: resultingMarkers })
  return returnState
}

const filterLocations = (filterRange = [], locations = []) =>
  locations
    .slice()
    .map(({ id, range }) => {
      const [filterStart, filterEnd] = filterRange
      const [locationStart, locationEnd] = range
      const originalMarkerLength = locationEnd - locationStart
      const markerLength = Math.max(Math.min(filterEnd, locationEnd) - Math.max(filterStart, locationStart), 0)
      if (!markerLength) return null
      const markerRangeStart = Math.max(filterStart - locationStart, 0)
      const markerRangeEnd = markerRangeStart + markerLength
      const markerRange = [markerRangeStart, markerRangeEnd]
      const containerLength = filterEnd - filterStart
      const containerRangeStart = Math.max(locationStart - filterStart, 0)
      const containerRangeEnd = Math.min(locationEnd - filterStart, containerLength)
      const containerRange = [containerRangeStart, containerRangeEnd]
      const start = locationStart >= filterStart
      const end = locationEnd <= filterEnd
      return { id, markerRange, containerRange, originalMarkerLength, start, end }
    })
    .filter(l => l)

const applyToConfiguringStack = (configuring = [], payload) => {
  const stackLength = configuring.length
  const lastConfiguration = configuring[stackLength - 1] || {}
  if (lastConfiguration.key === payload.key) {
    configuring.pop()
  }
  configuring.push(payload)
  return [...configuring]
}

const answerQuestion = (state, payload = {}) => {
  const { id, value } = payload
  const [answer, index] = getStateAnswer(state, id)
  if (index === -1) state.answers.push({ id, value })
  else state.answers.splice(index, 1, Object.assign({}, answer, payload))
  return { ...state }
}

const applyMarkersToSegments = (state = {}, segments = [], markers = []) =>
  markers
    .reduce((acc, marker) => {
      const { id, defaultKeep, questionIds = [], ruleSet = {}, range = [] } = marker
      if (!questionIds.length) return acc
      const testFunction = questionId => {
        const [answer, aIndex] = getStateAnswer(state, questionId)
        if (aIndex === -1) return
        const [question, qIndex] = getStateQuestion(state, questionId)
        if (qIndex === -1) return
        const { value } = answer
        const { options = [] } = question
        const relevantOptions = options.filter(option => option?.markers?.includes(id)).map(({ id }) => id)
        const selectedOptions = typeof value === 'string' ? [value] : value
        const test = optionId => selectedOptions.includes(optionId)
        const result = ruleSet[questionId] === 'all' ? relevantOptions.every(test) : relevantOptions.some(test)
        return result
      }
      const updatedKeep = defaultKeep ? questionIds.some(testFunction) : questionIds.every(testFunction)
      const start = range[0]
      const deleteCount = range[1] - start
      if (!updatedKeep && deleteCount) acc.splice(start, deleteCount, ...Array(deleteCount).fill(null))
      return acc
    }, segments)
    .filter(s => s)

const applyValueToMarkerChunk = (state = {}, chunk = {}, replacementMarkers = []) => {
  const { id: markerId, tag, textChunks = [] } = chunk
  if (tag !== SEGMENT_TAGS[SEGMENT_TYPES.mark]) return chunk
  const marker = replacementMarkers.find(({ id }) => id === markerId)
  if (!marker) return chunk
  const { questionId, applyModifiers = baseValue => baseValue } = marker
  if (!questionId) return chunk
  const [question, qIndex] = getStateQuestion(state, questionId)
  if (qIndex === -1) return chunk
  const { select, separator = '; ' } = question
  const [answer, aIndex] = getStateAnswer(state, questionId)
  if (aIndex === -1) return chunk
  const { value } = answer
  const valueArray = typeof value === 'string' ? [value] : value
  const parsedValueArray = parseValueArray(valueArray, question).map(applyModifiers)
  const customStyle = (textChunks.length === 1 && textChunks[0].customStyle) || ''
  const styles = textChunks.reduce((acc, { styles: chunkStyles = [] }) => [...acc, ...chunkStyles], [])
  const resultingChunk = { type: SEGMENT_TYPES.chunk, tag: SEGMENT_TAGS[SEGMENT_TYPES.chunk], customStyle, styles }
  if (select === 'multi') {
    const stringValue = parsedValueArray.join(separator)
    Object.assign(resultingChunk, { text: stringValue })
  } else Object.assign(resultingChunk, { text: parsedValueArray[0] })
  return resultingChunk
}

const applyMarkersToChunks = (textChunks = [], markers = []) =>
  markers.reduce(
    (result, marker) =>
      result.reduce(
        (iteratedChunks, chunk) => {
          const { id, range } = marker
          const [mStart, mEnd] = range
          const { chunks, length } = iteratedChunks
          const { tag, text = '', textChunks: markerTextChunks = [] } = chunk
          const textLength =
            tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]
              ? markerTextChunks.reduce((sum, { text: markerChunkText }) => sum + markerChunkText.length, 0)
              : text.length
          const cStart = length
          const cEnd = cStart + textLength
          if (mStart >= cEnd || mEnd <= cStart) {
            chunks.push(chunk)
            iteratedChunks.length += textLength
            return iteratedChunks
          }
          const relativeStart = mStart - cStart
          const relativeEnd = mEnd - cStart
          const pre = text.slice(0, Math.max(relativeStart, 0))
          const inside = text.slice(Math.max(relativeStart, 0), Math.min(relativeEnd, textLength))
          const post = text.slice(Math.min(relativeEnd, textLength), textLength)
          const resultingChunks = []
          const lastChunk = chunks[chunks.length - 1]
          if (lastChunk && lastChunk.tag === SEGMENT_TAGS[SEGMENT_TYPES.mark] && lastChunk.id === id)
            lastChunk.textChunks.push(Object.assign({}, chunk, { text: inside }))
          else
            resultingChunks.push({
              tag: SEGMENT_TAGS[SEGMENT_TYPES.mark],
              id,
              textChunks: [Object.assign({}, chunk, { text: inside })],
            })
          if (pre.length) resultingChunks.unshift(Object.assign({}, chunk, { text: pre }))
          if (post.length) resultingChunks.push(Object.assign({}, chunk, { text: post }))
          chunks.push(...resultingChunks)
          iteratedChunks.length += textLength
          return iteratedChunks
        },
        { chunks: [], length: 0 }
      ).chunks,
    textChunks
  )

const retainKeys = ['id', 'type', 'tag', 'customStyle', 'styles', 'break', 'text']

const generateDataStructure = (
  structure = {},
  locations = { choice: {}, replacement: {} },
  questions = [],
  answers = []
) => {
  const { textChunks } = structure
  const stripped = retainKeys.reduce(
    (acc, cur) => (structure[cur] ? Object.assign(acc, { [cur]: structure[cur] }) : acc),
    {}
  )
  const { id = 'root' } = stripped
  const isParagraph = stripped.type === SEGMENT_TYPES.paragraph
  const replacementMarkers = locations.replacement[id]
  if (isParagraph && textChunks?.length && replacementMarkers?.length)
    Object.assign(stripped, {
      textChunks: mergeChunks(
        applyMarkersToChunks(textChunks, replacementMarkers).map(chunk =>
          applyValueToMarkerChunk({ questions, answers }, chunk, replacementMarkers)
        )
      ),
    })
  const hasSegmentsContent = stripped.type === SEGMENT_TYPES.container || stripped.type === SEGMENT_TYPES.tableData
  const key = ['segments', 'content'].find(k => Object.keys(structure).includes(k))
  const choiceMarkers = locations.choice[id]
  if (hasSegmentsContent && key && structure[key] && structure[key].length && choiceMarkers?.length)
    Object.assign(stripped, { [key]: applyMarkersToSegments({ questions, answers }, structure[key], choiceMarkers) })
  const result = NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    const current = acc[key] || structure[key]
    if (current) {
      if (Array.isArray(current))
        Object.assign(acc, {
          [key]: current.slice().map(s => generateDataStructure(s, locations, questions, answers)),
        })
      else Object.assign(acc, { [key]: structure[key] })
    }
    return acc
  }, stripped)
  return result
}

// const addNewSeparator = state => {
//   const { questionLayout } = state
//   const newQuestionLayout = questionLayout.slice()
//   newQuestionLayout.push({ id: uuid(), type: 'separator', label: 'New separator' })
//   return Object.assign({}, state, { questionLayout: newQuestionLayout })
// }

// const addNewQuestionLayoutGroup = state => {
//   const { id = uuid(), questionLayout } = state
//   const newQuestionLayout = questionLayout.concat({ id, type: 'group', label: 'New question group', questionIds: [] })
//   return Object.assign({}, state, { questionLayout: newQuestionLayout })
// }

const getStateQuestionLayoutGroup = (state, id = '', index = -1) => [
  state.questionLayout?.find((qlg, i) => qlg.id === id && ((index = i) || true)) || null,
  index,
]

// // state.questionLayout?.reduce((acc, cur, i) => (cur.id === id ? [cur, i] : acc), [null, -1]) || [null, -1]

// const updateQuestionLayoutGroup = (state, payload = {}) => {
//   const { id } = payload
//   const [questionLayoutGroup, index] = getStateQuestionLayoutGroup(state, id)
//   if (index === -1) return state
//   state.questionLayout.splice(index, 1, Object.assign({}, questionLayoutGroup, payload))
//   return { ...state }
// }

// const removeEmptyLooseGroups = state => {
//   const { questionLayout } = state
//   const newQuestionLayout = questionLayout.filter(({ type, questionIds }) => type !== 'loose' || questionIds.length)
//   if (questionLayout.length === newQuestionLayout.length) return [state, false]
//   return [Object.assign(state, { questionLayout: newQuestionLayout }), false]
// }

// const mergeQuestionLayoutGroups = questionLayout =>
//   questionLayout.reduce(
//     (acc, cur) => {
//       const layoutArray = acc[0]
//       const groupCount = layoutArray.length
//       const { type, questionIds = [] } = cur
//       if (type !== 'loose' || !groupCount) return [layoutArray.concat(cur), false]
//       const previousLayoutGroup = layoutArray[groupCount - 1]
//       const { type: previousGroupType } = previousLayoutGroup
//       if (previousGroupType !== 'loose') return [layoutArray.concat(cur), false]
//       const resultingQuestionIds = previousLayoutGroup.questionIds?.concat(questionIds) || questionIds
//       return [layoutArray.concat(Object.assign({}, layoutArray.pop(), { questionIds: resultingQuestionIds })), true]
//     },
//     [[], false]
//   )

// const unpackQuestionLayoutGroup = (state, payload = {}) => {
//   const { id } = payload
//   const [questionLayoutGroup, index] = getStateQuestionLayoutGroup(state, id)
//   if (index === -1) return state
//   const { questionIds = [] } = questionLayoutGroup
//   const newLayoutGroup = { id: uuid(), type: 'loose', label: 'loose', questionIds }
//   const newQuestionLayout = state.questionLayout
//     .slice(0, index)
//     .concat(newLayoutGroup)
//     .concat(state.questionLayout.slice(index + 1))
//   return Object.assign({}, state, { questionLayout: mergeQuestionLayoutGroups(newQuestionLayout)[0] })
// }

// const removeQuestionLayoutGroup = (state, payload = {}) => {
//   const { id } = payload
//   const index = getStateQuestionLayoutGroup(state, id)[1]
//   if (index === -1) return state
//   const newQuestionLayout = state.questionLayout.filter(qlg => qlg.id !== id)
//   if (state.questionLayout.length === newQuestionLayout.length) return state
//   return Object.assign({}, state, { questionLayout: mergeQuestionLayoutGroups(newQuestionLayout)[0] })
// }

// const addNewQuestion = (state, payload = {}) => {
//   const { id = uuid(), questionLayoutGroupId } = payload
//   const resultingQuestions = state.questions.concat({ id })
//   let questionLayoutGroup = null
//   let index = -1
//   if (questionLayoutGroupId) {
//     const [foundGroup, foundIndex] = getStateQuestionLayoutGroup(state, questionLayoutGroupId)
//     questionLayoutGroup = foundGroup
//     index = foundIndex
//   }
//   if (index === -1) {
//     const groupCount = state.questionLayout.length
//     const lastGroupIndex = groupCount - 1
//     const lastGroup = state.questionLayout[lastGroupIndex]
//     const lastGroupType = lastGroup?.type
//     if (lastGroupType === 'loose') {
//       questionLayoutGroup = lastGroup
//       index = lastGroupIndex
//     } else {
//       const newLayoutGroup = { id: uuid(), type: 'loose', label: 'loose', questionIds: [] }
//       const newQuestionLayout = state.questionLayout.concat(newLayoutGroup)
//       Object.assign({}, state, { questionLayout: newQuestionLayout })
//       questionLayoutGroup = newLayoutGroup
//       index = groupCount
//     }
//   }
//   const resultingQuestionIds = questionLayoutGroup.questionIds?.concat(id) || [id]
//   state.questionLayout.splice(index, 1, Object.assign({}, questionLayoutGroup, { questionIds: resultingQuestionIds }))
//   return Object.assign({}, state, { questions: resultingQuestions })
// }

// const updateQuestion = (state, payload = {}) => {
//   const { id } = payload
//   const [question, index] = getStateQuestion(state, id)
//   if (index === -1) return state
//   const newQuestionObject = Object.assign({}, question, payload)
//   if (Object.keys(payload).includes('valueType') && question.options?.length)
//     Object.assign(newQuestionObject, {
//       options: question.options.map(o =>
//         o.valueType === payload.valueType ? o : Object.assign({}, o, { type: payload.valueType })
//       ),
//     })
//   state.questions.splice(index, 1, newQuestionObject)
//   return { ...state }
// }

// const addQuestionToLayoutGroup = (state, id, questionLayoutGroupId) => {
//   const [questionLayoutGroup, index] = getStateQuestionLayoutGroup(state, questionLayoutGroupId)
//   if (index === -1) return [state, false]
//   const resultingQuestionIds = Array.from(new Set((questionLayoutGroup.questionIds?.slice() || []).concat(id)))
//   if (questionLayoutGroup.questionIds?.length === resultingQuestionIds.length) return [state, false]
//   const resultingLayoutGroup = Object.assign({}, questionLayoutGroup, { questionIds: resultingQuestionIds })
//   return [state, Boolean(state.questionLayout.splice(index, 1, resultingLayoutGroup))]
// }

// const getStateQuestionLayoutGroupByQuestionId = (state, id = '', index = -1) => [
//   state.questionLayout?.find((qlg, i) => qlg.questionIds?.includes(id) && ((index = i) || true)) || null,
//   index,
// ]

// const removeQuestionFromItsLayoutGroup = (state, id) => {
//   const [questionLayoutGroup, index] = getStateQuestionLayoutGroupByQuestionId(state, id)
//   if (index === -1) return [state, false]
//   const resultingQuestionIds = questionLayoutGroup.questionIds?.filter(qid => qid !== id) || []
//   if (questionLayoutGroup.questionIds?.length === resultingQuestionIds.length) return [state, false]
//   const resultingLayoutGroup = Object.assign({}, questionLayoutGroup, { questionIds: resultingQuestionIds })
//   return [state, Boolean(state.questionLayout.splice(index, 1, resultingLayoutGroup))]
// }

// const assignQuestion = (state, payload = {}) => {
//   const didRemove = removeQuestionFromItsLayoutGroup(state, payload.id)[1]
//   const didAdd = addQuestionToLayoutGroup(state, payload.id, payload.questionLayoutGroupId)[1]
//   return didRemove || didAdd ? Object.assign({}, state) : state
// }

// const removeQuestion = (state, payload = {}) => {
//   const { id } = payload
//   const index = getStateQuestion(state, id)[1]
//   if (index === -1) return state
//   const resultingQuestions = state.questions.filter(q => q.id !== id)
//   if (state.questions.length === resultingQuestions.length) return state
//   return Object.assign({}, removeQuestionFromItsLayoutGroup(state, id)[0], { questions: resultingQuestions })
// }

// const generatePage = (range = [0, +Infinity], id = uuid(), index = 0) => ({ id, index, range })

// const generateSection = (
//   range = [0, +Infinity],
//   id = uuid(),
//   index = 0,
//   title = 'Generic Section Title',
//   pages = [generatePage()],
//   layout = {
//     orientation: 'landscape',
//     paper: 'a5',
//     margins: { top: 0.5, left: 0.5, bottom: 0.5, right: 0.5 },
//   }
// ) => ({
//   id,
//   index,
//   title,
//   range,
//   layout,
// })

// const getSections = (dataStructure = {}) =>
//   dataStructure.segments?.reduce(
//     (acc, cur, i) => {
//       const { sections, pages } = acc
//       const sBreak = cur.break
//       if (!sBreak) return acc
//       if (sBreak?.type === 'page') {
//         const lastPageIndex = pages.length - 1
//         pages[lastPageIndex].range[1] = i
//         pages.push(generatePage([i + 1, +Infinity]))
//       }
//       if (sBreak?.type !== 'section') return acc
//       const lastIndex = acc.length - 1
//       acc[lastIndex] = generateSection(
//         sBreak.id,
//         lastIndex,
//         sBreak.title,
//         [acc[lastIndex - 1]?.range[1] || 0, i + 1],
//         sBreak.layout
//       )
//       acc.push(generateSection())
//       return acc
//     },
//     { sections: [generateSection()], pages: [generatePage()] }
//   ) || []

// const getPages = (segments = []) => {}

// const generateSegment = (segmentType = SEGMENT_TYPES.paragraph, id = '') => {
//   switch (segmentType) {
//     case SEGMENT_TYPES.paragraph:
//       return {
//         id: id || uuid(),
//         type: SEGMENT_TYPES.paragraph,
//         tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
//         styleName: '',
//         styles: [],
//         textChunks: [],
//       }
//     case SEGMENT_TYPES.table:
//       return {
//         id: id || uuid(),
//         type: SEGMENT_TYPES.table,
//         tag: SEGMENT_TAGS[SEGMENT_TYPES.table],
//         styleName: '',
//         styles: [],
//         tableHeader: [],
//         tableBody: [],
//         tableFooter: [],
//       }
//     default:
//       return null
//   }
// }

// const insertSegmentAtIndex = (structure = {}, parentId = '', inside = false, index = 0, segment = {}) => {
//   if (!parentId)
//     return Object.assign(structure, {
//       segments: [...structure.segments.slice(0, index), segment, ...structure.segments.slice(index)],
//     })
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (!(structure[key] && structure[key].length)) return true
//     if (structure.id === parentId)
//       return !Object.assign(structure, {
//         [key]: [...structure[key].slice(0, index), segment, ...structure[key].slice(index)],
//       })
//     return structure[key].every(s => insertSegmentAtIndex(s, parentId, inside, index, segment))
//   })
//   return structure
// }

// const removeSegmentAtIndex = (structure = {}, parentId = '', index = 0) => {
//   if (!parentId)
//     return Object.assign(structure, {
//       segments: [...structure.segments.slice(0, index), ...structure.segments.slice(index + 1)],
//     })
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (!(structure[key] && structure[key].length)) return true
//     if (structure.id === parentId)
//       return !Object.assign(structure, {
//         [key]: [...structure[key].slice(0, index), ...structure[key].slice(index + 1)],
//       })
//     return structure[key].every(s => removeSegmentAtIndex(s, parentId, index))
//   })
//   return structure
// }

// const removeSegment = (structure = {}, id = '') => {
//   let innerDone = false
//   const updated = !NESTED_STRUCTURE_KEYS.every(key => {
//     if (innerDone || !(structure[key] && structure[key].length)) return true
//     const index = structure[key].findIndex(({ id: segmentId }) => segmentId === id)
//     if (index === -1) {
//       structure[key] = structure[key].map(s => {
//         if (innerDone) return s
//         const innerSegment = removeSegment(s, id)
//         innerDone = innerDone || s !== innerSegment
//         return innerSegment
//       })
//       return true
//     } else return !structure[key].splice(index, 1)
//   })
//   return updated ? { ...structure } : structure
// }

// const applyCustomStyle = (structure = {}, id = '', customStyle = '') => {
//   if (structure.id === id) return { ...structure, customStyle }
//   let innerDone = false
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (innerDone || !(structure[key] && structure[key].length)) return true
//     structure[key] = structure[key].map(s => {
//       if (innerDone) return s
//       const innerSegment = applyCustomStyle(s, id, customStyle)
//       innerDone = innerDone || s !== innerSegment
//       return innerSegment
//     })
//     return !innerDone
//   })
//   return structure
// }

// const updateSegmentLabel = (structure = {}, id = '', label = '') => {
//   if (structure.id === id) return { ...structure, label }
//   let innerDone = false
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (innerDone || !(structure[key] && structure[key].length)) return true
//     structure[key] = structure[key].map(s => {
//       if (innerDone) return s
//       const innerSegment = updateSegmentLabel(s, id, label)
//       innerDone = innerDone || s !== innerSegment
//       return innerSegment
//     })
//     return !innerDone
//   })
//   return structure
// }

// const replaceParagraphContent = (structure = {}, id = '', text = '') => {
//   if (structure.id === id) return { ...structure, textChunks: [{ text, styles: [] }] }
//   let innerDone = false
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (innerDone || !(structure[key] && structure[key].length)) return true
//     structure[key] = structure[key].map(s => {
//       if (innerDone) return s
//       const innerSegment = replaceParagraphContent(s, id, text)
//       innerDone = innerDone || s !== innerSegment
//       return innerSegment
//     })
//     return !innerDone
//   })
//   return structure
// }

// const insertSegmentAbove = (structure = {}, id = '', segment) => {
//   let innerDone = false
//   const updated = !NESTED_STRUCTURE_KEYS.every(key => {
//     if (innerDone || !(structure[key] && structure[key].length)) return true
//     const index = structure[key].findIndex(({ id: segmentId }) => segmentId === id)
//     if (index === -1) {
//       structure[key] = structure[key].map(s => {
//         if (innerDone) return s
//         const innerSegment = insertSegmentAbove(s, id, segment)
//         innerDone = innerDone || s !== innerSegment
//         return innerSegment
//       })
//       return true
//     } else return !(structure[key] = [...structure[key].slice(0, index), segment, ...structure[key].slice(index)])
//   })
//   return updated ? { ...structure } : structure
// }

// const pageIdsSelectorFunction = (state, sectionIndex = 0, pageIndex = 0) => {
//   const sectionInfo = (state.wizard.sections || [])[Number(sectionIndex)] || {}
//   const rangeWithinSection = (sectionInfo?.pages || [])[Number(pageIndex)]
//   const range = ((state.wizard.sections || [])[Number(sectionIndex)]?.pages || [])[Number(pageIndex)] || [0, 0]
//   const pageSegments = state.wizard.dataStructure?.segments?.slice(...range)?.map(({ id }) => id) || []
//   const sectionLocations = (state.wizard.locations.choice || {})[sectionInfo.id]
//   const locations = filterLocations(rangeWithinSection, sectionLocations)
//   locations.forEach(({ id, markerRange, containerRange, start, end }) => {
//     const pageStart = containerRange[0]
//     const length = containerRange[1] - pageStart
//     pageSegments.splice(
//       pageStart,
//       length,
//       ...new Array(length).fill(
//         `${id}[${markerRange[0]},${markerRange[1]}]${[start && 's', end && 'e'].filter(a => a).join('')}`
//       )
//     )
//   })
//   return pageSegments?.join('; ') || ''
// }

// const markerIdsSelectorFunction = (state, id = '', rangeString = '') => {
//   const allLocations = Object.values(state.wizard.locations.choice || {}).reduce((acc, cur) => acc.concat(cur), [])
//   const location = allLocations.find(l => l.id === id)
//   const insideRange = rangeString.split('-').map(s => Number(s))
//   const { range: rangeWithinSection } = location
//   const [start, end] = rangeWithinSection
//   const range = [Math.min(end, start + insideRange[0]), Math.min(end, start + insideRange[1])]
//   const markerSegments = state.wizard.dataStructure?.segments?.slice(...range)?.map(({ id }) => id) || []
//   const markerLocations = (state.wizard.locations.choice || {})[id]
//   const pera = [
//     rangeWithinSection[0] + insideRange[0],
//     Math.min(rangeWithinSection[0] + insideRange[1], rangeWithinSection[1]),
//   ]
//   const locations = filterLocations(pera, markerLocations)
//   locations.forEach(({ id, markerRange, containerRange, start, end }) => {
//     const pageStart = containerRange[0]
//     const length = containerRange[1] - pageStart
//     markerSegments.splice(
//       pageStart,
//       length,
//       ...new Array(length).fill(
//         `${id}[${markerRange[0]},${markerRange[1]}]${[start && 's', end && 'e'].filter(a => a).join('')}`
//       )
//     )
//   })
//   return markerSegments.join('; ')
// }

const clampRange = (limit = [], range = []) =>
  limit.splice(0, 2, Math.min(limit[0] + range[0], limit[1]), Math.min(limit[0] + range[1], limit[1]))

const getRangeLocationsSegments = (state, info) => {
  const { type } = info
  switch (type) {
    case 'page': {
      const { sectionIndex, pageIndex } = info
      const sectionInfo = (state.wizard.sections || [])[Number(sectionIndex)] || {}
      const range = (sectionInfo?.pages || [])[Number(pageIndex)] || [0, 0]
      // const locations = (state.wizard.locations.choice || {})[sectionInfo.id]
      const locations = (state.wizard.locations.choice || {}).root
      const segments = state.wizard.dataStructure.segments?.slice(...range)?.map(({ id }) => id) || []
      return [range, locations, segments]
    }
    case 'marker': {
      const { id, parent, insideRange } = info
      const allLocations = Object.values(state.wizard.locations.choice || {}).reduce((acc, cur) => acc.concat(cur), [])
      const range = allLocations.find(l => l.id === id)?.range?.slice() || [0, 0]
      clampRange(range, insideRange)
      const locations = (state.wizard.locations.choice || {})[id]
      const [structure, key = ''] = getStructure(state.wizard.dataStructure, parent)
      const segments =
        (structure[key] || state.wizard.dataStructure?.segments)?.slice(...range)?.map(({ id }) => id) || []
      return [range, locations, segments]
    }
    case 'cell': {
      const { id } = info
      const [structure, key = ''] = getStructure(state.wizard.dataStructure, id)
      const range = [0, structure[key]?.length || 0]
      const locations = (state.wizard.locations.choice || {})[id]
      const segments = structure[key]?.map(({ id }) => id) || []
      return [range, locations, segments]
    }
    default:
      return []
  }
}

const getSegmentIds = (state, info = {}) => {
  const [range, locations, segments] = getRangeLocationsSegments(state, info)
  if (!(range && segments.length)) return ''
  const filteredLocations = filterLocations(range, locations)

  filteredLocations.forEach(({ id, markerRange, containerRange, originalMarkerLength, start, end }) => {
    const containerStart = containerRange[0]
    const length = containerRange[1] - containerStart
    segments.splice(
      containerStart,
      length,
      ...new Array(length).fill(
        `${id}[${markerRange[0]},${markerRange[1]},${originalMarkerLength}]${[start && 's', end && 'e']
          .filter(a => a)
          .join('')}`
      )
    )
  })
  return segments.join('; ')
}

const contentIdsSelectorFunction = (state, type, ...extra) => {
  const info = { type }
  switch (type) {
    case 'page': {
      const [sectionIndex, pageIndex] = Array.from(extra)
      Object.assign(info, { sectionIndex, pageIndex })
      break
    }
    case 'marker': {
      const [id, parent, insideRange] = Array.from(extra)
      Object.assign(info, { id, parent, insideRange: insideRange.split('-').map(s => Number(s)) })
      break
    }
    case 'cell': {
      const [id] = Array.from(extra)
      Object.assign(info, { id })
      break
    }
    default:
  }
  return getSegmentIds(state, info)
}

export {
  initializeWizard,
  extractSections,
  addLocation,
  removeLocation,
  assignMarker,
  unassignMarker,
  applyToConfiguringStack,
  getStateQuestionLayoutGroup,
  getStateQuestion,
  getQuestionOption,
  answerQuestion,
  evaluateChoiceMarker,
  evaluateReplacementMarker,
  generateDataStructure,
  // addNewSeparator,
  // addNewQuestionLayoutGroup,
  // updateQuestionLayoutGroup,
  // unpackQuestionLayoutGroup,
  // removeQuestionLayoutGroup,
  // addNewQuestion,
  // updateQuestion,
  // assignQuestion,
  // removeQuestion,
  // filterLocations,
  //   generateSection,
  // getSections,
  // generateSegment,
  // insertSegmentAtIndex,
  // removeSegmentAtIndex,
  //   removeSegment,
  //   applyCustomStyle,
  //   updateSegmentLabel,
  //   replaceParagraphContent,
  //   insertSegmentAbove,
  // pageIdsSelectorFunction,
  // markerIdsSelectorFunction,
  contentIdsSelectorFunction,
}
