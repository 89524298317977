import React, {
  useMemo,
  useRef,
  // useEffect
} from 'react'

import useStore from '___store'
import { CASUS_CLASSES } from 'TemplateCreation-DocumentGeneration/constants'
// import { SEGMENT_TYPES } from 'TemplateCreation-DocumentGeneration/parsing'

import { PageProvider } from './PageContext'
import Segment from './Segment'
import { ChoiceMarker } from './Markers'

const classes = {
  page: 'Wizard-Editor-Page',
  // content: 'Wizard-Editor-Page-content',
}

export const Page = React.memo(props => {
  const ref = useRef()
  const { sectionIndex, index } = props
  const { contentIds: ids } = useStore(`selectContentIds[page,${sectionIndex},${index}]`)

  const children = useMemo(
    () =>
      Array.from(new Set(ids.split('; ')))
        .map(l => {
          const regex = /(.+)(\[(\d*),(\d*),(\d*)\])(se|s|e|)/g
          const match = [...l.matchAll(regex)][0]
          if (!match) return { type: 'segment', id: l }
          const id = match[1]
          const start = Number(match[3])
          const end = Number(match[4])
          const length = Number(match[5])
          const affix = [...match[6]]
          const rangeString = [start, end].join('-')
          return {
            type: 'marker',
            id,
            range: rangeString,
            length,
            start: affix.includes('s'),
            end: affix.includes('e'),
          }
        })
        .map(({ id, type, range, length, start, end }) =>
          type === 'segment' ? (
            <Segment key={id} id={id} />
          ) : (
            <ChoiceMarker key={id} id={id} parent={`root`} range={range} length={length} start={start} end={end} />
          )
        ),
    [ids]
  )
  // const lastIndex = useMemo(() => segments.length + pageOffset, [segments, pageOffset])

  // const { setConfiguring } = useStore('setConfiguring')

  // useEffect(() => {
  //   if (ref?.current) segments.forEach(({ id }, index) => Object.assign(ref.current, { [id]: index + pageOffset }))
  // }, [ref, segments, pageOffset])

  // console.groupCollapsed(`PAGE %c${sectionIndex}/${index}`, 'color: lightgreen;', 'RENDER!')
  // console.log(Array.from(new Set(ids.split('; '))))
  // console.groupEnd()

  const className = [classes.page, CASUS_CLASSES.pageContentRoot].join(' ')

  return (
    // <div
    //   className={classes.page}
    //   tabIndex={-1}
    //   onClick={event => {
    //     event.stopPropagation()
    //     event.preventDefault()
    //     // setConfiguring(null)
    //   }}
    // >
    // <div className={classes.content}>
    <div className={className}>
      <PageProvider ref={ref}>
        {children}
        {/* {segments.map(({ id: segmentId }) => (
            <Segment key={segmentId} id={segmentId} />
          ))}
          <button
            type="button"
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              insertSegmentAtIndex({ segmentType: SEGMENT_TYPES.paragraph, index: lastIndex })
            }}
          >
            ADD paragraph!
          </button> */}
      </PageProvider>
    </div>
    // </div>
  )
})

Page.displayName = 'Wizard-Editor-Page'

export default Page
