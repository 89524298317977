import React from 'react'

const classes = {
  wrapper: 'Wizard-Configuration-Questionnaire-Separator-wrapper',
}

export const Separator = React.memo(props => {
  const { label, questionIds } = props

  // const children = useMemo(
  //   () => {},
  //   []
  // )

  console.log(
    'QUESTIONS IDS: ',
    questionIds.map(({ id }) => id)
  )

  return (
    <div className={classes.wrapper}>
      {label}
      <br />
      {questionIds.map(({ id }) => id)}
    </div>
  )
})

Separator.displayName = 'Wizard-Questionnaire-Separator'

export default Separator
