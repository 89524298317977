import { ITeamMember } from 'types'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useState, useRef } from 'react'

import ProfileImage from 'components/user/ProfileImage/ProfileImage'
import { Icon, MenuPopover } from 'components/common'
import {
  BoxesWrapper,
  Container,
  ContentWrapper,
  ImageWrapper,
  InfoWrapper,
  StrongText,
  LightText,
  DataBox,
  iconStyles,
} from './TeamMember.styles'
import { brand } from 'config/themes/colors'
import { useTranslation } from 'react-i18next'

interface Props extends ITeamMember {
  onDelete: (teamMemberId: string) => void
}

const TeamMemberComponent: React.FC<Props> = ({
  id,
  imageUrl,
  firstName,
  lastName,
  jobPosition,
  email,
  templateCount,
  documentCount,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
  const containerEl = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const menuItems: IPopupMenuItem[] = [
    {
      label: 'Mitglied löschen',
      handleClick: () => onDelete(id),
      icon: 'delete',
    },
  ]

  return (
    <Container ref={containerEl}>
      <ImageWrapper>
        <ProfileImage
          firstName={firstName}
          lastName={lastName}
          imageUrl={imageUrl}
          size={56}
          style={{ border: `1px solid ${brand.dark}` }}
        />
      </ImageWrapper>
      <ContentWrapper>
        <InfoWrapper>
          <StrongText>
            {firstName && lastName ? `${firstName} ${lastName}` : email}
          </StrongText>
          <LightText>{jobPosition}</LightText>
        </InfoWrapper>
        <BoxesWrapper>
          <DataBox>
            <StrongText>{templateCount}</StrongText>
            <LightText>{t('u_templates')}</LightText>
          </DataBox>
          <DataBox>
            <StrongText>{documentCount}</StrongText>
            <LightText>Dokumente</LightText>
          </DataBox>
        </BoxesWrapper>
      </ContentWrapper>
      <Icon
        iconName='more'
        style={iconStyles}
        onClick={() => setAnchorEl(containerEl.current)}
      />
      <MenuPopover
        menuItems={menuItems}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </Container>
  )
}

export default TeamMemberComponent
