import { useDocumentsCreate, useDocumentsUpdate } from 'queries/documents'
import React, { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'

import { DocumentSettings, IAnswer, IDocument, Question } from 'types'

import { useToast } from 'hooks/useToast'
import { queryKeys } from 'queries/queryKeys'
import { StorageService } from 'utils/storageService'
import DocsApi from 'api/docs.api'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
// import { useUser } from 'queries/user/useUser'
import { useFetchTemplateHtml } from 'queries/templates/useFetchTemplateHtml'
import { useQuery } from 'react-query'

export type TDocumentGenerationMode = 'create' | 'edit' | 'create_public' | 'create_v2_public'

export type TDocumentGenerationSetter = {
  [K in keyof IDocumentGenerationState]?: IDocumentGenerationState[K]
}

interface Common {
  isCasus: boolean
  questions: Question[]
  templateHtmlData: string
  name: string
  mode: TDocumentGenerationMode
  templateId: string
  templateContentVersionId: string
  isDirty?: boolean
  cssData?: any
  templateDataStructure?: any
  documentDataStructure?: any
  isPublic?: boolean
}
interface IDocumentDataProps extends Common {
  documentId?: string
  answers?: IAnswer[]
  settings?: DocumentSettings
  step?: number
  htmlData?: string
}

interface IDocumentGenerationState extends Common {
  step: number
  answers: IAnswer[]
  filteredQuestions: Question[]
  htmlData: string
  settings?: DocumentSettings
  documentId?: string
  isSaving: boolean
}

type TCallback = (status: { success?: boolean; error?: boolean }) => void

interface IContext extends IDocumentGenerationState {
  set: (props: TDocumentGenerationSetter) => void
  save: (cb?: () => void) => void
  wasDocumentEdited: () => boolean
  fetchTemplateHtml: any
  isPublic?: boolean
}

const initialState: IDocumentGenerationState = {
  step: 1,
  questions: [],
  answers: [],
  filteredQuestions: [],
  templateHtmlData: '',
  htmlData: '',
  templateId: '',
  name: 'Unbenanntes Dokument',
  mode: 'create',
  templateContentVersionId: '',
  isCasus: false,
  isSaving: false,
  isDirty: false,
  templateDataStructure: {},
  documentDataStructure: {},
}

const DocumentGenerationContext = createContext<IContext | null>(null)

interface Props {
  initialData: IDocumentDataProps
}

export const DocumentGenerationProvider: React.FC<Props> = ({ children, initialData }) => {
  const { push } = useHistory()
  const toast = useToast()
  const create = useDocumentsCreate()
  const update = useDocumentsUpdate()
  const { setIsLoading } = useConfirmPopup()
  // const user = useUser()
  const docXMicro = true

  const [state, setState] = useState({
    ...initialState,
    ...initialData,
  })

  const { mode, templateId, answers, documentId, templateContentVersionId, name, cssData } = state

  const { mutate: fetchTemplateHtml } = useFetchTemplateHtml(templateId)
  const fetchedHtml = useQuery([queryKeys.TEMPLATE, templateId, 'html']).data as any

  const htmlData = (fetchedHtml?.htmlData && docXMicro ? fetchedHtml?.htmlData : null) || state?.htmlData || state?.templateHtmlData

  const set = useCallback((props: TDocumentGenerationSetter) => {
    setState(prev => {
      const newState = { ...prev, ...props }
      if (props.answers) {
        // console.log('ANSWERS: ', props.answers)
        // newState.documentDataStructure = generateDocumentDataStructure(
        //   newState.templateDataStructure,
        //   newState.questions,
        //   newState.answers
        // )
        newState.documentDataStructure = newState.templateDataStructure
      }
      return newState
    })
  }, [])

  const save = async (cb?: (docFile: IDocument) => void) => {
    const data = {
      templateId,
      answers,
      category: [],
      htmlData,
      templateContentVersionId,
      name,
      cssData,
    }
    set({ isSaving: true })

    switch (mode) {
      case 'create':
        setIsLoading(true)
        create.mutate(data, {
          onSuccess: newDoc => {
            if (cb) cb(newDoc)
            set({ documentId: newDoc.id })
          },
          onSettled: () => {
            set({ isSaving: false })
            setIsLoading(false)
          },
        })
        break
      case 'create_public':
        try {
          const doc = await DocsApi.create({ ...data, new: docXMicro }, true)
          StorageService.save('publicTemplatePurchase', {
            templateName: doc?.templateName,
            templateId,
            documentId: doc?.id,
          })
          push('/auth/register?plan=true')
        } catch (err) {
          toast('error', 'default')
        } finally {
          set({ isSaving: false })
        }
        break
      case 'create_v2_public':
        try {
          const doc = await DocsApi.create({ ...data, new: true }, true)
          push(`/public/download/${doc.id}`)
        } catch (err) {
          toast('error', 'default')
        } finally {
          set({ isSaving: false })
        }
        break
      case 'edit':
        if (!documentId) return toast('error', 'default')
        setIsLoading(true)
        update.mutate(
          { id: documentId, data: { ...data, isDirty: false } },
          {
            onSuccess: updatedDoc => {
              if (cb) cb(updatedDoc)
            },
            onSettled: () => {
              setIsLoading(false)
              set({ isSaving: false })
            },
          }
        )
    }
  }

  const wasDocumentEdited = () => {
    const initialAnswers = initialData.answers ?? initialState.answers
    const initialName = initialData.name ?? initialState.name
    const initial = JSON.stringify({
      answers: initialAnswers,
      name: initialName,
    })
    const current = JSON.stringify({ answers, name })
    return initial !== current
  }

  return (
    <DocumentGenerationContext.Provider
      value={{
        ...state,
        htmlData,
        fetchTemplateHtml,
        set,
        save,
        wasDocumentEdited,
      }}
    >
      {children}
    </DocumentGenerationContext.Provider>
  )
}

export const useDocumentGeneration = (): IContext => {
  const context = useContext(DocumentGenerationContext)

  if (!context) {
    throw new Error('useDocumentGeneration must be used within a DocumentGenerationContext')
  }

  return context
}
