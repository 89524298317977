import React, { useCallback, useMemo, useState } from 'react'
import useStore from '___store'
import { AdvancedQuestionConfiguration, QuestionConfiguration, QuestionGeneration, Questionnaire } from './components'

const classes = {
  wrapper: 'Wizard-Configuration-wrapper',
  content: 'Wizard-Configuration-content',
  controlsDiv: 'Wizard-Configuration-controlsDiv',
}

export const Configuration = React.memo(() => {
  const [collapsed, setCollapsed] = useState(false)
  const [ref, setRef] = useState({ priority: 0 })
  const { configuring = {} } = useStore('selectConfiguring')
  const { key, id } = configuring || {}

  const refCallback = useCallback(
    ref => ref && setRef(prev => (prev?.key === ref.key && prev?.priority > ref.priority ? prev : ref)),
    []
  )

  const render = useMemo(() => {
    let component = null
    switch (key) {
      case 'template-creation': {
        component = <QuestionGeneration ref={refCallback} />
        break
      }
      case 'question': {
        component = <QuestionConfiguration ref={refCallback} id={id} />
        break
      }
      case 'question-advanced': {
        component = <AdvancedQuestionConfiguration ref={refCallback} id={id} />
        break
      }
      case 'document-generation': {
        component = <Questionnaire ref={refCallback} />
        break
      }
      default:
    }
    return component
  }, [key, refCallback, id])

  // console.log('CONFIGURING: ', configuring)

  const buttons = useMemo(
    () =>
      ref?.buttonArray?.map(({ text, callback }, i) => (
        <button key={`configuration-button-${i}-${text}`} type="button" onClick={callback}>
          {text}
        </button>
      )),
    [ref]
  )

  return (
    <section data-shift={key && id && !collapsed} className={classes.wrapper}>
      <div className={classes.content} data-collapsed={collapsed}>
        {render}
      </div>
      <button type="button" onClick={() => setCollapsed(prev => !prev)}>
        {key && id && !collapsed ? '<' : '>'}
      </button>
      {key && id && !collapsed && configuring.key !== 'template-creation' && (
        <div className={classes.controlsDiv}>{buttons}</div>
      )}
    </section>
  )
})

Configuration.displayName = 'Wizard-Configuration'

export default Configuration
