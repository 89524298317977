import { Check } from 'assets/svgIconComponents'
import React, { useCallback, useEffect, useState } from 'react'

const classes = {
  wrapper: 'Input-wrapper',
  textarea: 'Input-textarea',
  input: 'Input-input',
  confirmButton: 'Input-confirmButton',
}

const setHeight = (node, maxLines, perLine = 0.9) => {
  if (node) {
    const value = node.value
    node.setAttribute('style', `height: ${perLine}rem; overflow: hidden;`)
    node.value = value || node.placeholder
    let style = `height: calc(${node.scrollHeight}px + ${perLine}rem)`
    if (maxLines) style += `; max-height: ${maxLines * perLine}rem`
    node.setAttribute('style', style)
    node.value = value
  }
}

export const Input = React.memo(props => {
  const [inputNode, setInputNode] = useState(null)
  const ref = useCallback(node => {
    if (node) setInputNode(node)
  }, [])
  const {
    className: cn,
    disabled,
    defaultValue,
    value,
    onChange,
    needsConfirmation,
    placeholder,
    multiline,
    maxLines,
    autofocus,
    blurShowUnmodified = false,
  } = props
  const [stateValue, setStateValue] = useState(value || defaultValue)

  const adjustHeight = useCallback(() => setHeight(inputNode, maxLines), [inputNode, maxLines])

  useEffect(() => setHeight(inputNode, maxLines), [inputNode, maxLines])

  const className = [classes.wrapper, cn].join(' ')

  return (
    <div
      className={className}
      data-modified={stateValue !== (value || defaultValue)}
      style={{ '--unmodified': `"${value || defaultValue}"` }}
    >
      {multiline ? (
        <textarea
          ref={ref}
          className={classes.textarea}
          tabIndex={disabled ? -1 : 0}
          type=""
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder || ''}
          wrap="soft"
          value={needsConfirmation ? stateValue : value}
          onInput={event => {
            adjustHeight()
            setStateValue(event.target.value)
            return !needsConfirmation && onChange(event)
          }}
          onChange={() => {}}
          onKeyDown={event => {
            if (event.key === 'Enter' && needsConfirmation) {
              event.preventDefault()
              event.target.blur()
              onChange(stateValue)
            }
          }}
          autoFocus={autofocus}
          data-show-unmodified={blurShowUnmodified}
        />
      ) : (
        <input
          className={classes.input}
          tabIndex={disabled ? -1 : 0}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder || ''}
          wrap="soft"
          value={needsConfirmation ? stateValue : value}
          onInput={event => {
            setStateValue(event.target.value)
            return !needsConfirmation && onChange(event)
          }}
          onChange={() => {}}
          onKeyDown={event => {
            if (event.key === 'Enter' && needsConfirmation) {
              event.preventDefault()
              event.target.blur()
              onChange(stateValue)
            }
          }}
          autoFocus={autofocus}
          data-show-unmodified={blurShowUnmodified}
        />
      )}
      {needsConfirmation && stateValue !== (value || defaultValue) ? (
        <button
          className={classes.confirmButton}
          type="button"
          onClick={event => {
            event.target.blur()
            onChange(stateValue)
          }}
          // data-hide-on-blur={blurShowUnmodified}
          data-hide-on-blur={true}
        >
          <Check />
        </button>
      ) : null}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
