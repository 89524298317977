import { v4 as uuid } from 'uuid'

import { ORIENTATION, PAPER_NAMES } from 'utilities/hardcoded/documentSettings'

import {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  // DATA_STRUCTURE,
  // NUMBERING_SYSTEM,
  NESTED_STRUCTURE_KEYS,
  NESTED_STRUCTURE_TYPES,
  SEGMENT_KEYS,
  STYLES_PROPERTIES,
  STYLES_AFFIXES,
} from './constants'

const keyMapObject = {
  customStyle: 'styleName',
}

// const stylesToIgnore = ['Normal']
const stylesToIgnore = []

const styleMapObject = {
  _casus_ind: '_casus_indent',
}

const valueMapObject = {
  id: id => id && String(id),
  styles: styles =>
    styles
      ?.filter(s => !stylesToIgnore.includes(s))
      .map(s => Object.entries(styleMapObject).reduce((acc, [r, w]) => acc.replace(r, w), s)) || [],
  customStyle: cs => (!(cs && !stylesToIgnore.includes(cs)) ? '' : cs),
}

const mapDataStructureKey = key => (key && keyMapObject[key]) || key

const mapDataStructureValue = (key, value) => (key && valueMapObject[key] ? valueMapObject[key](value) : value)

const noIdTypes = [
  SEGMENT_TYPES.container,
  SEGMENT_TYPES.mark,
  SEGMENT_TYPES.tableHeader,
  SEGMENT_TYPES.tableBody,
  SEGMENT_TYPES.tableFooter,
  SEGMENT_TYPES.textChunk,
  SEGMENT_TYPES.textarea,
]

const parseAnswers = (answers = []) => answers.map(({ questionId: id, value }) => ({ id, value }))

// const compareObjects = (prev, next) =>
//   Object.entries(prev).reduce((acc, [key, value]) => acc && next[key] === value, true)

// const filterRedundantStyles = (styles = []) =>
//   styles.reduce((filtered, style) => {
//     const prevStyle = filtered[filtered.length - 1] || null
//     if (!(prevStyle && compareObjects(prevStyle, style))) filtered.push(style)
//     return filtered
//   }, [])

const extractStyles = (givenStyles = [], dataStructure = {}) => {
  const { customStyle, styles } = dataStructure
  const styleSet = new Set()
  const stylesToAdd = []
  if (customStyle) stylesToAdd.push(customStyle)
  if (styles && styles.length) styles.forEach(s => stylesToAdd.push(s))
  const filteredStyles = stylesToAdd.filter(s => s && !givenStyles.includes(s))
  if (filteredStyles.length) filteredStyles.forEach(s => styleSet.add(s))
  return NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (dataStructure[key]?.length) {
      const innerStyles = dataStructure[key].reduce((acc, cur) => [...acc, ...extractStyles(givenStyles, cur)], [])
      if (innerStyles.length) innerStyles.forEach(s => acc.add(s))
    }
    return acc
  }, styleSet)
}

const parseStyles = (cssData = {}, dataStructure = {}) => {
  const givenStyles = Object.keys(cssData.customStyles)
  const styles = Array.from(extractStyles(givenStyles, dataStructure))
  const dynamicStyles = styles.reduce(
    (acc, cur) => {
      const styleRegex = /(.*)-([^-]+$)/g
      const match = [...cur.matchAll(styleRegex)][0]
      if (match) {
        match.shift()
        const [property, value] = match
        const mappedProperty = styleMapObject[property] || property
        const style = acc[mappedProperty]
        if (style) style.push(value)
      }
      return acc
    },
    Object.keys(STYLES_PROPERTIES).reduce((acc, cur) => Object.assign(acc, { [cur]: [] }), {})
  )
  return Object.entries(dynamicStyles).reduce((acc, [style, valArray]) => {
    valArray.forEach(value => {
      return Object.assign(acc, {
        [`${style}-${value}`]: {
          [STYLES_PROPERTIES[style]]: [STYLES_AFFIXES[style].prefix, value, STYLES_AFFIXES[style].suffix]
            .filter(a => a)
            .join(''),
        },
      })
    })
    return acc
  }, {})
}

const parseCustomStyle = (customStyle = '') => {
  const customStyleRegex = /(((?<=[.])[^>:@]+(?=:|>|$))|((?<=^)[^>:.]+(?=:|>|$)))((::|>)((?<=::|>).+))?/g
  const matches = [...customStyle.matchAll(customStyleRegex)][0]
  const className = (matches[2] || '').trim()
  const customProp = (matches[3] || '').trim()
  const appendix = (matches[4] || '').trim()
  const appendixSplitRegex = /((>|:|::)[^>:]+)/g
  const splitAppendix = [...appendix.matchAll(appendixSplitRegex)].map(([a]) => a.trim().replace(/\s+/g, ''))
  return [className, customProp, splitAppendix]
}

const assignStyleObject = (targetObject = {}, sourceObject = {}, path = []) => {
  if (!(Object.entries(sourceObject)?.length && path.length)) return targetObject
  const first = path.shift()
  const existing = targetObject[first] || {}
  if (!path.length) return Object.assign(targetObject, { [first]: { ...existing, ...sourceObject } })
  return Object.assign(targetObject, { [first]: assignStyleObject(existing, sourceObject, path) })
}

const parseCssData = (cssData = {}) =>
  Object.entries(cssData).reduce(
    (acc, [customStyle, styleString]) => {
      const propsRegex = /([^;]+)(?<=:)([^:]+)(?<=[;$])/g
      const styleObject = [...styleString.matchAll(propsRegex)].reduce((acc, cur) => {
        const property = cur[1].trim().replace(':', '').trim()
        const value = cur[2].trim().replace(';', '').trim()
        acc[property] = value
        return acc
      }, {})
      const [className, customProp, appendices] = parseCustomStyle(customStyle)
      const path = customProp ? ['customProperties', customProp] : ['customStyles', className, ...appendices]
      return assignStyleObject(acc, styleObject, path)
    },
    {
      customStyles: {},
      customProperties: {},
    }
  )

const compareSets = (prev, next) => prev.size === next.size && Array.from(prev).every(s => next.has(s))

const mergeChunks = (chunks = []) =>
  chunks
    .reduce((acc, chunk) => {
      const { customStyle = '', styles = [], text = '' } = chunk
      const prevChunk = acc[acc.length - 1] || null
      const prevChunkStyles = [prevChunk?.customStyle, ...(prevChunk?.styles || [])].filter(s => s)
      const currentChunkStyles = [customStyle, ...styles].filter(s => s)
      const comparedStyles = compareSets(new Set(prevChunkStyles), new Set(currentChunkStyles))
      if (prevChunk && comparedStyles) prevChunk.text += text
      else acc.push(chunk)
      return acc
    }, [])
    .filter(({ text }) => text)

const parseDataStructure = (dataStructure = {}, defaultType = SEGMENT_TYPES.container) => {
  const { type = defaultType } = dataStructure
  const stripped = {}
  if (type) {
    Object.assign(
      [...SEGMENT_KEYS.all, ...(SEGMENT_KEYS[type] || [])]
        .filter(k => !(k === 'tag' || NESTED_STRUCTURE_KEYS.includes(k)))
        .reduce(
          (acc, key) =>
            Object.assign(acc, { [key]: mapDataStructureValue(key, dataStructure[mapDataStructureKey(key)]) }),
          stripped
        ),
      { tag: SEGMENT_TAGS[type] }
    )
    if (!stripped.id && !noIdTypes.includes(type)) stripped.id = uuid()
    if (!stripped.type) stripped.type = type
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const { v2Styles: { pageBreak } = {} } = dataStructure
  // if (stripped.type === SEGMENT_TYPES.paragraph && Math.random() < 0.02) stripped.break = { type: 'page' }
  // if (stripped.type === SEGMENT_TYPES.paragraph && Math.random() < 0.005) stripped.break = { type: 'section' }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (dataStructure[key]?.length) {
      const array = dataStructure[key].map(segment => parseDataStructure(segment, NESTED_STRUCTURE_TYPES[key]))
      const source = {
        [key]: key === 'textChunks' ? mergeChunks(array) : array,
      }
      Object.assign(acc, source)
    }
    return acc
  }, stripped)
  return stripped
}

const v3parse = (
  dataStructure = {},
  locations = { choice: {}, replacement: {} },
  cssData = {},
  questions = [],
  questionLayout = [],
  answers = []
) => {
  // console.log('V3 PARSE: ', dataStructure)
  const { numberingSystem } = dataStructure
  // console.log('DATA STRUCTURE: ', dataStructure)
  const parsedDataStructure = parseDataStructure(dataStructure)
  // console.log('PARSED DATA STRUCTURE: ', parsedDataStructure)
  const parsedCssData = parseCssData(cssData)
  const parsedStyles = parseStyles(parsedCssData, dataStructure)
  parsedCssData.dynamicStyles = parsedStyles
  const parsedAnswers = parseAnswers(answers)
  return {
    dataStructure: parsedDataStructure,
    locations,
    cssData: parsedCssData,
    numberingSystem,
    questions,
    questionLayout,
    answers: parsedAnswers,
  }
}

const unparseDataStructure = (structure = {}) => {
  const { type } = structure
  const stripped = [...SEGMENT_KEYS.all, ...(SEGMENT_KEYS[type] || [])]
    .filter(k => !(k === 'tag' || NESTED_STRUCTURE_KEYS.includes(k)))
    .reduce((acc, key) => {
      if (structure[key]) {
        const value =
          key === 'styles'
            ? structure[key].map(s => (Object.entries(styleMapObject).find(([k, v]) => v === s) || [])[0] || s)
            : structure[key]
        return Object.assign(acc, { [mapDataStructureKey(key)]: value })
      }
      return acc
    }, {})
  return NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (structure[key]?.length) {
      const array = structure[key].map(segment => unparseDataStructure(segment))
      Object.assign(acc, { [key]: array })
    }
    return acc
  }, stripped)
}

const parseV3forBE = (payload = {}) =>
  payload.dataStructure
    ? Object.assign(payload, { dataStructure: unparseDataStructure(payload.dataStructure) })
    : payload

export { parseAnswers, mergeChunks, v3parse, parseV3forBE }

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// OLD //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////

// const KEYSTRINGS = {
//   remove: '_casus_remove',
//   keep: '_casus_keep',
//   highlight: '_casus_highlight',
// }

// const CASUS_IDS = {
//   rootElement: '_casus_root_element',
// }

// const CASUS_CLASSES = {
//   section: '_casus_section_root',
//   pageContentRoot: '_casus_page_content_root',
//   counterResetter: '_casus_counter_resetter',
//   segmentDiv: '_casus_segment_div',
//   configureDiv: '_casus_configure_div',
//   configureButton: '_casus_configure_button',
//   labelInput: '_casus_label_input',
//   textarea: '_casus_textarea',
//   textareaOverlay: '_casus_textarea_overlay',
//   paragraphSegment: '_casus_paragraph_segment',
//   tableSegment: '_casus_table_segment',
//   tableHeader: '_casus_table_header',
//   tableBody: '_casus_table_body',
//   tableFooter: '_casus_table_footer',
//   tableRow: '_casus_table_row',
//   tableCell: '_casus_table_cell',
//   textChunk: '_casus_text_chunk_span',
//   listDepthLevel: '_casus_list_depth_level',
// }

const extractSubQuestions = (questionArray = []) =>
  questionArray.reduce((acc, cur) => {
    if (cur.options?.length)
      cur.options.forEach(option => {
        return acc.push(...extractSubQuestions(option?.subquestions))
      })
    acc.push(cur)
    return acc
  }, [])

const REPLACEMENT_TYPES = {
  text: 'QUESTION_TYPE_TEXT_BOX',
  date: 'QUESTION_TYPE_DATE',
  number: 'QUESTION_TYPE_NUMBER',
  radio: 'QUESTION_TYPE_RADIO_REPLACEMENT',
}

const CHOICE_TYPES = ['QUESTION_TYPE_CHECKBOX', 'QUESTION_TYPE_RADIO_LINK']
const MEASURE_RESET_KEYS = ['segments', 'content']

const measure = (object = {}, start = 0) => {
  if (object.text && object.text.length) {
    return { ...object, start, length: object.text.length }
  }
  const [result, end] = NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) => {
      if (Object.keys(acc[0]).includes(key) && acc[0][key].length) {
        const calculatedStart = acc[0].id ? 0 : acc[1]
        const [measuredArray, arrayEnd] = acc[0][key].reduce(
          (accumulated, object) => {
            const nestedObject = measure(object, MEASURE_RESET_KEYS.includes(key) ? 0 : accumulated[1])
            accumulated[0].push(nestedObject)
            accumulated[1] += nestedObject.length
            return accumulated
          },
          [[], calculatedStart]
        )
        acc[0][key] = measuredArray
        acc[1] += arrayEnd - calculatedStart
      }
      return acc
    },
    [object, start]
  )
  return { ...result, start, length: end - start }
}

const measureStructureSegments = (structure = {}, start = 0) =>
  structure.segments?.reduce(
    (acc, segment) => {
      const measuredSegment = measure(segment, acc[1])
      acc[0].push(measuredSegment)
      acc[1] += measuredSegment.length
      return acc
    },
    [[], start]
  )[0] || []

const measureStructureSections = (structure = {}) =>
  structure?.sections?.map(section => {
    const segments = measureStructureSegments(section)
    const lastSegment = segments.length && segments[segments.length - 1]
    const length = lastSegment ? lastSegment.start + lastSegment.length : 0
    return {
      id: section.id,
      title: section.title,
      tag: SEGMENT_TAGS[SEGMENT_TYPES.container],
      segments,
      length,
    }
  }) || []

const parseQuestionLocations = question =>
  (
    (Object.values(REPLACEMENT_TYPES).includes(question.type)
      ? question?.locations
      : question?.options?.reduce(
          (locations, option) => [
            ...locations,
            ...option.locations.map(location => ({
              ...location,
              optionId: option.id,
            })),
          ],
          []
        )) || []
  ).map(({ id, questionId, locationId, optionId, start, length }) => ({
    segmentId: id,
    questionId,
    locationId,
    optionId,
    start,
    length,
  }))

const findSegmentById = (segments = [], id = '') => {
  // if (id === CASUS_IDS.rootElement) return { length: segments.reduce((acc, cur) => acc + cur.length, 0) }
  let result = null
  segments.every(segment =>
    segment.id === id
      ? !(result = segment)
      : NESTED_STRUCTURE_KEYS.every(
          key => !(segment[key] && segment[key].length && (result = findSegmentById(segment[key], id)))
        )
  )
  return result
}

const generateDefaultSectionLayout = () => ({
  id: uuid(),
  title: 'Generic section title',
  index: 0,
  layout: {
    orientation: ORIENTATION.vertical,
    paper: PAPER_NAMES.A4,
    margins: { top: 1, left: 1.252, bottom: 1, right: 1.252 },
  },
})

// const getDeepField = (section = {}, path) =>
//   path.reduce((acc, field, i) => {
//     if (typeof field === 'string') return acc[field] || Object.assign(acc, { [field]: [] })[field]
//     const parent = path
//       .slice(0, Math.max(i - 1, 0))
//       .reduce((s, k) => (typeof k === 'string' ? s[k] : s[k.index]), section)
//     if (acc[field.index]) return acc[field.index]
//     const difference = field.index - acc.length
//     if (difference) {
//       parent.index = difference
//       path[i].index = acc.length
//     }
//     acc.push(
//       Object.entries(field).reduce(
//         (acc, [key, value]) => Object.assign(acc, key === 'index' ? {} : { [key]: value }),
//         {}
//       )
//     )
//     return acc[acc.length - 1]
//   }, section)

// const breakDataStructure = (dataStructure = {}, breakType = 'section', accumulated = [{}], path = []) => {
//   const sectionCount = accumulated.length
//   const currentSection = accumulated[sectionCount - 1]
//   const scraped = {}
//   const keys = ['id', 'type', 'tag', 'customStyle', 'styles', 'break']
//   keys.forEach(k => {
//     if (dataStructure[k]) scraped[k] = dataStructure[k]
//   })
//   if (path.length) Object.assign(path[path.length - 1], scraped)
//   const deepField = getDeepField(currentSection, path)
//   if (dataStructure.break?.type === breakType) {
//     Object.assign(deepField, dataStructure)
//     if (breakType === 'section') {
//       Object.assign(currentSection, {
//         id: dataStructure.break.id,
//         title: dataStructure.break.title,
//         layout: dataStructure.break.layout,
//       })
//       accumulated.push(generateDefaultSectionLayout())
//     } else {
//       Object.assign(currentSection, { id: dataStructure.break.id })
//       accumulated.push({ id: uuid(), index: 0, sectionOffset: currentSection.sectionOffset || 0 })
//     }
//     return accumulated
//   }
//   NESTED_STRUCTURE_KEYS.forEach(key => {
//     if (dataStructure[key] && dataStructure[key].length) {
//       Object.assign(deepField, { [key]: [] })
//       if (key === 'textChunks') dataStructure[key].forEach(tc => deepField[key].push(tc))
//       else
//         dataStructure[key].forEach((segment, i) =>
//           breakDataStructure(segment, breakType, accumulated, [...path, key, { index: i }])
//         )
//     }
//   })
//   return accumulated
// }

const breakSegmentsIntoSections = (segments = []) => {
  return []
}

export {
  SEGMENT_TAGS,
  SEGMENT_TYPES,
  REPLACEMENT_TYPES,
  CHOICE_TYPES,
  MEASURE_RESET_KEYS,
  NESTED_STRUCTURE_KEYS,
  // KEYSTRINGS,
  // CASUS_IDS,
  // CASUS_CLASSES,
  extractSubQuestions,
  measure,
  measureStructureSegments,
  measureStructureSections,
  parseQuestionLocations,
  findSegmentById,
  generateDefaultSectionLayout,
  // breakDataStructure,
  breakSegmentsIntoSections,
}
