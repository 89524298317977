import React, { Fragment, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PagesLayout from 'layout/PagesLayout/PagesLayout'
import AccountSettingsSidebar from './components/AccountSettingsSidebar'
import PasswordSettings from './views/PasswordSettings/PasswordSettings.container'
import ProfileSettings from './views/ProfileSettings/ProfileSettings.container'
import EmailSettings from './views/EmailSettings/EmailSettings.container'
import TwoFaSettings from "./views/TwoFaSettings/TwoFaSettings.container";
import PlansSettings from './views/PlansSettings/PlansSettings'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'
import { useUser } from 'queries/user/useUser'
import LangSettings from './views/LangSettings/LangSettings.container'
import { useTranslation } from 'react-i18next'

const AccountSettingsPage: React.FC = () => {
  const user = useUser()
  const {t} = useTranslation()

  useEffect(() => {
    document.title = `Einstellungen | CASUS`
  }, [])

  const allowAboPage =
    user?.type === USER_TYPE_CLIENT_ADMIN || user?.type === USER_TYPE_LAWYER_ADMIN

  return (
    <Fragment>
      <AccountSettingsSidebar />
      <PagesLayout>
        <Switch>
          <Route exact path={`/${t('settings')}`} component={ProfileSettings} />
          <Route exact path={`/${t('settings')}/${t('password')}`} component={PasswordSettings} />
          <Route exact path={`/${t('settings')}/lang`} component={LangSettings} />
          <Route exact path={`/${t('settings')}/2fa`} component={TwoFaSettings} />
          <Route exact path={`/${t('settings')}/email`} component={EmailSettings} />
          {allowAboPage && (
            <Route exact path={`/${t('settings')}/abo`} component={PlansSettings} />
          )}
          <Redirect to={`/${t('settings')}`} />
        </Switch>
      </PagesLayout>
    </Fragment>
  )
}

export default AccountSettingsPage
