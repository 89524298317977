import React from 'react'

const classes = {
  input: {
    wrapper: 'Modal-Main-Input-wrapper',
    hidden: 'Modal-Main-Input-Hidden-wrapper'
  },
}

export const Input = React.memo(({ value, onChange, placeholder, readOnly }) => {
  return (
    <div className={ readOnly ? classes.input.hidden : classes.input.wrapper}>
      <input value={value} onChange={onChange} className={classes.input.hidden} placeholder={placeholder} readOnly={readOnly} />
    </div>
  )
})

