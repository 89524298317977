import React, { useCallback, useMemo } from 'react'
import { Loader } from 'components/common'
import { useFetchTemplate } from 'queries/templates'
import { useUser } from 'queries/user/useUser'
import { useHistory, useParams } from 'react-router'
import { TemplateCreationWizard } from 'TemplateCreation-DocumentGeneration'
import { CreateTemplateProvider } from './provider/CreateTemplateProvider'
import TemplateEdit from './TemplateEdit'
import templateApi from 'api/templates.api'

const TemplateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data = {} as any, isSuccess } = useFetchTemplate(id)
  const { push } = useHistory()
  const user = useUser()
  // const docXmicro = !!user?.beta?.newDocxMicroservice
  const version = user?.version

  const { questions, htmlData, settings, originalStylesKept, name, cssData, dataStructure, v3 = {} } = data

  const {
    title: v3title,
    dataStructure: v3dataStructure,
    locations: v3locations,
    cssData: v3cssData,
    numberingSystem: v3numberingSystem,
    questions: v3questions,
    questionLayout: v3questionLayout,
  } = v3

  let renderOld = false
  // renderOld = !docXmicro
  renderOld = version !== 'v3'

  const OldComponent = useMemo(
    () => (
      <CreateTemplateProvider
        initialData={{
          id,
          questions,
          htmlData,
          hyphens: settings?.hyphens,
          originalStylesKept,
          name,
          cssData,
          dataStructure,
        }}
      >
        <TemplateEdit />
      </CreateTemplateProvider>
    ),
    [id, questions, htmlData, settings, originalStylesKept, name, cssData, dataStructure]
  )

  const closeHandler = useCallback(() => push('/templates'), [push])
  const finishHandler = useCallback(
    ({ dataStructure, locations, questions, questionLayout }) => {
      templateApi.update({ version: 'v3', id, data: { dataStructure, locations, questions, questionLayout } })
      push('/templates')
    },
    [push, id]
  )

  const V3component = useMemo(() => {
    // console.log(v3dataStructure, v3locations, v3cssData, v3numberingSystem, v3questions)
    return (
      <TemplateCreationWizard
        // @ts-ignore
        id={id}
        title={v3title}
        dataStructure={v3dataStructure}
        locations={v3locations}
        cssData={v3cssData}
        numberingSystem={v3numberingSystem}
        questions={v3questions}
        questionLayout={v3questionLayout}
        closeHandler={closeHandler}
        finishHandler={finishHandler}
      />
    )
  }, [
    id,
    v3title,
    v3dataStructure,
    v3locations,
    v3cssData,
    v3numberingSystem,
    v3questions,
    v3questionLayout,
    closeHandler,
    finishHandler,
  ])

  const render = useMemo(() => {
    if (renderOld) return OldComponent
    return V3component
  }, [renderOld, OldComponent, V3component])

  if (!isSuccess) return <Loader />

  // const container = document.createElement('div')
  // container.innerHTML = htmlData

  // console.log('::::::: TEP :::::::')
  // console.log(htmlData.slice(350, 600))

  return render
}

export default TemplateEditPage
