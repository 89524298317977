import { isObject } from 'utilities/helpers'
import { CASUS_KEYSTRINGS } from 'TemplateCreation-DocumentGeneration/constants'

import {
  evaluateDependencies,
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  addNewQuestion,
  updateQuestion,
  removeQuestion,
  assignQuestion,
  unassignQuestion,
  updateQuestionOption,
  updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
} from './template-creation'

import { goToNextQuestion, goToPreviousQuestion } from './document-generation'

const deepAssign = (obj1, obj2) =>
  Object.entries(obj2).reduce((acc, [k, v]) => Object.assign(acc, { [k]: isObject(acc[k]) && isObject(v) ? deepAssign(acc[k], v) : v }), obj1)

const fullAssign = (...objectArray) => objectArray.reverse().reduce((acc, cur) => Object.assign(cur, acc))
const filterObjectFields = (object, ...keys) =>
  Object.entries(object).reduce(
    ([res, ignoreList], [k, v]) => [ignoreList.includes(k) ? res : Object.assign(res, { [k]: v }), ignoreList],
    [{}, keys.reduce((a, c) => a.concat(c), [])]
  )[0]
const parseUserInputValue = val =>
  // eslint-disable-next-line no-useless-escape
  [...val.matchAll(new RegExp(`(^${CASUS_KEYSTRINGS.userInput}:)([a-zA-Z0-9\-]+):(.*)`, 'g'))][0]
const conditionalOutcomeTest = (state, rule) => {
  debugger
  const { questionId, optionId, condition } = rule
  const { needed } = condition
  const { value = [] } = state.answers.find(a => a.id === questionId) || {}
  const values = typeof value === 'string' ? [value] : value
  const answeredOptions = values.map(val => {
    const parsedValue = parseUserInputValue(val)
    return parsedValue ? parsedValue[2] : val
  })
  if (optionId) return answeredOptions.includes(optionId) === needed
  return true // Instead of "true" add non optionId related rules (e.g. replacement question value rules)
}
const evaluateQuestionConditionalOutcome = (state, id) => {
  const { advanced: { conditionalFunctionality = 'show', logic = 'and', rules = [] } = {} } = state.questions.find(q => q.id === id) || {}
  return (conditionalFunctionality === 'show') === rules[logic === 'and' ? 'every' : 'some'](rule => conditionalOutcomeTest(state, rule))
}

export {
  // /////////////////////////////// //
  // /////////// GENERAL /////////// //
  // /////////////////////////////// //
  deepAssign,
  fullAssign,
  filterObjectFields,
  evaluateQuestionConditionalOutcome,
  // /////////////////////////////// //
  // ///////// DEPENDENCIES //////// //
  // /////////////////////////////// //
  evaluateDependencies,
  // /////////////////////////////// //
  // ///// QUESTION GENERATION ///// //
  // /////////////////////////////// //
  addNewQuestionLayoutGroupSeparator,
  addNewQuestionLayoutGroup,
  updateQuestionLayoutGroup,
  unpackQuestionLayoutGroup,
  removeQuestionLayoutGroup,
  // /////////////////////////////// //
  // ////////// QUESTIONS ////////// //
  // /////////////////////////////// //
  addNewQuestion,
  updateQuestion,
  removeQuestion,
  assignQuestion,
  unassignQuestion,
  // /////////////////////////////// //
  // /////////// OPTIONS /////////// //
  // /////////////////////////////// //
  updateQuestionOption,
  // /////////////////////////////// //
  // /////////////////////////////// //
  // ////// ADVANCED QUESTION ////// //
  // /////////////////////////////// //
  updateAdvancedQuestionConfiguration,
  addNewConditionalRule,
  updateConditionalRule,
  removeConditionalRule,
  // /////////////////////////////// //
}

export { goToNextQuestion, goToPreviousQuestion }
