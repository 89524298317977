import { Box, styled, Theme, Typography } from '@material-ui/core'
import { CustomButton, SwitchButton } from 'components/common'
import { scrollBarStyles } from 'constants/common-styles'
import { ArrowDropDown } from '@material-ui/icons'

export { CustomButton, SwitchButton }
interface WithOn {
  on: number
  theme: Theme
}

export const Container = styled(Box)(({ theme }) => ({
  width: 588,
}))

export const Footer = styled(Box)(({ theme, on }: WithOn) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: on ? 'transparent' : theme.colorz.secondary[50],
  height: 91,
  padding: theme.spacing(0, on ? 3 : 4),
  margin: theme.spacing(0, !on ? 3 : 0, 3, !on ? 3 : 0),
}))

export const InputActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 3),
  '& > *:first-child': {
    marginRight: theme.spacing(1),
  },
  marginBottom: "16px"
}))

export const DisabledSharingTextWrapper = styled(Box)({
  marginRight: 'auto',
})

export const PersonsContainer = styled(Box)(({ theme }) => ({
  marginTop: 16,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 250,
  maxHeight: 250,
  overflowY: 'auto',
  ...scrollBarStyles(theme),
}))

export const PersonWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  position: 'relative',
  display: "flex",
}))

export const StrongText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 2.05,
  letterSpacing: 0.35,
}))

export const LightText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: 2.05,
  letterSpacing: 0.35,
  color: theme.colorz.secondary[300],
}))

export const ArrowIcon = styled(ArrowDropDown)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fill: theme.colorz.secondary.main,
}))

export const PopoverItem = styled(StrongText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  fontWeight: 400,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    backgroundColor: theme.colorz.brand.main,
  },
}))


export const Button = styled(StrongText)(() => ({
  margin: '6px 6px',
  transition: 'all .2s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "white",
  cursor: 'pointer',
  borderRadius: "100%",
  backgroundColor: "#D11A2A",
  width: 20,
  height: 20
}))
